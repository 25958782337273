import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useHistory } from "react-router-dom";

import styles from "../Registration.module.scss";
import { ReactComponent as Line } from "../../../../icons/line_icon.svg";

import Button from "../../../../components/shared/UiButton";
import { ButtonType } from "../../../../components/shared/UiButton/Button";
import { textParams } from "../../../../utils";

type SuccessModalType = {
  error: null | string;
};

const SuccessModal: React.FC<SuccessModalType> = ({ error }) => {
  const { t } = useTranslation("translation");
  const history = useHistory();
  return (
    <div className={styles.success}>
      <div className={styles.iconWrap}>
        <h4>
          {error
            ? t(`registrationPage.errorPopup.header`)
            : t(`registrationPage.successPopup.header`)}
        </h4>
        <Line className={styles.decor} />
      </div>
      <p>
        {error ? (
          <>
            {textParams(t(`registrationPage.errorPopup.text`))[0]}{" "}
            {
              <NavLink to={"/contact-us"} className={styles.account}>
                {textParams(t(`registrationPage.errorPopup.text`))[1]}
              </NavLink>
            }{" "}
          </>
        ) : (
          t(`registrationPage.successPopup.text`)
        )}
      </p>
      <Button
        variant={ButtonType.Primary}
        type={"button"}
        className={styles.closeSuccess}
        onClick={() => history.push("/login")}
      >
        {t(`buttons.close`)}
      </Button>
    </div>
  );
};

export default SuccessModal;
