import React, { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import cn from "classnames";

import styles from "../Header/Header.module.scss";

import { useActions } from "../../../hooks/useActions";
import {
  countryCodeSelector,
  profileSelector,
  singleClientSelector
} from "../../../store/appStatic/selectors";
import PayeeCodeDropDown from "../../shared/LedgerBlock/PayeeCodeDropDown";
import UiDropdown from "../../shared/UiDropdown";
import { UserRole } from "../../userManagement/api/users";
import { NavBarProps } from "./NavBar.types";

const NavBar: FC<NavBarProps> = ({ isPreview }) => {
  const ref = useRef<any>(null);
  const { setCountryCode } = useActions();
  const { t } = useTranslation("translation");
  const profile = useSelector(profileSelector);
  const history = useHistory();
  const singleClient = useSelector(singleClientSelector);
  const countryCode = useSelector(countryCodeSelector);
  const myMaybeNullElement = document.getElementById(
    history.location.pathname.replace("/", "") + "_link"
  );
  if (myMaybeNullElement) {
    myMaybeNullElement.scrollIntoView(false);
  }
  const isPublic = singleClient?.data?.emailType?.includes("PUB");
  const menuItems = isPublic
    ? [
        { label: t(`headerMenu.home`), value: "/home" },
        { label: t(`headerMenu.documents`), value: "/documents" }
      ]
    : [
        { label: t(`headerMenu.home`), value: "/home" },
        { label: t(`headerMenu.analytics`), value: "/analytics" },
        { label: t(`headerMenu.songs`), value: "/songs" },
        { label: t(`headerMenu.documents`), value: "/documents" },
        ...(countryCode === "GBR"
          ? [{ label: t(`headerMenu.advances`), value: "/advances" }]
          : [])
      ];
  const defMenuItem = menuItems.find(
    (m: any) => m.value === history.location.pathname
  );
  const [dropdownValue, setDropdownValue] = useState({
    label: defMenuItem ? defMenuItem.label : "Dashboard",
    value: defMenuItem ? defMenuItem.value : "/dashboard"
  });
  const onChangeMenu = (val: any) => {
    setDropdownValue({ ...val });
    history.push(`${val.value}`);
  };
  useEffect(() => {
    if (
      profile.role === UserRole.ADMIN ||
      profile.role === UserRole.SUPER_ADMIN
      // profile.role === UserRole.SENIOR_ADMIN
    ) {
      setCountryCode(singleClient?.data?.profile?.countryCode);
    } else {
      setCountryCode(profile.countryCode);
    }
  }, [profile, singleClient]);
  return (
    <>
      <PayeeCodeDropDown isPreview={isPreview} />
      <ul className={cn(styles.nav_list, "container")}>
        {menuItems.map((el, i) => {
          const isActive = () =>
            el.value === history.location.pathname || (!!isPreview && i === 0);
          return (
            <li
              className={styles.nav_item}
              key={el.value}
              ref={ref}
              id={el.value.replace("/", "") + "_link"}
            >
              <NavLink
                className={styles.nav_item_link}
                activeClassName={styles.nav_item_link_active}
                isActive={isActive}
                to={el.value}
              >
                {el.label}
              </NavLink>
            </li>
          );
        })}
        <li>
          <UiDropdown
            options={menuItems}
            className={styles.nav_dropdown}
            isDropTop
            value={dropdownValue}
            onChange={(val: any) => onChangeMenu(val)}
            controlClassName={styles.nav_dropdown_control}
            placeholderClassName={styles.nav_dropdown_placeholder}
            menuClassName={styles.nav_dropdown_menu}
            arrowClassName={styles.nav_dropdown_arrow}
          />
        </li>
      </ul>
    </>
  );
};

export default NavBar;
