import "moment/min/locales";

import React, { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import cn from "classnames";

import styles from "../../../page/Home/Home.module.scss";
import { ReactComponent as DnwldIcon } from "../../../icons/file_dwnld.svg";

import { isCurrentState } from "../../../configs/utils";
import { useActions } from "../../../hooks/useActions";
import { jc } from "../../../lib/styles";
import {
  clientsSelector,
  singleClientSelector
} from "../../../store/appStatic/selectors";
import { ledgerSelector } from "../../../store/dealsStore/selectors";
import { textParams } from "../../../utils";
import CurrencyRateShort from "../CurrencyRateShort";
import InfoComponent from "../InfoComponent";
import SetRoyaltyPeriodModal from "../Modals/SetRoyaltyPeriodModal";
import PayeeCodeInfo from "../PayeeCodeInfo";
import Button from "../UiButton";
import { ButtonType } from "../UiButton/Button";
import RoyaltyPeriod from "./RoyaltyPeroid";
interface LedgerProps {
  hideLedgersInfoBlock?: boolean;
}
const LedgerBlock = (props: LedgerProps) => {
  const { t } = useTranslation("translation");
  const { hideLedgersInfoBlock = false } = props;
  const history = useHistory();
  const clients = useSelector(clientsSelector);
  const ledger = useSelector(ledgerSelector);
  const { ledgers } = useSelector((state: any) => state.dealsReducer);
  const singleClient = useSelector(singleClientSelector);
  const { SetOpenModal, SetLedger } = useActions();
  const labelRef = useRef<any>(null);

  useEffect(() => {
    if (ledgers?.balances) {
      const date = localStorage.getItem("selectedDate");
      let localeDate: any = null;
      if (date && ledgers?.balances?.length) {
        const parsedDate = JSON.parse(
          localStorage.getItem("selectedDate") || ""
        );
        localeDate = ledgers?.balances.find(
          (el: any) => `${el.startDate} - ${el.endDate}` === parsedDate.value
        );
      }
      if (
        history.location.pathname.includes("/analytics") &&
        isCurrentState(
          ledgers?.balances[0]?.startDate,
          ledgers?.balances[0]?.endDate
        )
      ) {
        SetLedger({
          data: ledgers?.balances?.length
            ? localeDate || ledgers?.balances[1]
            : null
        });
      } else {
        SetLedger({
          data: ledgers?.balances?.length
            ? localeDate || ledgers?.balances[0]
            : null
        });
      }
    }
  }, [history.location.pathname, ledgers]);
  const renderLedgers = useCallback(() => {
    const periodIndex = ledgers?.balances?.findIndex(
      (x: any) => x === ledger.data
    );
    const generateBigClassName = (data: string, className?: string) => {
      return jc(
        data && data.length >= 10 && `${styles.fz38}`,
        data && data.length >= 11 && `${styles.fz36}`,
        data && data.length >= 12 && `${styles.fz30}`,
        data && data.length >= 13 && `${styles.fz28}`,
        data && data.length >= 14 && `${styles.fz26}`,
        data && data.length >= 15 && `${styles.fz22}`,
        className && className
      );
    };
    const generateMiddleClassName = (data: number, className?: string) => {
      const rate = data?.toFixed(2).toString();
      return jc(
        rate && rate.length >= 10 && `${styles.fz30}`,
        rate && rate.length >= 11 && `${styles.fz28}`,
        rate && rate.length >= 12 && `${styles.fz26}`,
        rate && rate.length >= 13 && `${styles.fz22}`,
        rate && rate.length >= 14 && `${styles.fz20}`,
        rate && rate.length >= 15 && `${styles.fz18}`,
        className && className
      );
    };
    if (periodIndex === 0 && history.location.pathname.includes("/home")) {
      return (
        <>
          <div className={cn(styles.col_2)}>
            <PayeeCodeInfo
              name={t(`ledger.currentBalance`)}
              label={
                <CurrencyRateShort
                  id="ledger.closingBalance"
                  rate={ledger?.data?.closingBalance}
                  hasIcon
                />
              }
              id={1}
              hasIcon
              isBig
              infoText={t(`ledger.currentBalanceBottomText`)}
              currentBalancePosition={ledger?.data?.direction}
              currentBalancePercentage={ledger?.data?.percentage}
              className={cn(
                generateBigClassName(
                  ledger?.data?.closingBalance?.toFixed(2).toString(),
                  styles.payeeCodeItem_first
                )
              )}
              tooltipText={t(`ledger.currentBalanceTooltip`)}
            />
          </div>
          <div className={cn(styles.currentPeriod)}>
            <div className={cn(styles.row)}>
              <div
                className={cn(
                  styles.currentPeriod_col,
                  styles.currentPeriod_col_1
                )}
              >
                <InfoComponent
                  hasIcon
                  title={`${t("ledger.advances")} / ${t("ledger.adjustments")}`}
                  titleSize="middle"
                  iconSize="big"
                  information={t(`ledger.advancesTooltip`)}
                  tooltipSize="small"
                >
                  <CurrencyRateShort
                    id="ledger.advances"
                    rate={ledger?.data?.paymentsAdvances}
                    hasIcon
                  />
                </InfoComponent>
              </div>
              <div
                className={cn(
                  styles.currentPeriod_col,
                  styles.currentPeriod_col_2
                )}
              >
                <InfoComponent
                  hasIcon
                  title={t(`ledger.priorStatementBalance`)}
                  titleSize="middle"
                  iconSize="big"
                  information={t(`ledger.priorStatementBalanceTooltip`)}
                  tooltipSize="small"
                  blockClassName={cn(
                    generateMiddleClassName(
                      ledger?.data?.openingBalance,
                      styles.fz32
                    )
                  )}
                >
                  <CurrencyRateShort
                    id="ledger.priorStatementBalance"
                    rate={ledger?.data?.openingBalance}
                    hasIcon
                  />
                </InfoComponent>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className={cn(styles.col_2)}>
            <PayeeCodeInfo
              name={t(`ledger.closingBalance`)}
              label={
                <CurrencyRateShort
                  id="ledger.closingBalance"
                  rate={ledger?.data?.closingBalance}
                  hasIcon
                />
              }
              id={1}
              hasIcon
              isBig
              // infoText={`${t("ledger.paidBy")} ${moment
              //   .utc(new Date(ledger?.data?.endDate))
              //   .locale(i18n.language)
              //   .format("ll")}`}
              currentBalancePosition={ledger?.data?.direction}
              currentBalancePercentage={ledger?.data?.percentage}
              className={cn(
                generateBigClassName(
                  ledger?.data?.closingBalance?.toFixed(2).toString(),
                  styles.payeeCodeItem_first
                )
              )}
              tooltipText={t(`ledger.closingBalanceTooltip`)}
            />
            <div className={cn(styles.col_5, styles.col_5_hideOnDesktop)}>
              <InfoComponent
                hasIcon
                title={t(`ledger.royaltiesEarned`)}
                titleSize="middle"
                iconSize="big"
                information={t(`ledger.royaltiesEarnedTooltip`)}
                tooltipSize="big"
                blockClassName={cn(
                  generateMiddleClassName(
                    ledger?.data?.pipelineAdjustment,
                    styles.fz32
                  )
                )}
              >
                <CurrencyRateShort
                  id="ledger.royaltiesEarned-mobile"
                  rate={ledger?.data?.pipelineAdjustment}
                  hasIcon
                />
              </InfoComponent>
            </div>
          </div>
          <div className={cn(styles.col_8, styles.noCurrentPeriod)}>
            <div className={cn(styles.row)}>
              <div
                className={cn(
                  styles.noCurrentPeriod_col_6,
                  styles.noCurrentPeriod_col_1
                )}
              >
                <div className={cn(styles.row, styles.row_wrap)}>
                  <div className={cn(styles.col_5, styles.col_5_hideOnMobile)}>
                    <InfoComponent
                      hasIcon
                      title={t(`ledger.royaltiesEarned`)}
                      titleSize="middle"
                      iconSize="big"
                      information={t(`ledger.royaltiesEarnedTooltip`)}
                      tooltipSize="big"
                      blockClassName={cn(
                        generateMiddleClassName(
                          ledger?.data?.pipelineAdjustment,
                          styles.fz32
                        )
                      )}
                    >
                      <CurrencyRateShort
                        id="ledger.royaltiesEarned"
                        rate={ledger?.data?.pipelineAdjustment}
                        hasIcon
                      />
                    </InfoComponent>
                  </div>
                  <div className={cn(styles.col_5)}>
                    <InfoComponent
                      hasIcon
                      title={`${t("ledger.advances")} / ${t(
                        "ledger.adjustments"
                      )}`}
                      titleSize="middle"
                      iconSize="big"
                      information={`
                        ${t("ledger.advancesAdjustmentsTooltipsLine1")} 
                        <br />
                        ${t("ledger.advancesAdjustmentsTooltipsLine2")}`}
                      tooltipSize="big"
                      blockClassName={cn(
                        generateMiddleClassName(
                          ledger?.data?.paymentsAdvances,
                          styles.fz32
                        )
                      )}
                    >
                      <CurrencyRateShort
                        rate={ledger?.data?.paymentsAdvances}
                        id="ledger.advances-ledger.adjustments"
                        hasIcon
                      />
                    </InfoComponent>
                  </div>
                </div>
              </div>
              <div className={cn(styles.col_3, styles.noCurrentPeriod_col_2)}>
                <InfoComponent
                  hasIcon
                  title={t(`ledger.priorStatementBalance`)}
                  titleSize="middle"
                  iconSize="big"
                  information={t(`ledger.priorStatementBalanceTooltipClosed`)}
                  tooltipSize="big"
                  blockClassName={cn(
                    generateMiddleClassName(
                      ledger?.data?.openingBalance,
                      styles.fz32
                    )
                  )}
                >
                  <CurrencyRateShort
                    rate={ledger?.data?.openingBalance}
                    id="ledger.priorStatementBalance"
                    hasIcon
                  />
                </InfoComponent>
              </div>
            </div>
          </div>
        </>
      );
    }
  }, [ledger]);

  return (
    <>
      <div>
        <div
          className={cn(
            styles.flex,
            singleClient?.data && singleClient?.data?.emailType?.includes("PUB")
              ? styles.flex_noSticky
              : ""
          )}
        >
          {!!clients?.length && (
            <div className={cn(styles.ledger_block)}>
              <div className={cn(styles.wrap, styles.wrap_ledger)}>
                <RoyaltyPeriod
                  royaltyPeriodRef={labelRef}
                  onClick={() => {
                    ledgers?.balances?.length &&
                      SetOpenModal({
                        isOpen: true,
                        type: "royaltyPeriod"
                      });
                  }}
                />
              </div>
              <Button
                variant={ButtonType.Primary}
                className={styles.button}
                onClick={() =>
                  SetOpenModal({
                    isOpen: true,
                    type: "downloadStatementModal"
                  })
                }
              >
                <DnwldIcon
                  className={cn(styles.button_icon, "hideOnDesktop")}
                />
                <span className="hideOnMobile">
                  {t(`downloadStatement.title`)}
                </span>
              </Button>
            </div>
          )}
        </div>
        {!hideLedgersInfoBlock && (
          <div className={cn(styles.row)}>
            <div
              className={cn(styles.payeeCodeBlock, styles.payeeCodeBlock_big)}
            >
              {!ledgers?.balances?.length ? (
                <div className={cn(styles.payeeCodeBlock_nodata)}>
                  <h1>{t(`common.noDataHeadline`)}</h1>
                  <p>
                    {textParams(t(`common.noDataText2`))[0]}{" "}
                    <Link to="/contact-us">
                      {textParams(t(`common.noDataText2`))[1]}
                    </Link>
                  </p>
                </div>
              ) : (
                renderLedgers()
              )}
            </div>
          </div>
        )}
      </div>

      <SetRoyaltyPeriodModal
        labelRef={labelRef}
        currency={ledgers?.currency}
        top={labelRef?.current?.getBoundingClientRect().top + 20}
        left={labelRef?.current?.getBoundingClientRect().left}
        client={singleClient?.data}
      />
    </>
  );
};
export default LedgerBlock;
