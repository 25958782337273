import { FC } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { DateTime } from "luxon";

import styles from "./AlertSlide.module.scss";

import Icon from "../../../../components/shared/Icon";
import { correctUrl } from "../../../../utils/url";
import { DATE_FORMAT } from "./AlertSlide.constants";
import { AlertSlideProps } from "./AlertSlide.types";

/**
 * Renders alert slide component
 * @param props React component properties
 * @returns React component
 */
const AlertSlide: FC<AlertSlideProps> = props => {
  const { i18n } = useTranslation("translation");

  const formattedStartDate = DateTime.fromJSDate(new Date(props.item.startDate))
    .setLocale(i18n.language)
    .toFormat(DATE_FORMAT);
  const formattedEndDate = DateTime.fromJSDate(new Date(props.item.endDate))
    .setLocale(i18n.language)
    .toFormat(DATE_FORMAT);

  return (
    <div className={styles.item}>
      <div className={props.hasFullWidthText ? styles.fullWidth : "container"}>
        <div
          className={classNames(styles.itemContent, {
            [styles.itemContentWithNav]: props.hasNav
          })}
        >
          <div>
            <Icon fillColor="currentColor" kind="warning_triangle" />
          </div>
          <div>
            <div className={styles.dates}>
              {formattedStartDate} - {formattedEndDate}
            </div>
            <div>
              {props.item.text}
              {props.item.link && (
                <>
                  {" "}
                  <a
                    className={styles.link}
                    href={correctUrl(props.item.link.href)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {props.item.link.text}
                  </a>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlertSlide;
