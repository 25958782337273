import React from "react";
import cn from "classnames";

import styles from "./Button.module.scss";
import { ReactComponent as LoadingIcon } from "../../../icons/loading.svg";

import { jc } from "../../../lib/styles";

export enum ButtonType {
  Default,
  Primary,
  Text
}

export enum ButtonIconPosition {
  IconLeft,
  IconRight
}

export interface ButtonProps extends React.PropsWithChildren<unknown> {
  className?: string;
  disabled?: boolean | any;
  href?: string;
  onClick?: (
    e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement, MouseEvent>
  ) => void;
  text?: string;
  variant?: ButtonType;
  type?: "button" | "submit" | "reset" | undefined;
  iconPosition?: ButtonIconPosition;
  icon?: HTMLElement;
  loading?: boolean | undefined | null;
}

const renderBtnClass = (props: ButtonProps): string => {
  return jc(
    styles.btn,
    (typeof props.variant === "undefined" ||
      props.variant === ButtonType.Default) &&
      `${styles.btn_default} ${props.iconPosition ===
        ButtonIconPosition.IconLeft &&
        styles.btn_icon_left} ${props.iconPosition ===
        ButtonIconPosition.IconRight && styles.btn_icon_right}`,
    props.variant === ButtonType.Text &&
      `${styles.btn_text} ${props.iconPosition ===
        ButtonIconPosition.IconLeft &&
        styles.btn_icon_left} ${props.iconPosition ===
        ButtonIconPosition.IconRight && styles.btn_icon_right}`,
    props.variant === ButtonType.Primary &&
      `${styles.btn_primary} ${props.iconPosition ===
        ButtonIconPosition.IconLeft &&
        styles.btn_icon_left} ${props.iconPosition ===
        ButtonIconPosition.IconRight && styles.btn_icon_right}`,
    props.className
  );
};
const Button = (props: ButtonProps) => {
  const children = props.children;
  return (
    <button
      className={cn(renderBtnClass(props), props?.className)}
      disabled={props.disabled}
      onClick={props.onClick}
      type={props.type}
    >
      {props.loading && <LoadingIcon className={styles.btn_icon_loader} />}
      <span className={styles.btn_title}>{props.text}</span>
      {children}
    </button>
  );
};
export default Button;
