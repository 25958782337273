import axios from "axios";

import { oktaAuth } from "../../../lib/oktaAuth";
import { DocumentTypes } from "../../../types/documents";
import { downloadFile } from "../../StatementUpload/utils/fileUtils";
import { Document } from "../Documents.types";

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URI
});

axiosInstance.interceptors.request.use(config => {
  config.headers.common[
    "Authorization"
  ] = `Bearer ${oktaAuth.getAccessToken()}`;
  config.headers.common["accept"] = "*/*";
  config.headers.common["x-email"] = `${
    localStorage.getItem("impersonateEmail")
      ? localStorage.getItem("impersonateEmail")
      : localStorage.getItem("profileEmail")
  }`;
  return config;
});

export const useDownloadFITDocument = (payeeCode: string) => {
  const handleDownloadStatementDocument = async (
    document: Document,
    types: string[]
  ) => {
    const signedUrls = await Promise.all(
      types.map(type =>
        axiosInstance.get(
          `/api/royalty/${payeeCode}/${document.startDate}/${
            document.endDate
          }/statement/${type.toUpperCase()}`,
          {
            headers: {
              ContentType: `application/${type}`
            }
          }
        )
      )
    );

    signedUrls.forEach((url, index) => {
      downloadFile(
        `Statement_${document.startDate}_${document.endDate}.${types[index]}`,
        url.data
      );
    });
  };
  const handleDownloadDocument = async (document: Document) => {
    const res = await axiosInstance.get(
      `/api/cms/${
        DocumentTypes[document.documentType]
      }/payee-code-documents/${payeeCode}/${document.id}`
    );
    const url = res.data.url;
    const fileName =
      document.documentType === "TAX"
        ? `${document.fileName}.pdf`
        : `${document.documentType}_${document.startDate}_${document.endDate}.pdf`;
    downloadFile(fileName, url);
  };

  return {
    handleDownloadStatementDocument,
    handleDownloadDocument
  };
};
