import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import cn from "classnames";

import styles from "../../../../page/Home/Home.module.scss";
import style from "../LedgerBlock.module.scss";
import { ReactComponent as UserIcon } from "../../../../icons/user_icon.svg";

import { useActions } from "../../../../hooks/useActions";
import { setSingleClient } from "../../../../store/appStatic/actions";
import {
  clientsSelector,
  loadingSelector,
  payeeCodeSelector,
  singleClientSelector
} from "../../../../store/appStatic/selectors";
import { dealsQueryOptions } from "../../../../store/dealsStore/selectors";
import UiSelect from "../../UiSelect";
import { PayeeCodeDropDownProps } from "./PayeeCodeDropDown.types";

const PayeeCodeDropDown = (props: PayeeCodeDropDownProps): JSX.Element => {
  const { t } = useTranslation("translation");
  const history = useHistory();
  const clients = useSelector(clientsSelector);
  const profileLoading = useSelector(loadingSelector);
  const payeeCode = useSelector(payeeCodeSelector);
  const queryOptions = useSelector(dealsQueryOptions);
  const [options, setOptions] = useState<any[]>([]);
  const [dropdownValue, setDropdownValue] = useState<any>({});
  const { setQueryOptionsDeals, setPayeeCodeAction } = useActions();
  const [scrolledClass, setScrolledClass] = useState<string>("");
  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  const choosePayeeCode = (e: any) => {
    setPayeeCodeAction(e.value);
    setSingleClient({
      data: clients.find((el: any) =>
        el?.payeeAccounts?.find((e: any) => e.payeeCode === payeeCode)
      )
    });
    setDropdownValue({ label: e.label, value: e.value });
    setQueryOptionsDeals({ ...queryOptions, page: 0, size: 10 });
    localStorage.setItem("selectedDate", "");
  };

  const previewOption = useMemo(
    () => ({
      code: "--",
      label: (
        <div>
          <p className={styles.value}>--</p>
          <p>000000000000</p>
        </div>
      ),
      value: "000000000000"
    }),
    []
  );

  useEffect(() => {
    if (props.isPreview) {
      setDropdownValue(previewOption);
      setOptions([previewOption, previewOption]);
      return;
    }
    const activeClient = clients.find((el: any) =>
      el?.payeeAccounts?.find((e: any) => e.payeeCode === payeeCode)
    );
    if (profileLoading) {
      return;
    }
    if (!activeClient) {
      history.push(`/not-found`);
    }
    const res = activeClient?.payeeAccounts?.map((el: any) => {
      return {
        label: (
          <div>
            <p className={styles.value}>{el.payeeAccount}</p>
            <p>{el.payeeCode}</p>
          </div>
        ),
        code: el.payeeAccount,
        value: el.payeeCode
      };
    });
    setOptions(res);
  }, [clients, payeeCode, props.isPreview, profileLoading]);
  useEffect(() => {
    if (options?.length && !props.isPreview) {
      setDropdownValue(options.find(el => el.value === payeeCode));
    }
  }, [options, payeeCode, props.isPreview]);
  useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
      handleScroll();
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  function handleScroll() {
    if (dimensions.width < 750) {
      if (window.scrollY > 20 && window.scrollY < 10000) {
        setScrolledClass("addShadow");
      } else {
        setScrolledClass("");
      }
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleScroll);
  }, []);
  const singleClient = useSelector(singleClientSelector);

  return (
    <>
      {options?.length === 1 && (
        <div
          className={cn(
            style.singlePayeeCode,
            styles.payeeCodeDropDown,
            singleClient?.data && singleClient?.data?.emailType?.includes("PUB")
              ? styles.payeeCodeDropDown_noSticky
              : ""
          )}
        >
          <div className={cn(style.singlePayeeCode_block, scrolledClass)}>
            <UserIcon />
            <div className={cn(style.singlePayeeCode_block_info)}>
              <h4>{options[0].code}</h4>
              <p>{options[0].value}</p>
            </div>
          </div>
        </div>
      )}
      {options.length !== 1 && options.length !== 0 && (
        <div
          className={cn(
            styles.payeeCodeDropDown,
            singleClient?.data && singleClient?.data?.emailType?.includes("PUB")
              ? styles.payeeCodeDropDown_noSticky
              : ""
          )}
        >
          <UiSelect
            placeholder={t(`myAccountPage.search`)}
            hasShadowOnMobile
            noOptionsMessage={() => {
              t("common.noResult");
            }}
            options={options}
            className={cn(styles.select, styles.select_searchable)}
            isDropTop
            value={{
              label: dropdownValue?.label,
              value: dropdownValue?.value
            }}
            filterOption={(option: any, value: any) => {
              return (
                option.data.code.toLowerCase().includes(value.toLowerCase()) ||
                option.data.value.toLowerCase().includes(value.toLowerCase())
              );
            }}
            isSearchable
            onChange={(e: any) => choosePayeeCode(e)}
            menuClassName={styles.menu}
            isBig
          />
        </div>
      )}
      {!options.length && (
        <div
          style={{
            width: 270,
            minWidth: 270
          }}
        />
      )}
    </>
  );
};
export default PayeeCodeDropDown;
