import React, { useEffect, useState } from "react";
import Select, { components, IndicatorSeparatorProps } from "react-select";
import { IconProp, library } from "@fortawesome/fontawesome-svg-core";
import { faChevronDown, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames";

import styles from "./UiSelect.module.scss";
import { ReactComponent as Glob } from "../../../icons/white_glogus.svg";

import { TextFieldProps } from "../UiTextField/TextField";
import reactSelectOverWriteStyles from "./reactSelectOverWriteStyles";

const faPropIcon = faChevronDown as IconProp;
const faPropIconCross = faXmark as IconProp;

library.add(faChevronDown);
library.add(faXmark);
type UiSelectType = {
  label?: string | any;
  className?: string;
  errors?: any;
  options?: { value: string; label: string }[] | [];
  onChange?: (evt: any) => void;
  onFocus?: (evt: any) => void;
  value?: any;
  placeholder?: any;
  isClearable?: boolean;
  name?: string;
  rightIcon?: any;
  isSearchable?: boolean;
  hasShadowOnMobile?: boolean;
  hasGlob?: boolean;
  [key: string]: any;
};
const UiSelect: React.FC<TextFieldProps> = React.forwardRef(function UiSelect(
  props,
  ref: any
) {
  const {
    rightIcon = null,
    label,
    labelIcon,
    onChange,
    onFocus,
    disabled,
    className,
    options,
    errors = {},
    placeholder,
    isClearable,
    name = "",
    isSearchable = false,
    hasGlob,
    hasShadowOnMobile,
    ...res
  } = props;
  const handleChange = (evt: any): void => {
    if (onChange) {
      onChange(evt);
    }
  };
  const indicatorSeparatorStyle = {
    display: "none"
  };
  const handleFocus = (evt: any): void => {
    if (onFocus) {
      onFocus(evt);
    }
  };
  const IndicatorSeparator = ({
    innerProps
  }: IndicatorSeparatorProps<UiSelectType, true>) => {
    return <span style={indicatorSeparatorStyle} {...innerProps} />;
  };
  const CaretDownIcon = () => {
    return <FontAwesomeIcon icon={faPropIcon} />;
  };
  const CrossIcon = () => {
    return <FontAwesomeIcon icon={faPropIconCross} />;
  };
  const DropdownIndicator = (props: any) => {
    return hasGlob ? (
      <components.DropdownIndicator {...props}>
        <Glob />
      </components.DropdownIndicator>
    ) : (
      <components.DropdownIndicator {...props}>
        {rightIcon ? rightIcon : <CaretDownIcon />}
      </components.DropdownIndicator>
    );
  };

  const ClearIndicator = (props: any) => {
    return (
      <components.ClearIndicator {...props}>
        <CrossIcon />
      </components.ClearIndicator>
    );
  };
  const isInvalid = !!errors[name];
  const [scrolledClass, setScrolledClass] = useState<string>("");
  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth
  });
  useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
      handleScroll();
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  function handleScroll() {
    if (hasShadowOnMobile && dimensions.width < 750) {
      if (window.scrollY > 20 && window.scrollY < 10000) {
        setScrolledClass("addShadow");
      } else {
        setScrolledClass("");
      }
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleScroll);
  }, []);
  return (
    <div className={cn(styles.select_container, className, scrolledClass)}>
      {label && (
        <span className={styles.label}>
          {label}
          <span className={styles.labelIcon}>{labelIcon}</span>
        </span>
      )}
      <div className={styles.relative}>
        <Select
          noOptionsMessage={() => (
            <p className={styles.noOptions}>No Options</p>
          )}
          ref={ref || null}
          {...res}
          className={`${cn(styles.select, {
            [styles.whiteSelect]: props.isWhite,
            [styles.isBig]: props.isBig
          })} ${isInvalid} UiSelect`}
          onChange={handleChange}
          onFocus={handleFocus}
          styles={reactSelectOverWriteStyles(
            props.value,
            props.isWhite,
            isInvalid
          )}
          components={{
            IndicatorSeparator,
            DropdownIndicator,
            ClearIndicator,
            ...res.components
          }}
          options={options}
          isSearchable={isSearchable}
          isDisabled={disabled}
          placeholder={placeholder}
          isClearable={isClearable}
          classNamePrefix="select"
        />
        {errors[name]?.message && (
          <div className={styles.select_toolTip}>{errors[name].message}</div>
        )}
      </div>
    </div>
  );
});

export default UiSelect;
