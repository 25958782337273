import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import cn from "classnames";

import styles from "./Profile.module.scss";
import { ReactComponent as Cursor } from "../../icons/cursor.svg";
import { ReactComponent as Leaf } from "../../icons/leaf.svg";

import Loader from "../../components/shared/Loading";
import { UserRole } from "../../components/userManagement/api/users";
import { preferableLanguage } from "../../store/appStatic/constants";
import {
  profileSelector,
  singleClientSelector
} from "../../store/appStatic/selectors";
import { textParams } from "../../utils";
import Select from "./Select";

const ProfileInfoItem = (data: any) => {
  return (
    <div className={cn(styles.info_item)}>
      <div className={cn(styles.info_item_label)}>{data.label}</div>
      <div className={cn(styles.info_item_value)}>
        {data.value ? data.value : "-"}
      </div>
    </div>
  );
};
const Profile = () => {
  const { t } = useTranslation("translation");
  const singleClient = useSelector(singleClientSelector);
  const profile = useSelector(profileSelector);
  const history = useHistory();
  const { data } = singleClient;
  const name = useMemo(() => {
    return data?.alternativeName
      ? data?.alternativeName?.map(
          (el: { presentationName: string }, i: number) => (
            <>
              <span key={el?.presentationName}>
                {`${el?.presentationName} ${
                  i + 1 !== data?.alternativeName?.length ? " |" : " "
                }`}
              </span>
              <span>&nbsp;</span>
            </>
          )
        )
      : "-";
  }, [data?.alternativeName]);
  const preferableLanguageArray = data?.preferableLanguage.map(
    (element: string) => {
      return preferableLanguage.filter(item => item.code === element)[0]?.name;
    }
  );
  return !(profile && singleClient) ? (
    <Loader />
  ) : (
    <div className="container">
      <div className={cn(styles.filters)}>
        <button
          className={cn(styles.backButton)}
          onClick={() => {
            history.push(`/home${history.location.search}`);
          }}
          role="button"
        >
          <Cursor className={styles.backIcon} />
          <p>{t(`buttons.back`)}</p>
        </button>
      </div>
      <div className={cn(styles.wrapper)}>
        <h4 className={cn(styles.title)}> {t(`profilePage.userProfile`)}</h4>
        <div className={cn(styles.row)}>
          <h5 className={cn(styles.subtitle)}>
            {t(`profilePage.accountInformation`)}
          </h5>
          <ProfileInfoItem
            value={data?.fullName}
            label={t(`profilePage.fullName`)}
          />
          <ProfileInfoItem
            value={name}
            label={t(`profilePage.alternativeName`)}
          />
          <ProfileInfoItem
            value={localStorage.getItem("impersonateEmail") || profile?.email}
            label={t(`profilePage.email`)}
          />
          <ProfileInfoItem
            value={data?.suisaIpiNumber}
            label={t(`profilePage.suisaIPINumber`)}
          />
          <ProfileInfoItem
            value={data?.affiliatedSociety}
            label={t(`profilePage.affiliatedSociety`)}
          />
          <ProfileInfoItem
            value={data?.address?.addressLine1}
            label={t(`profilePage.address`) + " 1"}
          />
          <ProfileInfoItem
            value={data?.address?.addressLine2}
            label={t(`profilePage.address`) + " 2"}
          />
          <ProfileInfoItem
            value={data?.address?.addressLine3}
            label={t(`profilePage.address`) + " 3"}
          />
          <ProfileInfoItem
            value={data?.address?.city}
            label={t(`profilePage.cityTown`)}
          />
          <ProfileInfoItem
            value={data?.address?.state}
            label={t(`profilePage.State/Province`)}
          />
          <ProfileInfoItem
            value={data?.address?.zipCode}
            label={t(`profilePage.zipCode`)}
          />
          <ProfileInfoItem
            value={data?.address?.country}
            label={t(`profilePage.country`)}
          />
          <div className={cn(styles.contactus)}>
            {textParams(t(`profilePage.somethingNotLookRight`))[0]}{" "}
            <span className={cn(styles.underline)}>
              <Link to="/contact-us">
                {textParams(t(`profilePage.somethingNotLookRight`))[1]}.
              </Link>
            </span>
          </div>
        </div>
        <div className={cn(styles.row, styles.row_bordered)}>
          <div className={cn(styles.wrapper, styles.wrapper_settings)}>
            <h2 className={cn(styles.subtitle, styles.subtitle_settings)}>
              {t(`profilePage.accountAndLanguage`)}
            </h2>
            {profile?.role !== UserRole.ADMIN ? (
              <div
                className={cn(
                  styles.wrapper_settings_row,
                  styles.wrapper_settings_row_password
                )}
              >
                <div className={cn(styles.label)}>
                  <p>{t(`profilePage.password`)}</p>
                </div>
                <div
                  className={cn(
                    styles.value,
                    styles.value_underline,
                    styles.value_orange
                  )}
                  onClick={() => history.push("/change-password")}
                >
                  <p>{t(`profilePage.changePassword`)}</p>
                </div>
              </div>
            ) : null}
            <div
              className={cn(
                styles.wrapper_settings_row,
                styles.wrapper_settings_row_select
              )}
            >
              <div className={cn(styles.label)}>
                <p>{t(`profilePage.appLanguage`)}</p>
              </div>
              <div className={cn(styles.value)}>
                <Select />
              </div>
            </div>
            <div
              className={cn(
                styles.wrapper_settings_row,
                styles.wrapper_settings_row_language
              )}
            >
              <div className={cn(styles.label)}>
                <p>{t(`profilePage.statementAndEmailLanguage`)}</p>
              </div>
              <div className={cn(styles.value, styles.value_ml0)}>
                <p>
                  {preferableLanguageArray &&
                    preferableLanguageArray.map((el: string, index: number) => (
                      <span key={el}>{`${el}${
                        preferableLanguageArray?.length == index + 1 ? "" : ", "
                      }`}</span>
                    ))}
                </p>
              </div>
            </div>
            <div
              className={cn(
                styles.wrapper_settings_row,
                styles.wrapper_settings_row_note
              )}
            >
              <p className={cn(styles.wrapper_settings_note)}>
                {textParams(t(`profilePage.statementAndEmailLanguageText`))[0]}{" "}
                <Link to="/contact-us">
                  {" "}
                  {
                    textParams(
                      t(`profilePage.statementAndEmailLanguageText`)
                    )[1]
                  }{" "}
                </Link>
              </p>
            </div>
            <div className={cn(styles.wrapper_settings_row)}>
              <div className={cn(styles.wrapper_settings_paperless)}>
                <div className={cn(styles.label)}>
                  <p>{t(`profilePage.paperlessStatements`)}</p>
                </div>
                <div className={cn(styles.value)}>
                  <Leaf />
                  <p>{t(`profilePage.paperlessStatementsText`)}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Profile;
