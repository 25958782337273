import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import cn from "classnames";
import moment from "moment";

import styles from "./History.module.scss";

import DatePickerModal from "../../../../components/shared/Modals/SetDatePickerModal";
import SetDownloadPopup from "../../../../components/shared/Modals/SetDownloadPopup";
import SetDownloadReportPopup from "../../../../components/shared/Modals/SetDownloadReportPopup";
import HistoryFilterModal from "../../../../components/shared/Modals/SetHistoryFilterModal";
import SetImpersonatePopup from "../../../../components/shared/Modals/SetUserControlModal/SetImpersonatePopup";
import Chip, {
  IChip
} from "../../../../components/userManagement/Filter/chip/Chip";
import FilterBar from "../../../../components/userManagement/Filter/FilterBar";
import DeleteSuccessPopup from "../../../../components/userManagement/Modals/DeleteSuccessPopup";
import Table from "../../../../components/userManagement/UploadTable";
import { useActions } from "../../../../hooks/useActions";
import {
  generatedHistoryFiltersSelector,
  historyDateRangeSelector,
  historyFiltersSelector,
  modalSelector
} from "../../../../store/architectUsersStore/selectors";
import { options } from "../../constants";
import useHistory from "../../hooks/useHistory";
import { PaginationParams, TableType } from "../../types";
import SelectTableType from "../SelectTableType";
import { getColumnsDefault, getColumnsTaxDoc } from "./History.utils";

const History = () => {
  const initialTableType = localStorage.getItem("uploadTableType") as TableType;
  const historyDateRange = useSelector(historyDateRangeSelector);
  const historyFilters = useSelector(historyFiltersSelector);
  const generatedHistoryFilters = useSelector(generatedHistoryFiltersSelector);
  const modal = useSelector(modalSelector);
  const [historyData, setHistoryData] = useState<Record<any, any>>({});
  const [historyPageSize, setHistoryPageSize] = useState(10);
  const tableData = useMemo(() => {
    return historyData?.result || [];
  }, [historyData]);
  const { getHistory } = useHistory();
  const {
    SetOpenModalAction,
    UploaderListAction,
    SetHistoryFilters,
    SetHistoryDateRange,
    GenerateHistoryFilter,
    SetUploadTableType
  } = useActions();
  const [loading, setLoading] = useState<boolean>(true);
  const [searchValue, setSearchValue] = useState<string>();
  const [fetchedSearchValue, setFetchedSearchValue] = useState<string | null>();
  const [rowData, setRowData] = useState();
  const [tableType, setTableType] = useState<TableType>(
    initialTableType || "statement-doc"
  );
  const [filterType, setFilterType] = useState<
    "HISTORY" | "DOWNLOAD" | "SAVED_UPLOADS"
  >("HISTORY");

  useEffect(() => {
    if (tableType) {
      SetUploadTableType(tableType);
      localStorage.setItem("uploadTableType", tableType);
    }
  }, [tableType, SetUploadTableType]);

  const handleRowClick = useCallback(
    el => {
      if (!el?.original?.documents) return;

      setRowData(el?.original);
      SetOpenModalAction({
        isOpen: true,
        type: "downloadModalClick"
      });
    },
    [SetOpenModalAction, setRowData, rowData]
  );

  const columns = useMemo(() => {
    if (tableType === "tax-doc") {
      return getColumnsTaxDoc(tableType, fetchedSearchValue);
    } else {
      return getColumnsDefault(tableType, fetchedSearchValue);
    }
  }, [tableType, tableData, fetchedSearchValue]);

  useEffect(() => {
    localStorage.setItem("uploadTableType", tableType as string);
  }, [tableType]);

  useEffect(() => {
    if (modal.isOpen && modal.type.includes("downloadReportModal")) {
      setFilterType("DOWNLOAD");
    }
  }, [modal]);

  // Fetch saved batches on filter change or table type change
  useEffect(() => {
    setLoading(true);
    refetchTableData(generatedHistoryFilters, {
      documentType: tableType
    });
  }, [generatedHistoryFilters, tableType]);

  // Find out what this shit does.
  useEffect(() => {
    if (modal.isOpen && modal.type.includes("HistoryFilterModal")) {
      UploaderListAction("PUBLISHED");
    }
  }, [modal.isOpen]);

  const refetchTableData = useCallback(
    (paginationParams: PaginationParams, sortingParams?: any) => {
      setLoading(true);
      getHistory(generatedHistoryFilters, {
        documentType: tableType,
        paginationParams,
        sortingParams
      })
        .then(data => {
          const sortedData = { ...data };
          if (fetchedSearchValue) {
            sortedData.result = data.result.map((batch: any) => ({
              ...batch,
              documents: batch.documents.sort((a: any, b: any) => {
                const aIncludesSearchValue = a.fileName
                  .toLowerCase()
                  .includes(fetchedSearchValue?.toLowerCase());
                const bIncludesSearchValue = b.fileName
                  .toLowerCase()
                  .includes(fetchedSearchValue?.toLowerCase());

                if (aIncludesSearchValue && !bIncludesSearchValue) {
                  return -1;
                } else if (!aIncludesSearchValue && bIncludesSearchValue) {
                  return 1;
                } else {
                  return 0;
                }
              })
            }));
          }

          setHistoryData(sortedData);
        })
        .finally(() => setLoading(false));
    },
    [
      getHistory,
      setLoading,
      setHistoryData,
      fetchedSearchValue,
      generatedHistoryFilters,
      tableType
    ]
  );

  const handleTableSizeChange = useCallback(
    (el: any) => {
      setHistoryPageSize(+el.value);
      refetchTableData({ skip: 0, take: +el.value });
    },
    [setHistoryData, refetchTableData]
  );
  const handleTableSort = useCallback(
    (el: any) => {
      const sortingParams = el?.sortBy?.length ? el?.sortBy : null;
      refetchTableData({ skip: 0 }, sortingParams);
    },
    [refetchTableData]
  );

  const hidePagination =
    historyData?.totalCount <= 10
      ? null
      : historyData?.totalCount ?? "Default Value";
  const filterIconClicked = useCallback(() => {
    setFilterType("HISTORY");
    SetOpenModalAction({
      isOpen: true,
      type: ["HistoryFilterModal"]
    });
  }, [SetOpenModalAction]);

  const searchEmpty = () => {
    GenerateHistoryFilter([]);
    SetHistoryFilters([]);
    SetHistoryDateRange(null);
  };
  const searchClick = useCallback(() => {
    const searchValueBody = () => {
      if (searchValue) {
        setFetchedSearchValue(searchValue?.trim());
        return [
          {
            operation: "like",
            name: "payeeCode",
            value: searchValue.trim()
          }
        ];
      }
    };
    const body = searchValue
      ? generatedHistoryFilters
          .filter((el: any) => el.name !== "payeeCode")
          .concat(searchValueBody())
      : generatedHistoryFilters.filter((el: any) => el.name !== "payeeCode");
    GenerateHistoryFilter(body);
  }, [generatedHistoryFilters, searchValue, setFetchedSearchValue]);

  const handleClick = (type: string, itemValue?: string) => {
    if (type === "uploadedAt") {
      SetHistoryDateRange(null);
    } else
      SetHistoryFilters(
        historyFilters.filter((el: IChip) => el.itemValue !== itemValue)
      );
  };

  return (
    <div className={styles.container}>
      <div className={cn(styles.filter)}>
        <SelectTableType
          setTableType={setTableType}
          defaultValue={
            tableType
              ? {
                  label: options.uploadType.find(
                    (obj: { label: string; value: string }) =>
                      obj.value === tableType
                  )?.label,
                  value: tableType
                }
              : null
          }
        />
        {tableType ? (
          <FilterBar
            hasSearchInput
            searchClick={searchClick}
            onChangeSearch={(val: string) => {
              setSearchValue(val);
              setFetchedSearchValue(null);
            }}
            searchValue={searchValue}
            searchEmpty={searchEmpty}
            onClick={filterIconClicked}
            placeholder="Search  by payee code"
          />
        ) : null}
      </div>
      {/*<div className={cn(styles.filter_chips)}>*/}
      {historyFilters.length || historyDateRange ? (
        <div className={cn(styles.filter_chips)}>
          {tableType ? (
            <div className={cn(styles.chips)}>
              {historyFilters.map((el: IChip) => {
                return (
                  <Chip
                    handleClick={() => handleClick(el.type, el.itemValue)}
                    type={el.type}
                    typeName={el.typeName}
                    label={el.label}
                    value={el.value}
                    itemValue={el.itemValue}
                    key={el.itemValue}
                  />
                );
              })}
              {historyDateRange && (
                <Chip
                  handleClick={() => handleClick("uploadedAt")}
                  type="uploadedAt"
                  typeName="Upload Date"
                  value={`${historyDateRange?.startDate} - ${historyDateRange?.endDate}`}
                  label={`${moment(historyDateRange?.startDate)
                    .format("D MMM  YYYY")
                    .toUpperCase()} - ${moment(historyDateRange?.endDate)
                    .format("D MMM  YYYY")
                    .toUpperCase()}`}
                  itemValue="uploadedAt"
                />
              )}
              <div />
            </div>
          ) : null}
          <div
            className={cn(styles.chips_clear)}
            onClick={() => {
              searchEmpty();
            }}
          >
            Clear filter
          </div>
        </div>
      ) : null}
      {/*</div>*/}
      {tableType ? (
        <Table
          // canDelete={[UserRole.SENIOR_ADMIN, UserRole.SUPER_ADMIN].includes(
          //   profile?.role
          // )}
          columns={columns}
          data={tableData}
          loading={loading}
          totalItems={hidePagination}
          hidePagination={false}
          fetchData={refetchTableData}
          onChangeSize={handleTableSizeChange}
          onSort={handleTableSort}
          defaultPageSize={historyPageSize}
          selectActionType={"Download Report"}
          selectActionLabel={"Download Report"}
          defaultPage={0}
          onRowClick={handleRowClick}
        />
      ) : null}
      {/*<DeleteSelectedPopup*/}
      {/*  onClick={deleteSelectedHandler}*/}
      {/*  title={`Delete ${selectedCount?.length} ${*/}
      {/*    selectedCount?.length > 1 ? "documents" : "document"*/}
      {/*  }?`}*/}
      {/*/>*/}
      <HistoryFilterModal />
      <DeleteSuccessPopup />
      <SetDownloadReportPopup />
      <DatePickerModal type={filterType} />
      <SetDownloadPopup rowData={rowData} />
      <SetImpersonatePopup />
    </div>
  );
};

export default History;
