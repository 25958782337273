import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import styles from "./MfaForm.module.scss";
import { ReactComponent as CircleCheckIcon } from "../../../icons/circle-check-solid.svg";

import Button from "../../../components/shared/UiButton";
import { ButtonType } from "../../../components/shared/UiButton/Button";
import CheckBoxField from "../../../components/shared/UiCheckBox";
import ResponseAlert from "../../../components/shared/UiResponseAlert";
import { ResponseAlertType } from "../../../components/shared/UiResponseAlert/ResponseAlert";
import { getErrorDetails } from "../../../utils/error";
import CodeInput from "./CodeInput";
import {
  AuthFactor,
  AuthFactorProvider,
  AuthFactorType,
  MfaFormProps
} from "./MfaForm.types";
import { getIsAutoPushEnabled } from "./MfaForm.utils";

/**
 * Renders MFA form
 * @param props React component properties
 * @returns JSX element
 */
const MfaForm = (props: MfaFormProps): JSX.Element => {
  const { t } = useTranslation("translation");

  const checkBoxRef = useRef<HTMLInputElement>(null);
  const factors = (props.transaction.factors as AuthFactor[]) ?? [];
  const pushFactor = factors.find(
    f =>
      f.factorType === AuthFactorType.PUSH &&
      f.provider === AuthFactorProvider.OKTA
  );
  const tokenFactor = factors.find(
    f =>
      f.factorType === AuthFactorType.TOKEN_SOFTWARE_TOTP &&
      f.provider === AuthFactorProvider.OKTA
  );

  const [error, setError] = useState<string | null>(null);
  const [hasCriticalError, setHasCriticalError] = useState(false);
  const [isAutoPushEnabled, setIsAutoPushEnabled] = useState(
    getIsAutoPushEnabled(props.transaction)
  );
  const [isPushSent, setIsPushSent] = useState(isAutoPushEnabled);
  const [isSubmittingCode, setIsSubmittingCode] = useState(false);

  const getAutoPushValue = useCallback(
    (): boolean =>
      checkBoxRef.current ? checkBoxRef.current.checked : isAutoPushEnabled,
    [checkBoxRef, isAutoPushEnabled]
  );

  const onCodeSubmit = async (
    tokenFactor: AuthFactor,
    passCode: string
  ): Promise<void> => {
    try {
      setError(null);
      setIsSubmittingCode(true);
      const verifyResult = await tokenFactor.verify({
        autoPush: getAutoPushValue(),
        passCode
      });
      if (verifyResult.status === "SUCCESS") {
        props.onSuccess(verifyResult.sessionToken ?? "");
        return;
      }
      setError(t("common.unknownError"));
      setHasCriticalError(true);
    } catch (e) {
      const { errorCode } = getErrorDetails(e);
      if (errorCode === "E0000068") {
        setError(t("mfa.incorrectCode"));
        setIsSubmittingCode(false);
        return;
      }
      setError(t("common.unknownError"));
      setHasCriticalError(true);
    }
  };

  const onSendPushClick = useCallback(async (pushFactor: AuthFactor): Promise<
    void
  > => {
    setError(null);
    setIsPushSent(true);
    try {
      const verifyResult = await pushFactor.verify({
        autoPush: getAutoPushValue()
      });
      const factorTransaction = await verifyResult.poll({
        // Get auto push value via function because it might be changed
        autoPush: getAutoPushValue
      });
      if (factorTransaction.factorResult === "REJECTED") {
        setError(t("mfa.rejected"));
        setHasCriticalError(true);
        return;
      }
      if (factorTransaction.status === "SUCCESS") {
        props.onSuccess(factorTransaction.sessionToken ?? "");
        return;
      }
      setError(t("common.unknownError"));
      setHasCriticalError(true);
    } catch (e) {
      setError(t("common.unknownError"));
      setHasCriticalError(true);
    }
  }, []);

  useEffect(() => {
    if (
      props.transaction.status === "MFA_ENROLL" ||
      props.transaction.status === "MFA_ENROLL_ACTIVATE"
    ) {
      setError(t("mfa.enroll"));
      setHasCriticalError(true);
      return;
    }
    if (!pushFactor && !tokenFactor) {
      setError(t("common.unknownError"));
      setHasCriticalError(true);
      return;
    }
    if (pushFactor) {
      const newIsAutoPushEnabled = getIsAutoPushEnabled(props.transaction);
      setIsAutoPushEnabled(newIsAutoPushEnabled);
      if (newIsAutoPushEnabled) {
        void onSendPushClick(pushFactor);
      }
    }
  }, [onSendPushClick, props.transaction, pushFactor, tokenFactor]);

  return (
    <div className="container">
      <div className={styles.form}>
        <h2 className={styles.header}>Okta Verify</h2>
        {error && (
          <ResponseAlert
            className={styles.error}
            hideCloseButton={hasCriticalError}
            onClick={() => setError(null)}
            type={ResponseAlertType.Error}
          >
            <>{error}</>
          </ResponseAlert>
        )}
        {hasCriticalError && (
          <div>
            <Button
              className={styles.backButton}
              onClick={() => props.onError()}
              variant={ButtonType.Primary}
            >
              {t("buttons.back")}
            </Button>
          </div>
        )}
        {!hasCriticalError && (
          <>
            {pushFactor && isPushSent && (
              <>
                <div>
                  <CircleCheckIcon className={styles.pushSentIcon} />
                </div>
                <div className={styles.pushSentMessage}>
                  {t("mfa.pushSent")}
                </div>
              </>
            )}
            {pushFactor && !isPushSent && (
              <div>
                <Button
                  onClick={() => onSendPushClick(pushFactor)}
                  variant={ButtonType.Primary}
                >
                  {t("mfa.sendPush")}
                </Button>
              </div>
            )}
            {tokenFactor && (
              <CodeInput
                className={styles.codeInput}
                isSubmitting={isSubmittingCode}
                onSubmit={passCode => onCodeSubmit(tokenFactor, passCode)}
              />
            )}
            {pushFactor && (
              <div className={styles.checkboxContainer}>
                <CheckBoxField
                  checked={isAutoPushEnabled}
                  name="auto-push-enabled"
                  onChange={e => setIsAutoPushEnabled(e.target.checked)}
                  label={t(`mfa.sendPushAutomatically`)}
                  ref={checkBoxRef}
                />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default MfaForm;
