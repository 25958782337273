import { Reducer } from "redux";

import { DealsTypes } from "./types";

export const dealsInitialState: any = {
  deals: null,
  works: null,
  worksError: null,
  worksLoading: false,
  queryOptions: {
    page: 0,
    size: 10,
    sortBy: { id: "contractingParty", desc: false }
  },
  songsQueryOptions: {
    page: 0,
    size: 10,
    value: "",
    sortBy: { id: "workTitle", desc: false }
  },
  payeeCodes: [],
  loading: false,
  dealsLoading: false,
  ledgersLoading: false,
  closedPeriodsLoading: false,
  modal: { isOpen: false, type: "" },
  ledger: { data: null },
  ledgers: null,
  closedPeriods: []
};

export const dealsReducer: Reducer<any, any> = (
  state = dealsInitialState,
  action
) => {
  switch (action.type) {
    case DealsTypes.RESET_WORKS_LIST: {
      return {
        ...state,
        works: null,
        songsQueryOptions: {
          page: 0,
          size: 10,
          value: "",
          sortBy: { id: "workTitle", desc: false }
        }
      };
    }
    case DealsTypes.GET_WORKS_LOADING: {
      return {
        ...state,
        worksLoading: action.payload
      };
    }
    case DealsTypes.SET_WORKS_ERROR: {
      return {
        ...state,
        works: null,
        worksError: action.payload
      };
    }
    case DealsTypes.GET_WORKS_SUCCESS: {
      return {
        ...state,
        works: action.payload.data,
        songsQueryOptions: action.payload.query,
        worksError: null
      };
    }
    case DealsTypes.CLOSED_PERIODS_LOADING: {
      return {
        ...state,
        closedPeriodsLoading: action.payload
      };
    }
    case DealsTypes.CLOSED_PERIODS_SUCCESS: {
      return {
        ...state,
        closedPeriods: action.payload.closedPeriods
      };
    }
    case DealsTypes.DEALS_LOADING: {
      return {
        ...state,
        deals: action.payload ? null : state.deals,
        dealsLoading: action.payload
      };
    }
    case DealsTypes.LEDGERS_LOADING: {
      return {
        ...state,
        ledgersLoading: action.payload
      };
    }
    case DealsTypes.DEALS_SUCCESS: {
      return {
        ...state,
        deals: action.payload.data
      };
    }
    case DealsTypes.LEDGERS_SUCCESS: {
      return {
        ...state,
        ledgers: action.payload.ledgers
      };
    }
    case DealsTypes.PAYEECODE_SUCCESS: {
      return {
        ...state,
        payeeCodes: action.payload.data
      };
    }
    case DealsTypes.SET_OPEN_MODAL: {
      return {
        ...state,
        modal: action.payload
      };
    }
    case DealsTypes.SET_LEDGER: {
      return {
        ...state,
        ledger: action.payload
      };
    }
    case DealsTypes.RESET_DEALS_STATE: {
      return dealsInitialState;
    }
    case DealsTypes.SET_QUERY_OPTIONS_DEALS: {
      return {
        ...state,
        queryOptions: action.payload
      };
    }
    case DealsTypes.SET_SONGS_QUERY_OPTIONS: {
      return {
        ...state,
        songsQueryOptions: action.payload
      };
    }
    default:
      return state;
  }
};
