import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import cn from "classnames";

import styles from "./SetPasswordModal.module.scss";

import { useActions } from "../../../../hooks/useActions";
import { dealsModalSelector } from "../../../../store/dealsStore/selectors";

const SetPasswordModal = () => {
  const { t } = useTranslation();
  const modal = useSelector(dealsModalSelector);
  const { SetOpenModal } = useActions();

  useEffect(() => {
    const timer = setTimeout(() => {
      if (modal.isOpen) {
        SetOpenModal({ isOpen: false, type: "" });
      }
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={(styles.SetUserControlModal, "container")}>
      <Modal
        ariaHideApp={false}
        isOpen={modal.isOpen && modal.type === "successPassword"}
        className={cn(styles.noOverLayModal)}
        overlayClassName={styles.nooverlay}
      >
        <p className={cn(styles.success)}>
          <span>{t(`profilePage.changePasswordSuccess`)}</span>
        </p>
      </Modal>
    </div>
  );
};
export default SetPasswordModal;
