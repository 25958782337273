import "moment/min/locales";

import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import cn from "classnames";
import moment from "moment/moment";

import styles from "./SetRoyaltyPeriodModal.module.scss";
import { ReactComponent as ArrowDown } from "../../../../icons/arrow_small.svg";
import { ReactComponent as CloseIcon } from "../../../../icons/close.svg";

import { isCurrentState } from "../../../../configs/utils";
import { useActions } from "../../../../hooks/useActions";
import {
  payeeCodeSelector,
  singleClientSelector
} from "../../../../store/appStatic/selectors";
import { dealsModalSelector } from "../../../../store/dealsStore/selectors";
import UiSelect from "../../UiSelect";
type modal = {
  left: number;
  top: number;
  client: any;
  currency: string;
  labelRef: any;
};
const SetRoyaltyPeriodModal = ({
  left,
  top,
  client,
  currency,
  labelRef
}: modal) => {
  const { t, i18n } = useTranslation("translation");
  const history = useHistory();
  const singleClient = useSelector(singleClientSelector);
  const payeeCode = useSelector(payeeCodeSelector);
  const { ledgers, ledger } = useSelector((state: any) => state.dealsReducer);
  const dealsModal = useSelector(dealsModalSelector);
  const { SetOpenModal, SetLedger } = useActions();
  const [success] = useState(false);
  const [cancel] = useState(false);
  const [expanded, setExpanded] = useState<boolean>(false);
  const [dropdownValue, setDropdownValue] = useState<any>({});
  const [scrollTop, setTop] = useState(top);
  const [opts, setOpts] = useState<any>([]);
  function handleScroll() {
    if (window.innerWidth > 1024) {
      setTop(labelRef?.current?.getBoundingClientRect().top + 20);
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, []);

  const options = () => {
    const ledgersData = ledgers?.periods?.map((el: any) => {
      return {
        ...el,
        year:
          history.location.pathname.includes("/home") &&
          isCurrentState(el.startDate, el.endDate)
            ? "open"
            : el.year,
        isCurrentState: isCurrentState(el.startDate, el.endDate)
      };
    });
    const data = history.location.pathname.includes("/home")
      ? ledgersData
      : ledgersData.filter((el: any) => !el.isCurrentState);
    let o: any = [];
    const groupBy = (keys: string[] | number[]) => (array: any) =>
      array.reduce((objectsByKeyValue: any, obj: any) => {
        const value = keys.map(key => obj[key]).join("+");
        objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
        return objectsByKeyValue;
      }, {});

    const groupByYear = groupBy(["year"]);
    for (const [groupName, values] of Object.entries(groupByYear(data))) {
      o.push({ data: values, year: groupName });
    }
    o = o.map((el: any) => {
      return {
        expanded: true,
        label: el.year === "open" ? t(`common.open`) : el.year,
        options: el.data?.map((el: any) => {
          return {
            label: `${moment
              .utc(new Date(el.startDate))
              .locale(i18n.language)
              .format("MMM YYYY")} - ${moment
              .utc(new Date(el.endDate))
              .locale(i18n.language)
              .format("MMM YYYY")}`,
            value: `${el.startDate} - ${el.endDate}`
          };
        })
      };
    });
    return o;
  };
  const toggleModal = () => {
    SetOpenModal({ isOpen: false, type: "" });
  };
  const choosePayeeCode = (e: any) => {
    localStorage.setItem(
      "selectedDate",
      JSON.stringify({ label: e.label, value: e.value })
    );
    setDropdownValue({ label: e.label, value: e.value });
    const currentLedger = ledgers.balances.find(
      (l: any) => `${l.startDate} - ${l.endDate}` === e.value
    );

    SetLedger({
      data: currentLedger
    });
    toggleModal();
  };
  useEffect(() => {
    if (ledgers?.balances?.length) {
      if (localStorage.getItem("selectedDate")) {
        setDropdownValue(
          JSON.parse(localStorage.getItem("selectedDate") || "")
        );
      } else {
        setDropdownValue({
          label: opts[0]?.options[0]?.label,
          value: opts[0]?.options[0]?.value
        });
      }
      setOpts(options().reverse());
    }
  }, [ledgers, history.location.pathname, i18n.language]);
  useEffect(() => {
    if (ledger?.data) {
      setDropdownValue({
        label: `${moment
          .utc(new Date(ledger?.data?.startDate))
          .locale(i18n.language)
          .format("MMM YYYY")} - ${moment
          .utc(new Date(ledger?.data?.endDate))
          .locale(i18n.language)
          .format("MMM YYYY")}`,
        value: `${ledger?.data?.startDate} - ${ledger?.data?.endDate}`
      });
      localStorage.setItem(
        "selectedDate",
        JSON.stringify({
          label: `${moment
            .utc(new Date(ledger?.data?.startDate))
            .locale(i18n.language)
            .format("MMM YYYY")} - ${moment
            .utc(new Date(ledger?.data?.endDate))
            .locale(i18n.language)
            .format("MMM YYYY")}`,
          value: `${ledger?.data?.startDate} - ${ledger?.data?.endDate}`
        })
      );
    }
    if (
      isCurrentState(ledger?.data?.startDate, ledger?.data?.endDate) &&
      history.location.pathname.includes("/analytics")
    ) {
      setDropdownValue({
        label: `${moment
          .utc(new Date(ledgers?.balances[1]?.startDate))
          .locale(i18n.language)
          .format("MMM YYYY")} - ${moment
          .utc(new Date(ledgers?.balances[1]?.endDate))
          .locale(i18n.language)
          .format("MMM YYYY")}`,
        value: `${ledgers?.balances[1]?.startDate} - ${ledgers?.balances[1]?.endDate}`
      });
      localStorage.setItem(
        "selectedDate",
        JSON.stringify({
          label: `${moment
            .utc(new Date(ledgers?.balances[1]?.startDate))
            .locale(i18n.language)
            .format("MMM YYYY")} - ${moment
            .utc(new Date(ledgers?.balances[1]?.endDate))
            .locale(i18n.language)
            .format("MMM YYYY")}`,
          value: `${ledgers?.balances[1]?.startDate} - ${ledgers?.balances[1]?.endDate}`
        })
      );
    }
  }, [ledgers, history.location.pathname, i18n.language, ledger?.data]);
  const toggle = useCallback(
    (data: any) => {
      data.expanded = !data.expanded;
      setExpanded(!expanded);
    },
    [expanded]
  );
  const Group = (props: any) => {
    const { children, label } = props;
    const expanded = props.data.expanded;
    return (
      <div className={cn(styles.optionGroup)}>
        <div
          onClick={() => toggle(props.data)}
          className={cn(
            styles.optionGroup_item,
            expanded ? styles.optionGroup_item_open : styles.optionGroup_item
          )}
        >
          <span>{label}</span>
          <ArrowDown />
        </div>
        <div style={{ display: expanded ? "block" : "none" }}>{children}</div>
      </div>
    );
  };
  const onMenuOpen = () => {
    setTimeout(() => {
      const selectedEl = document.getElementsByClassName(
        "select__option--is-selected"
      )[0];
      if (selectedEl) {
        selectedEl.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center"
        });
      }
    }, 15);
  };

  const payeeAccount = singleClient?.data?.payeeAccounts?.find(
    (p: any) => p.payeeCode === payeeCode
  );
  return (
    <Modal
      ariaHideApp={false}
      isOpen={dealsModal.isOpen && dealsModal.type === "royaltyPeriod"}
      onRequestClose={toggleModal}
      className={styles.contactUsModal}
      overlayClassName={cn(
        styles.myoverlay,
        dealsModal.isOpen && window.innerWidth > 1024 && styles.myoverlay_zIndex
      )}
    >
      <div
        className={styles.wrapper}
        style={{ top: scrollTop ? scrollTop : top, left: left }}
      >
        <div className={cn(styles.container)}>
          <h3 className={cn(styles.title)}>
            {t(`ledger.selectRoyaltyPeriod`)}
          </h3>
          <div className={cn(styles.row)}>
            <p>
              {t(`common.statementFrequency`)} :{" "}
              <span>
                {" "}
                {`${
                  payeeAccount?.accountingPeriod === 3
                    ? t(`ledger.quarter`)
                    : t(`ledger.semiAnnual`)
                }`}
              </span>
            </p>
            {currency ? (
              <p>
                {t(`common.currency`)} : <span>{currency}</span>
              </p>
            ) : null}
          </div>
          <div className={cn(styles.row)}>
            <p>
              {t(`common.settlementPeriod`)}:{" "}
              <span>
                {" "}
                {`${client?.payeeAccounts[0]?.settlementPeriod} ${t(
                  "downloadStatement.days"
                )}`}
              </span>
            </p>
          </div>
          <div className={cn(styles.row_select)}>
            {!!opts?.length && (
              <UiSelect
                noOptionsMessage={() => {
                  t(`common.noResult`);
                }}
                options={opts}
                className={cn(styles.select, styles.select_menuOpened)}
                isDropTop
                onMenuOpen={() => onMenuOpen()}
                value={{
                  label: dropdownValue?.label
                    ? dropdownValue?.label
                    : opts[0]?.options[0]?.label,
                  value: dropdownValue?.value
                    ? dropdownValue?.value
                    : opts[0]?.options[0]?.value
                }}
                onChange={(e: any) => choosePayeeCode(e)}
                components={{ Group }}
              />
            )}
          </div>
          {!success && !cancel && (
            <CloseIcon className={styles.closeIcon} onClick={toggleModal} />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default SetRoyaltyPeriodModal;
