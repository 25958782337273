const monthNames = [
  "Jan",
  "Feb",
  "March",
  "April",
  "May",
  "June",
  "July",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
];

export function formatDate(date, format) {
  let dateObj = new Date(date);
  let month = monthNames[dateObj.getUTCMonth()]; //months from 1-12
  let day = dateObj.getDate();
  let year = dateObj.getUTCFullYear();
  if (format === "MM-DD-YY") {
    return month + " " + day + "," + year;
  }
  if (format === "MM/DD/YY") {
    return dateObj.getUTCMonth() + 1 + "/" + day + "/" + year;
  }
}

export function getDiffBetweenDays(date_1, date_2) {
  let Difference_In_Time =
    new Date(date_2).getTime() - new Date(date_1).getTime();

  // To calculate the no. of days between two dates
  let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
  return Difference_In_Days.toFixed();
}

export function getSortedData(data, sortBy) {
  return data.sort((a, b) => {
    const first =
      typeof b[sortBy?.sort] === "string"
        ? b[sortBy?.sort].toLowerCase()
        : b[sortBy?.sort];
    const last =
      typeof a[sortBy?.sort] === "string"
        ? a[sortBy?.sort].toLowerCase()
        : a[sortBy?.sort];
    return !sortBy.order ? (first < last ? 1 : -1) : first > last ? 1 : -1;
  });
}

export function setCurrency(currency, data, brackets = false) {
  let v = data;
  if (data) {
    if (Number(data) < 0) {
      let removeMinus = data.replace("-", "");
      v = `-${currency}${Number(removeMinus).toLocaleString("en-US")}`;
    } else {
      v = `${currency}${Number(data).toLocaleString("en-US")}`;
    }
    if (brackets) {
      return `(${v})`;
    } else {
      return `${v}`;
    }
  } else return "-";
}

export const isCurrentState = (startDate, endDate) => {
  const dayInMs = 86400000;
  const sDate = Date.parse(startDate);
  const eDate = Date.parse(endDate);
  const dateNow = Date.now() - (Date.now() % dayInMs);
  return (
    dateNow.valueOf() >= sDate.valueOf() && dateNow.valueOf() <= eDate.valueOf()
  );
};
export const getCookie = name => {
  let value = "; " + document.cookie;
  let parts = value.split("; " + name + "=");
  if (parts.length === 2) {
    return true;
  }
};

export const reloadOTBanner = () => {
  let otConsentSdk = document.getElementById("onetrust-consent-sdk");
  if (otConsentSdk) {
    otConsentSdk.remove();
  }

  if (window.OneTrust != null) {
    window.OneTrust.Init();

    setTimeout(function() {
      window.OneTrust.LoadBanner();

      const toggleDisplay = document.getElementsByClassName(
        "ot-sdk-show-settings"
      );

      for (let i = 0; i < toggleDisplay.length; i++) {
        toggleDisplay[i].onclick = function(event) {
          event.stopImmediatePropagation();
          window.OneTrust.ToggleInfoDisplay();
        };
      }
    }, 1000);
  }
};
export const clearDup = () => {
  const sec = document.getElementById("ot-sdk-cookie-policy");
  const tally = [];
  if (sec) {
    for (let i = sec.length - 1; i >= 0; i--) {
      if (tally[sec[i].firstChild.innerText] === undefined) {
        tally[sec[i].firstChild.innerText] = 1;
      } else {
        sec[i].remove();
        return true;
      }
    }
  }
  //return false;
};

export const createCookieInMount = (cookieName, cookieValue, hourToExpire) => {
  let date = new Date();
  date.setTime(date.getTime() + hourToExpire * 60 * 60 * 24 * 30 * 1000);
  document.cookie =
    cookieName + " = " + cookieValue + "; expires = " + date.toGMTString();
};

export const getRememberedUserInCookie = () => {
  return document.cookie
    .split(";")
    ?.find(el => el.includes("rememberedUser"))
    ?.split("=")[1];
};
export const getTermsOfUseCookie = () => {
  return document.cookie
    .split(";")
    ?.find(el => el.includes("agreeTermsOfUse"))
    ?.split("=")[1];
};
export const CurrencyFormat = (locale, currency, money, isCompact) => {
  if (typeof money === "number") {
    return currency
      ? new Intl.NumberFormat(locale, {
          style: "currency",
          currency: currency,
          minimumFractionDigits: money > 0 ? 2 : 0,
          maximumFractionDigits: 2,
          notation: isCompact ? "compact" : "standard"
        }).format(money)
      : new Intl.NumberFormat(locale, {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: money > 0 ? 2 : 0,
          maximumFractionDigits: 2,
          notation: isCompact ? "compact" : "standard"
        })
          .format(money)
          .replace("$", "");
  } else {
    return "-";
  }
};
export const GroupingFilters = filters => {
  const roles = filters.filter(el => el.name === "role");
  const userTitle = filters.filter(el => el.name === "userTitle");
  const userEmail = filters.filter(el => el.name === "userEmail");
  const songwriterType = filters.filter(el => el.name === "songwriterType");
  const location = filters.filter(el => el.name === "location");
  const modifiedBy = filters.filter(el => el.name === "modifiedBy");
  const accountingPeriod = filters.filter(el => el.name === "accountingPeriod");
  const statementPeriod = filters.filter(el => el.name === "settlementPeriod");
  const filterBody = [];
  const generateFilter = (data, name) => {
    if (data.length) {
      filters.filter(el => el.name === name);
      return {
        name: name,
        operation: "in",
        values: data.map(el => {
          return name === "location" ? el.label : el.value;
        })
      };
    } else {
      return false;
    }
  };
  generateFilter(roles, "role") &&
    filterBody.push(generateFilter(roles, "role"));

  generateFilter(songwriterType, "songwriterType") &&
    filterBody.push(generateFilter(songwriterType, "songwriterType"));

  generateFilter(location, "location") &&
    filterBody.push(generateFilter(location, "location"));

  generateFilter(modifiedBy, "modifiedBy") &&
    filterBody.push(generateFilter(modifiedBy, "modifiedBy"));

  generateFilter(userTitle, "userTitle") &&
    filterBody.push(generateFilter(userTitle, "userTitle"));
  generateFilter(userEmail, "userEmail") &&
    filterBody.push(generateFilter(userEmail, "userEmail"));

  generateFilter(accountingPeriod, "accountingPeriod") &&
    filterBody.push(generateFilter(accountingPeriod, "accountingPeriod"));

  generateFilter(statementPeriod, "settlementPeriod") &&
    filterBody.push(generateFilter(statementPeriod, "settlementPeriod"));
  return filterBody;
};
export const groupByValue = (arr, keyName) => {
  return arr.reduce((acc, document) => {
    const key = document[keyName];
    acc[key] = acc[key] || [];
    acc[key].push(document);
    return acc;
  }, {});
};
