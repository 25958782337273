import React, { useCallback, useState } from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import cn from "classnames";
import moment from "moment";

import styles from "./SetDownloadPopup.module.scss";
import { ReactComponent as LoadingIcon } from "../../../../icons/loading.svg";

import { useActions } from "../../../../hooks/useActions";
import { TableType } from "../../../../page/StatementUpload/types";
import { downloadCSV } from "../../../../page/StatementUpload/utils";
import { modalSelector } from "../../../../store/architectUsersStore/selectors";
import Icon from "../../Icon";
import Button, { ButtonIconPosition, ButtonType } from "../../UiButton/Button";
import DownloadData from "./DownloadData";

const SetDownloadPopup = ({ rowData }: any) => {
  const initialTableType = localStorage.getItem("uploadTableType") as TableType;
  const [loading, setLoading] = useState<boolean>(false);
  const modal = useSelector(modalSelector);
  const { SetOpenModalAction } = useActions();
  const closeModal = useCallback(() => {
    SetOpenModalAction({ isOpen: false, type: "" });
  }, [SetOpenModalAction]);
  const { handleClickDownload } = downloadCSV;
  const startFormatted = rowData?.startDate
    ? moment(rowData?.startDate).format("MMMM")
    : null;
  const endFormatted = rowData?.endDate
    ? moment(rowData?.endDate).format("MMMM YYYY")
    : null;
  const result = startFormatted ? `${startFormatted} - ${endFormatted}` : "-";
  return (
    <Modal
      ariaHideApp={false}
      isOpen={modal.isOpen && modal.type === "downloadModalClick"}
      onRequestClose={closeModal}
      className={cn(styles.modalContainer)}
      overlayClassName={styles.modalOverlay}
    >
      {loading ? (
        <div
          className={cn(styles.modalContainer_wrapper, {
            [styles.modalContainer_wrapperLoading]: loading
          })}
        >
          <LoadingIcon className={cn(styles.modalContainer_wrapper_loader)} />
        </div>
      ) : null}
      <div onClick={closeModal}>
        <Icon className={styles.closeIcon} kind="close" />
      </div>
      <div className={styles.title}>
        <div className={styles.description}>
          <p className={cn(styles.item)}>
            Uploaded by:{" "}
            <span className={styles.span}>{rowData?.userTitle}</span>
          </p>
          <p className={cn(styles.item)}>
            Upload Date:{" "}
            <span className={styles.span}>
              {moment(rowData?.createdAt).format("D MMM  YYYY")}
            </span>
          </p>
          <p className={cn(styles.item)}>
            Document Date: <span className={styles.span}>{result}</span>
          </p>
        </div>

        <div className={styles.buttonContainer}>
          <Button
            className={styles.downloadButton}
            variant={ButtonType.Default}
            text="Download Report"
            iconPosition={ButtonIconPosition.IconLeft}
            onClick={() => {
              handleClickDownload(rowData.id, initialTableType);
              closeModal();
            }}
          >
            <Icon className={styles.downloadIcon} kind="file_dnld_icon" />
          </Button>
        </div>
      </div>
      <DownloadData rowData={rowData} setLoading={setLoading} />
    </Modal>
  );
};
export default SetDownloadPopup;
