import { MenuItems } from "./types";

export const menuData: MenuItems = [
  {
    label: "menu.impersonateDifferentClient",
    slug: "/user-management",
    storedSlugKey: "UMSBackUrl",
    conditions: {
      shouldBeAdmin: true
    }
  },
  {
    label: "headerMenu.notificationSettings",
    slug: "/settings/notification"
  },
  {
    label: "headerMenu.home",
    slug: "/home",
    conditions: {
      emailTypeShouldNotInclude: ["PUB"],
      pathShouldNotInclude: ["/accounts"]
    }
  },
  {
    label: "headerMenu.analytics",
    slug: "/analytics",
    conditions: {
      emailTypeShouldNotInclude: ["PUB"],
      pathShouldNotInclude: ["/accounts"]
    }
  },
  {
    label: "headerMenu.songs",
    slug: "/songs",
    conditions: {
      emailTypeShouldNotInclude: ["PUB"],
      pathShouldNotInclude: ["/accounts"]
    }
  },
  {
    label: "headerMenu.documents",
    slug: "/documents",
    conditions: {
      pathShouldNotInclude: ["/accounts"]
    }
  },
  {
    label: "headerMenu.advances",
    slug: "/advances",
    conditions: {
      emailTypeShouldNotInclude: ["PUB"],
      pathShouldNotInclude: ["/accounts"],
      inCountry: ["GBR"]
    }
  },
  {
    submenu: {
      title: "common.helpSupport",
      list: [
        {
          label: "menu.aboutWcm",
          slug: "https://warnerchappell.com/"
        },
        {
          label: "menu.faq",
          slug: "/faq"
        },
        {
          label: "menu.contactUs",
          slug: "/contact-us"
        },
        {
          label: "menu.privacyPolicy",
          slug: "/privacy-policy"
        },
        {
          label: "menu.termOfUse",
          slug: "/terms-of-use"
        },
        {
          label: "menu.deleteAccount",
          className: "deleteAccount",
          slug: "/contact-us",
          query: "state=delete",
          conditions: {
            shouldNotBeAdmin: true
          }
        }
      ]
    }
  },
  {
    label: "menu.logout",
    icon: "logout",
    slug: "/logout"
  }
];
