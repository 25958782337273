import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import cn from "classnames";

import styles from "./Menu.module.scss";

import { useActions } from "../../../../hooks/useActions";
import {
  clientsSelector,
  payeeCodeSelector,
  profileSelector,
  singleClientSelector
} from "../../../../store/appStatic/selectors";
import { UserRole } from "../../../userManagement/api/users";
import { MenuGroup, MenuItem } from "./components";
import { menuData } from "./constants";
import { AccountData } from "./types";
import { validateMenuItems } from "./utils";

type MenuProps = {
  isMenuOpen: boolean;
  setOpenMenu: (newValue: boolean) => void;
};
const Menu: React.FC<MenuProps> = ({ isMenuOpen, setOpenMenu }: any) => {
  const { t } = useTranslation("translation");
  const history = useHistory();
  const { setSingleClient } = useActions();
  const profile = useSelector(profileSelector);
  const clients = useSelector(clientsSelector);
  const payeeCode = useSelector(payeeCodeSelector);
  const { data: singleClient } = useSelector(singleClientSelector);
  useEffect(() => {
    setSingleClient({
      data: clients.find((el: any) =>
        el?.payeeAccounts?.find((e: any) => e.payeeCode === payeeCode)
      )
    });
  }, [clients, history.location.pathname, payeeCode]);

  const isAdmin = [
    UserRole.ADMIN,
    UserRole.SUPER_ADMIN
    // UserRole.SENIOR_ADMIN
  ].includes(profile?.role as UserRole);
  const impersonateId = localStorage.getItem("impersonateId");
  const accountData: AccountData = {
    isAdmin,
    impersonateId,
    path: history.location.pathname,
    emailTypes: singleClient?.emailType,
    country: isAdmin ? singleClient?.profile?.countryCode : profile?.countryCode
  };

  const menuItems = useMemo(() => {
    return validateMenuItems(menuData, accountData);
  }, [menuData, accountData]);
  return (
    <div>
      <div
        className={cn(styles.container)}
        style={{ display: `${isMenuOpen ? "block" : "none"}` }}
      >
        <div className={cn(styles.menu)}>
          <div className={cn(styles.heading)}>
            <p className={cn(styles.email)}>
              {localStorage.getItem("impersonateEmail") || profile?.email}
            </p>
            {!history.location.pathname.includes("/accounts") && singleClient && (
              <Link to={`/profile`} className={cn(styles.link)}>
                {t("profilePage.userProfile")}
              </Link>
            )}
          </div>
          {menuItems.map(menuItem => {
            if (!menuItem) return null;
            if ("submenu" in menuItem) {
              return (
                <MenuGroup
                  data={menuItem.submenu}
                  key={menuItem.submenu.title}
                  setOpenMenu={setOpenMenu}
                />
              );
            }
            return (
              <MenuItem
                item={menuItem}
                key={menuItem.slug}
                setOpenMenu={setOpenMenu}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default Menu;
