import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import cn from "classnames";
import i18n from "i18next";
import moment from "moment";

import styles from "./Home.module.scss";
import { ReactComponent as CursorIcon } from "../../icons/arrow_black.svg";
import { ReactComponent as ChevronDownIcon } from "../../icons/chevrons_down.svg";
import { ReactComponent as ChevronUpIcon } from "../../icons/chevrons_up.svg";

import LedgerBlock from "../../components/shared/LedgerBlock";
import Loader from "../../components/shared/Loading";
import SetCountryModal from "../../components/shared/Modals/SetCountryModal";
import PayeeCodeInfo from "../../components/shared/PayeeCodeInfo";
import SearchInput from "../../components/shared/SearchInput";
import Table from "../../components/shared/Table";
import { getSortedData } from "../../configs/utils";
import { useActions } from "../../hooks/useActions";
import {
  payeeCodeSelector,
  profileSelector,
  singleClientSelector
} from "../../store/appStatic/selectors";
import {
  dealsQueryOptions,
  loadingDealsSelector,
  loadingLedgersSelector
} from "../../store/dealsStore/selectors";
import DealsPageCard from "./DealsPageCard";

const Home = () => {
  const { t } = useTranslation("translation");
  const { deals } = useSelector((state: any) => state.dealsReducer);
  const history = useHistory();
  const profile = useSelector(profileSelector);

  const queryOptions = useSelector(dealsQueryOptions);
  const dealsLoading = useSelector(loadingDealsSelector);
  const ledgersLoading = useSelector(loadingLedgersSelector);

  const {
    GetDealsAction,
    SetOpenModal,
    setQueryOptionsDeals,
    GetLedgersAction,
    clearPreselectedPayeCodeAction
  } = useActions();
  const [tooltipIsOpe, setToolTipOpen] = useState<boolean>(false);
  const [countryList, SetCountryList] = useState([]);
  const [modalTitle, SetModalTitle] = useState<string>("");
  const [currentList, setCurrentList] = useState<any>([]);
  const [paginateData, setPaginateData] = useState<any>([]);
  const singleClient = useSelector(singleClientSelector);
  const payeeCode = useSelector(payeeCodeSelector);
  const desc = useMediaQuery({ maxWidth: 1181 });
  const md = useMediaQuery({ maxWidth: 858 });
  const mb = useMediaQuery({ maxWidth: 750 });
  const m = useMediaQuery({ maxWidth: 567 });

  useEffect(() => {
    clearPreselectedPayeCodeAction();
  }, []);

  useEffect(() => {
    GetLedgersAction(payeeCode, history);
  }, [payeeCode]);
  useEffect(() => {
    if (queryOptions.sortBy.id && currentList?.length) {
      const sortedData = getSortedData(currentList, {
        sort: queryOptions.sortBy.id,
        order: queryOptions.sortBy.desc
      });
      setCurrentList(sortedData);
    }
  }, [queryOptions.sortBy.id, queryOptions.sortBy.desc, currentList.length]);
  const [searchInputValue, setSearchInputValue] = useState<string>("");

  useEffect(() => {
    if (currentList?.length) {
      setPaginateData(
        currentList
          ?.map((el: any, index: any) => ({ ...el, id: index + 1 }))
          .slice(
            queryOptions.page * queryOptions.size,
            (queryOptions.page + 1) * queryOptions.size
          ) || []
      );
    } else {
      setPaginateData([]);
    }
  }, [currentList, queryOptions.page, queryOptions.size, queryOptions.sortBy]);

  useEffect(() => {
    if (payeeCode) {
      if (singleClient?.data && deals?.payeeAccountCode !== payeeCode) {
        !singleClient?.data?.emailType?.includes("PUB") &&
          GetDealsAction(payeeCode, history);
      }
    }
  }, [payeeCode, singleClient?.data?.emailType]);

  function Songs({ row }: any) {
    return (
      <div className={cn(styles.table_column)}>{row.original.worksCount}</div>
    );
  }

  const Location = ({ row }: any) => {
    const data = row.original;
    if (row) {
      return (
        <div>
          <button
            className={cn(
              styles.capitalize,
              styles.tableValue_gray,
              "table-toolTip-item",
              data.territories.length > 1 && styles.underlineonhover
            )}
            data-tip=""
            data-for={
              data.territories.length > 1 && `TerritoryToolTip_${data.dealId}`
            }
            onClick={() => {
              SetModalTitle(data?.contractingParty);
              data.territories.length > 5 &&
                SetOpenModal({ isOpen: true, type: "countryList" });
              SetCountryList(data.territories);
            }}
          >
            {data.territories.length && data.territories.length > 1
              ? `${data.territories.length} ${t("common.territories")}`
              : data.territories.length === 1
              ? data.territories[0]
              : ""}
          </button>
          {data?.territories?.length > 1 && (
            <ReactTooltip
              globalEventOff={"mouseout"}
              id={`TerritoryToolTip_${data.dealId}`}
              place="top"
              effect="solid"
              border
              className={cn(styles.reactTooltip)}
              offset={{
                left: 0,
                top: -8
              }}
              afterShow={() => setToolTipOpen(true)}
              afterHide={() => setToolTipOpen(false)}
              // globalEventOff={isMobile ? "click" : undefined}
            >
              <span>{data?.territories.slice(0, 5).join(", ")}</span>
              {data?.territories?.length > 5 && "..."}
              {data?.territories?.length > 5 && (
                <div className={styles.reactTooltip_underline}>
                  {t(`dealAndSongs.administeredTerritoryMore`)}
                </div>
              )}
            </ReactTooltip>
          )}
        </div>
      );
    }
  };

  function Collapse({ row }: any) {
    return (
      <button
        className={cn(styles.chevron)}
        onClick={() => {
          setCurrentList(
            [...currentList].map((el: any) => {
              if (el.dealId === row.original.dealId) {
                return { ...el, isOpen: !row.original.isOpen };
              } else {
                return el;
              }
            })
          );
        }}
      >
        {!row.original.isOpen ? <ChevronDownIcon /> : <ChevronUpIcon />}
      </button>
    );
  }

  const getStartDateColumn = ({ value }: any) => {
    return (
      <div
        className={cn(
          // styles.capitalize,
          styles.tableValue,
          styles.tableValue_gray
        )}
      >
        {value
          ? moment
              .utc(new Date(value))
              .locale(i18n.language)
              .format("ll")
          : "-"}
      </div>
    );
  };

  const getStartEndDateColumn = ({ row }: any) => {
    const data = row.original;
    return (
      <div
        className={cn(
          // styles.capitalize,
          styles.tableValue,
          styles.tableValue_gray
        )}
      >
        {data.dealEndDate &&
          moment
            .utc(new Date(data.dealEndDate))
            .locale(i18n.language)
            .format("ll")}
        {data.dealEndDate && data.dealEndDate && "-"}
        {data.dealEndDate &&
          moment
            .utc(new Date(data.dealEndDate))
            .locale(i18n.language)
            .format("ll")}
      </div>
    );
  };

  const getEndDateColumn = ({ value }: any) => {
    return (
      <div
        className={cn(
          // styles.capitalize,
          styles.tableValue,
          styles.tableValue_gray
        )}
      >
        {value
          ? moment
              .utc(new Date(value))
              .locale(i18n.language)
              .format("ll")
          : "-"}
      </div>
    );
  };

  const columnDefs = useMemo<ColDef[]>(
    () => [
      {
        accessor: "dealId",
        Header: () => {
          return (
            <span className="table_heading" title={t(`dealAndSongs.dealId`)}>
              {t(`dealAndSongs.dealId`)}
            </span>
          );
        },
        maxWidth: 50
      },
      {
        accessor: "contractingParty",
        Header: () => {
          return (
            <span
              className="table_heading"
              title={t(`dealAndSongs.contractingParty`)}
            >
              {t(`dealAndSongs.contractingParty`)}
            </span>
          );
        },
        maxWidth: 395,
        Cell: (props: ICellRendererParams) => {
          return <div className={styles.tableValue}>{props.value}</div>;
        }
      },
      {
        accessor: "accountDealStartDate",
        Header: () => {
          return (
            <span className="table_heading" title={t(`dealAndSongs.startDate`)}>
              {t(`dealAndSongs.startDate`)}
            </span>
          );
        },
        maxWidth: 80,
        Cell: getStartDateColumn
      },
      {
        accessor: "dealEndDate",
        Header: () => {
          return (
            <span className="table_heading" title={t(`dealAndSongs.endDate`)}>
              {t(`dealAndSongs.endDate`)}
            </span>
          );
        },
        maxWidth: 80,
        Cell: getEndDateColumn
      },
      {
        accessor: "administeredTerritory",
        Header: () => {
          return (
            <span
              className="table_heading"
              title={t(`dealAndSongs.administeredTerritory`)}
            >
              {t(`dealAndSongs.administeredTerritory`)}
            </span>
          );
        },
        Cell: Location,
        width: 110
      },
      {
        accessor: "works",
        Header: () => {
          return (
            <span className="table_heading" title={t(`dealAndSongs.songs`)}>
              {t(`dealAndSongs.songs`)}
            </span>
          );
        },
        Cell: Songs,
        maxWidth: 50
      },
      {
        Header: "",
        id: "collapse",
        Cell: Collapse,
        withoutSort: true,
        maxWidth: 15
      }
    ],
    [queryOptions.sortBy.id, queryOptions.sortBy.desc, currentList, t]
  );

  const ipadColumnsLandscape = useMemo<ColDef[]>(
    () => [
      {
        accessor: "contractingParty",
        Header: () => {
          return (
            <span
              className="table_heading"
              title={t(`dealAndSongs.contractingParty`)}
            >
              {t(`dealAndSongs.contractingParty`)}
            </span>
          );
        },
        maxWidth: 150,
        width: 150,
        Cell: (props: ICellRendererParams) => {
          return <div className={styles.value}>{props.value}</div>;
        }
      },
      {
        accessor: "dealStartDate",
        Header: () => {
          return (
            <span
              className="table_heading"
              title={`${t(`dealAndSongs.startDate`)} / ${t(
                `dealAndSongs.endDate`
              )}`}
            >{`${t(`dealAndSongs.startDate`)} / ${t(
              `dealAndSongs.endDate`
            )}`}</span>
          );
        },
        width: 120,
        Cell: getStartEndDateColumn
      },
      {
        accessor: "administeredTerritory",
        Header: () => {
          return (
            <span
              className="table_heading"
              title={t(`dealAndSongs.administeredTerritory`)}
            >
              {t(`dealAndSongs.administeredTerritory`)}
            </span>
          );
        },
        Cell: Location,
        maxWidth: 90
      },
      {
        accessor: "works",
        Header: () => {
          return (
            <span className="table_heading" title={t(`dealAndSongs.songs`)}>
              {t(`dealAndSongs.songs`)}
            </span>
          );
        },
        Cell: Songs,
        maxWidth: 60
      },
      {
        Header: "",
        id: "collapse",
        Cell: Collapse,
        withoutSort: true,
        maxWidth: 15
      }
    ],
    [queryOptions.sortBy, currentList, t]
  );

  const ipadColumnsPortrait = useMemo<ColDef[]>(
    () => [
      {
        accessor: "contractingParty",
        Header: () => {
          return (
            <span
              className="table_heading"
              title={t(`dealAndSongs.contractingParty`)}
            >
              {t(`dealAndSongs.contractingParty`)}
            </span>
          );
        },
        maxWidth: 300,
        Cell: (props: ICellRendererParams) => {
          return <div className={styles.value}>{props.value}</div>;
        }
      },
      {
        accessor: "administeredTerritory",
        Header: () => {
          return (
            <span
              className="table_heading"
              title={t(`dealAndSongs.administeredTerritory`)}
            >
              {t(`dealAndSongs.administeredTerritory`)}
            </span>
          );
        },
        Cell: Location,
        maxWidth: 120
      },
      {
        accessor: "works",
        Header: () => {
          return (
            <span className="table_heading" title={t(`dealAndSongs.songs`)}>
              {t(`dealAndSongs.songs`)}
            </span>
          );
        },
        Cell: Songs,
        maxWidth: 60
      },
      {
        Header: "",
        id: "collapse",
        Cell: Collapse,
        withoutSort: true,
        maxWidth: 15
      }
    ],
    [queryOptions.sortBy, currentList, t]
  );

  const mobileColumns = useMemo<ColDef[]>(
    () => [
      {
        accessor: "contractingParty",
        Header: () => {
          return (
            <span
              className="table_heading"
              title={t(`dealAndSongs.contractingParty`)}
            >
              {t(`dealAndSongs.contractingParty`)}
            </span>
          );
        },
        maxWidth: 395,
        Cell: (props: ICellRendererParams) => {
          return <div className={styles.value}>{props.value}</div>;
        }
      },
      {
        accessor: "works",
        Header: () => {
          return (
            <span className="table_heading" title={t(`dealAndSongs.songs`)}>
              {t(`dealAndSongs.songs`)}
            </span>
          );
        },
        Cell: Songs,
        maxWidth: 100
      },
      {
        accessor: "cursor",
        Header: "",
        withoutSort: true,
        Cell: () => <CursorIcon className={styles.cursor} />,
        maxWidth: 30
      }
    ],
    [queryOptions.sortBy, currentList, t]
  );

  const tableColumns = useMemo(() => {
    if (mb) {
      return mobileColumns;
    }
    if (md) {
      return ipadColumnsPortrait;
    }
    if (desc) {
      return ipadColumnsLandscape;
    }
    return columnDefs;
  }, [desc, mb, md, currentList, queryOptions.sortBy, t]);

  useEffect(() => {
    if (deals?.deals) {
      setCurrentList(deals?.deals);
    }
  }, [deals]);

  const DetailCellRenderer = (row: any) => {
    const data = row.original;
    return (
      <div className={cn(styles.table_detail)}>
        <PayeeCodeInfo
          id="dealExecutedDate"
          name={t(`dealAndSongs.dealExecutedDate`)}
          tooltipText={t(`dealAndSongs.dealExecutedDateTooltip`)}
          label={
            data?.dealExecutedDate
              ? moment
                  .utc(new Date(data?.dealExecutedDate))
                  .locale(i18n.language)
                  .format("ll")
              : "-"
          }
          hasIcon
          className={styles.PayeeCodeInfo}
        />
        <PayeeCodeInfo
          id="contractType"
          name={t(`dealAndSongs.contractType`)}
          tooltipText={t(`dealAndSongs.contractTypeTooltip`)}
          label={data.contractType ? data.contractType : "-"}
          hasIcon
          className={cn(styles.PayeeCodeInfo, styles.PayeeCodeInfo_location)}
        />
        <PayeeCodeInfo
          name={t(`dealAndSongs.rightsTermPeriodStatus`)}
          tooltipText={t(`dealAndSongs.rightsTermPeriodStatusTooltip`)}
          label={data.status ? data.status : "-"}
          hasIcon
          className={styles.PayeeCodeInfo}
          id="rightsTermPeriodStatusTooltip"
        />
        <PayeeCodeInfo
          id="dealSigningTerritoryTooltip"
          name={t(`dealAndSongs.dealSigningTerritory`)}
          tooltipText={t(`dealAndSongs.dealSigningTerritoryTooltip`)}
          hasIcon
          label={data.dealSigningTerritory ? data.dealSigningTerritory : "-"}
          className={cn(styles.PayeeCodeInfo, styles.PayeeCodeInfo_location)}
        />
      </div>
    );
  };
  const onFilterTextBoxChanged = useCallback(
    e => {
      const filteredData = e.target.value
        ? deals?.deals.filter(
            (account: any) =>
              account?.contractingParty
                ?.toUpperCase()
                .includes(e.target.value.toUpperCase()) ||
              account?.dealId
                ?.toUpperCase()
                .includes(e.target.value.toUpperCase())
          )
        : deals?.deals;
      setCurrentList(filteredData);
      if (queryOptions.page > 0) {
        setQueryOptionsDeals({
          ...queryOptions,
          page: 0,
          size: queryOptions.size
        });
      }
      setSearchInputValue(e.target.value ? e.target.value : "");
    },
    [deals?.deals, queryOptions, searchInputValue]
  );
  const handleRowClick = useCallback(
    (el, e) => {
      const tagName = e.target.tagName;
      if (tagName !== "BUTTON" && tagName !== "svg" && tagName !== "path") {
        history.push(`/home/${el.original.dealId}`);
      }
    },
    [history, payeeCode]
  );

  const handleSort = useCallback(
    ({ sortBy }) => {
      if (sortBy[0]?.id) {
        setQueryOptionsDeals({ ...queryOptions, sortBy: sortBy[0] });
      }
    },
    [queryOptions.page]
  );

  const defaultSortBy = useMemo(() => {
    return [{ id: queryOptions.sortBy.id, desc: queryOptions.sortBy.desc }];
  }, [queryOptions]);

  const fetchData = useCallback(
    ({ pageIndex }: { pageSize: number; pageIndex: number }) => {
      if (queryOptions.page !== pageIndex) {
        setQueryOptionsDeals({ ...queryOptions, page: pageIndex });
      }
    },
    [queryOptions]
  );

  return (
    <>
      {!profile || dealsLoading || ledgersLoading ? (
        <Loader />
      ) : (
        <>
          <div className={cn(styles.container, "container")}>
            <div>
              <LedgerBlock />
              <div
                className={cn(
                  styles.table,
                  paginateData.length === 0 ? styles.table_nodata : "",
                  tooltipIsOpe ? styles.table_zindex : ""
                )}
              >
                {deals?.deals?.length ? (
                  <div className={cn(styles.table_head)}>
                    <h4 className={cn(styles.table_title)}>
                      {t(`dealAndSongs.myDeals`)}
                    </h4>
                    {deals?.deals.length > 5 && (
                      <SearchInput
                        onInput={onFilterTextBoxChanged}
                        placeholder={t(`dealAndSongs.dealSearch`)}
                        mobilePlaceholder={t(`dealAndSongs.dealSearch`)}
                        value={searchInputValue}
                        onclickClearIcon={onFilterTextBoxChanged}
                      />
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            {deals?.deals?.length ? (
              <>
                {m && (
                  <DealsPageCard
                    data={paginateData}
                    defaultSortBy={defaultSortBy}
                    handleSort={handleSort}
                  />
                )}
                <Table
                  data={paginateData}
                  isMobile={m}
                  columns={tableColumns}
                  totalItems={currentList?.length}
                  fetchData={fetchData}
                  callapsComponent={DetailCellRenderer}
                  onRowClick={handleRowClick}
                  onSort={handleSort}
                  defaultSortBy={defaultSortBy}
                  isWhitePagination={true}
                  defaultPage={queryOptions.page}
                  defaultPageSize={queryOptions.size}
                  onChangeSize={(e: any) => {
                    setQueryOptionsDeals({
                      ...queryOptions,
                      page: 0,
                      size: +e.value
                    });
                  }}
                />
              </>
            ) : (
              ""
            )}
            <SetCountryModal countries={countryList} modalTitle={modalTitle} />
          </div>
        </>
      )}
    </>
  );
};

export default Home;
