import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import cn from "classnames";

import styles from "./NoDataAnalytics.module.scss";

import { textParams } from "../../../utils";

const NoDataAnalytics = ({ className = "" }) => {
  const { t } = useTranslation();
  return (
    <div className={cn(styles.noDataAnalytics, className)}>
      <h3>{t(`common.noDataHeadline`)}</h3>
      <p>
        {textParams(t(`common.noDataText2`))[0]}{" "}
        <NavLink to={`/contact-us`} className={styles.link}>
          {textParams(t(`common.noDataText2`))[1]}{" "}
        </NavLink>
        {" ."}
      </p>
    </div>
  );
};

export default NoDataAnalytics;
