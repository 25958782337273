import { createTheme } from "@material-ui/core";

export const theme = createTheme({
  overrides: {
    MuiTooltip: {
      arrow: { "&:before": { backgroundColor: "black" } },
      tooltip: {
        backgroundColor: "black",
        borderRadius: 0,
        color: "#fafafa",
        fontFamily: "inherit",
        fontSize: "13px",
        lineHeight: "16px",
        padding: "15px"
      }
    }
  },
  props: {
    MuiTooltip: { arrow: true, enterDelay: 500, placement: "top" }
  }
});
