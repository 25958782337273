import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";

import styles from "./DocumentCards.module.scss";

import Button from "../../../../components/shared/UiButton";
import CheckBoxFiled from "../../../../components/shared/UiCheckBox";
import { getCurrentPageData } from "../../../../utils/pagination";
import { Document } from "../../Documents.types";
import { getPeriodOrPublishedDate } from "../../utils";
import { DownloadTypeSelector } from "../index";
import { DocumentCardsProps } from "./DocumentCards.types";

const DocumentCards = ({
  data,
  setSelectedDocuments,
  selectedDocuments,
  page,
  pageSize,
  handleDownload,
  setDownloadType,
  royaltyIsSelected,
  downloadType
}: DocumentCardsProps) => {
  const { t } = useTranslation("translation");

  const [currentPageData, setCurrentPageData] = useState<Document[]>([]);

  const selectedDocumentsLength = Object.keys(selectedDocuments).length;
  const isAllSelected =
    currentPageData.length > 0 &&
    currentPageData.length === selectedDocumentsLength;

  const toggleSelectAll = () => {
    setSelectedDocuments(prevSelectedDocuments => {
      const updatedSelectedRows = { ...prevSelectedDocuments };

      currentPageData.forEach(({ id }) => {
        if (isAllSelected) {
          delete updatedSelectedRows[id];
        } else {
          updatedSelectedRows[id] = true;
        }
      });

      return updatedSelectedRows;
    });
  };

  const toggleSelectRow = (rowId: string) => {
    setSelectedDocuments(prevSelectedDocuments => {
      const updatedSelectedRows = { ...prevSelectedDocuments };
      if (updatedSelectedRows[rowId]) {
        delete updatedSelectedRows[rowId];
      } else {
        updatedSelectedRows[rowId] = true;
      }
      return updatedSelectedRows;
    });
  };

  useEffect(() => {
    const currentData = getCurrentPageData(data, page, pageSize);

    setCurrentPageData(currentData);
  }, [page, pageSize]);

  return (
    <div>
      <CheckBoxFiled
        label={t("downloadStatement.selectAll")}
        name={"Select all"}
        onChange={toggleSelectAll}
        checked={isAllSelected}
        disabled={!currentPageData.length}
      />
      <div className={styles.docList}>
        {currentPageData.map(
          ({
            id,
            year,
            startDate,
            endDate,
            publishedAt,
            documentType,
            downloadCount
          }) => {
            return (
              <div key={id} className={styles.card} role="listitem">
                <CheckBoxFiled
                  isDark={false}
                  name={id}
                  onChange={() => toggleSelectRow(id)}
                  checked={selectedDocuments[id]}
                />
                <div className={styles.cardInfoWrapper}>
                  <div>
                    <span
                      className={cn(styles.yearText, "text-13 font-semibold")}
                    >
                      {year}
                    </span>
                    <span className={cn(styles.monthsText, "text-13")}>
                      {getPeriodOrPublishedDate(
                        startDate,
                        endDate,
                        publishedAt
                      )}
                    </span>
                  </div>
                  <span className={cn(styles.typeText, "text-11")}>
                    {t(`statementsPage.documents.documentType.${documentType}`)}
                  </span>
                </div>
                {!downloadCount && <div className={styles.mark} />}
              </div>
            );
          }
        )}
      </div>
      <div
        className={cn(styles.downloadBtnContainerMobile, {
          [styles.btnVisibleMobile]: !!selectedDocumentsLength
        })}
      >
        <DownloadTypeSelector
          isMobile={true}
          isVisible={royaltyIsSelected}
          setDownloadType={setDownloadType}
          downloadType={downloadType}
        />
        <Button
          className={styles.downloadBtnMobile}
          onClick={handleDownload}
          text={`${t("buttons.download")} (${selectedDocumentsLength})`}
        />
      </div>
    </div>
  );
};

export default DocumentCards;
