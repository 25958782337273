import React, { useCallback, useEffect } from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import cn from "classnames";
import moment from "moment/moment";

import styles from "../SetFilterModal/FilterModal.module.scss";
import { ReactComponent as CalendarIcon } from "../../../../icons/calendar_vector.svg";

import { GroupingFilters } from "../../../../configs/utils";
import { useActions } from "../../../../hooks/useActions";
import {
  historyDateRangeModalSelector,
  historyDateRangeSelector,
  historyFilterModalSelector,
  historyFiltersSelector,
  modalSelector
} from "../../../../store/architectUsersStore/selectors";
import Chip, { IChip } from "../../../userManagement/Filter/chip/Chip";
import SelectField from "../../../userManagement/Filter/SelectField";
import Button from "../../../userManagement/WCMButton";
import { ISelectItem } from "../SetFilterModal/FilterModal";
const HistoryFilterModal = () => {
  const modal = useSelector(modalSelector);
  const historyFilterModal = useSelector(historyFilterModalSelector);
  const historyDateRange = useSelector(historyDateRangeSelector);
  const historyDateRangeModal = useSelector(historyDateRangeModalSelector);
  const historyFilters = useSelector(historyFiltersSelector);

  const {
    SetOpenModalAction,
    SetHistoryFilterModal,
    GenerateHistoryFilter,
    SetHistoryFilters,
    SetHistoryDateRangeModal,
    SetHistoryDateRange
  } = useActions();
  const toggleModal = useCallback(() => {
    SetOpenModalAction({ isOpen: false, type: [] });
  }, [SetOpenModalAction]);
  const { uploaderList } = useSelector(
    (state: any) => state.architectUsersReducer
  );
  const uploaders = uploaderList.map((el: any) => {
    return {
      value: el.email,
      label: el.title,
      name: "userEmail",
      typeName: "Uploader",
      itemValue: `Uploader_${el.email}`,
      operation: "in"
    };
  });
  const handleChange = useCallback(
    (items: ISelectItem[], type: string, typeName: string) => {
      const filterList = items.map((itm: ISelectItem) => {
        return {
          ...itm,
          type: type,
          typeName: typeName,
          itemValue: `${type}_${itm.value}`
        };
      });
      SetHistoryFilterModal([
        ...historyFilterModal.filter((el: IChip) => el.type !== type),
        ...filterList
      ]);
    },
    [historyFilters, historyFilterModal]
  );
  useEffect(() => {
    GenerateHistoryFilter(
      GroupingFilters(historyFilters).concat(
        historyDateRange?.filterDateRange || []
      )
    );
  }, [historyDateRange, historyFilters]);

  useEffect(() => {
    if (modal.isOpen && modal.type.includes("HistoryFilterModal")) {
      SetHistoryFilterModal(historyFilters);
      SetHistoryDateRangeModal(historyDateRange);
    }
  }, [modal.isOpen]);

  const ApplyFilter = useCallback(() => {
    toggleModal();
    SetHistoryFilters(historyFilterModal);
    SetHistoryDateRange(historyDateRangeModal);
  }, [historyFilterModal, historyDateRangeModal]);
  const CancelFilter = useCallback(() => {
    toggleModal();
  }, [historyFilterModal, historyDateRangeModal]);
  const ClearFilter = useCallback(() => {
    SetHistoryFilterModal([]);
    SetHistoryDateRangeModal(null);
  }, [historyFilterModal, historyDateRangeModal]);
  const handleClick = () => {
    SetHistoryDateRangeModal(null);
  };
  return (
    <div className={styles.SetUserControlModal}>
      <Modal
        ariaHideApp={false}
        isOpen={modal.isOpen && modal.type.includes("HistoryFilterModal")}
        onRequestClose={toggleModal}
        className={cn(styles.mymodal)}
        overlayClassName={styles.myoverlay}
      >
        <h1 className={cn(styles.title)}>Filters</h1>
        <div className={cn(styles.filterModal_field)}>
          <p className={cn(styles.filterModal_field_label)}>Upload Date</p>
          <div
            className={cn(styles.dateInput)}
            onClick={() => {
              SetOpenModalAction({
                isOpen: true,
                type: ["rangeDatePickerModal", "HistoryFilterModal"]
              });
            }}
          >
            {historyDateRangeModal !== null ? (
              <Chip
                handleClick={() => handleClick()}
                type="uploadedAt"
                label={`${moment(historyDateRangeModal?.startDate)
                  .format("D MMM  YYYY")
                  .toUpperCase()} - ${moment(historyDateRangeModal?.endDate)
                  .format("D MMM  YYYY")
                  .toUpperCase()}`}
                itemValue="uploadedAt"
              />
            ) : (
              "--Select date--"
            )}
            <CalendarIcon className={cn(styles.calendar)} />
          </div>
        </div>
        <div className={cn(styles.filterModal_field)}>
          <p className={cn(styles.filterModal_field_label)}>Uploader</p>
          <SelectField
            isSearchable
            options={uploaders}
            placeholder="--Select uploader--"
            onChange={(item: ISelectItem[]) =>
              handleChange(item, "userTitle", "Uploader")
            }
            defaultValue={historyFilterModal.filter(
              (el: IChip) => el.type === "userTitle"
            )}
            value={historyFilterModal.filter(
              (el: IChip) => el.type === "userTitle"
            )}
          />
        </div>
        <div className={cn(styles.buttonList)}>
          <Button onClick={() => CancelFilter()} variant="text">
            Cancel
          </Button>
          <Button onClick={() => ClearFilter()} variant="text">
            Clear
          </Button>
          <Button onClick={() => ApplyFilter()} variant="outlined">
            Apply
          </Button>
        </div>
      </Modal>
    </div>
  );
};
export default HistoryFilterModal;
