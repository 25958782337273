import React from "react";
import { useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";
import cn from "classnames";

import styles from "../InfoComponent/info-component.module.scss";
import { ReactComponent as InfoIcon } from "../../../icons/white_info_icon.svg";
import { ReactComponent as InfoIconHovered } from "../../../icons/white_info_icon_hovered.svg";

import { CurrencyFormat } from "../../../configs/utils";
export interface CurrencyRateProps {
  rate: number;
  id: number | string;
  hasIcon: boolean;
}
const CurrencyRateShort: any = ({
  rate,
  id,
  hasIcon = false
}: CurrencyRateProps) => {
  const { ledgers } = useSelector((state: any) => state.dealsReducer);
  return (
    <>
      {Math.abs(rate) >= 1000 && (
        <ReactTooltip
          border
          id={`shortTooltip_${id}`}
          place="top"
          effect="solid"
          arrowColor="rgba(56, 56, 56, 1)"
          className={cn(styles.tooltipText, "payeeCodeToolTip")}
        >
          {CurrencyFormat(navigator.language, ledgers?.currency, rate)}
        </ReactTooltip>
      )}
      <span
        className={cn(
          styles.shortNumber,
          !hasIcon ? styles.shortNumber_inline : "",
          !hasIcon && Math.abs(rate) >= 1000 ? styles.shortNumber_underline : ""
        )}
        data-for={
          !hasIcon && Math.abs(rate) >= 1000 ? `shortTooltip_${id}` : ""
        }
        data-tip=""
      >
        {CurrencyFormat(navigator.language, ledgers?.currency, rate, "compact")}
        {Math.abs(rate) >= 1000 && hasIcon && (
          <span
            data-for={`shortTooltip_${id}`}
            data-tip=""
            className={cn(styles.iconBlock)}
          >
            <InfoIcon className={cn(styles.icon)} />
            <InfoIconHovered className={cn(styles.icon, styles.iconHovered)} />
          </span>
        )}
      </span>
    </>
  );
};

export default CurrencyRateShort;
