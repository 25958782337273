import axios, { AxiosPromise, AxiosRequestConfig } from "axios";

import { oktaAuth } from "../lib/oktaAuth";

export const request = (
  method: AxiosRequestConfig["method"],
  url: AxiosRequestConfig["url"],
  data?: AxiosRequestConfig["data"],
  headers?: AxiosRequestConfig["headers"],
  responseType?: AxiosRequestConfig["responseType"]
): AxiosPromise | null => {
  const res = axios({
    method,
    url,
    data,
    headers: {
      Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
      ...headers
    },
    responseType
  });
  res.catch(({ response }) => {
    if (response.status === 401) {
      window.location.href = "/logout";
    }
  });
  return res;
};
