import { createSelector } from "reselect";

const initialSelector = (state: any) => state.dealsReducer;

const initialWorksSelector = createSelector(initialSelector, state => state);
const songsQueryOptionsSelector = createSelector(
  initialSelector,
  state => state.songsQueryOptions
);
const dealsSelector = createSelector(initialSelector, state => state.deals);
const dealsQueryOptions = createSelector(
  initialSelector,
  state => state.queryOptions
);
const loadingDealsSelector = createSelector(
  initialSelector,
  state => state.dealsLoading
);
const loadingLedgersSelector = createSelector(
  initialSelector,
  state => state.ledgersLoading
);
const dealsModalSelector = createSelector(
  initialSelector,
  state => state.modal
);
const ledgerSelector = createSelector(initialSelector, state => state.ledger);
const worksErrorSelector = createSelector(
  initialSelector,
  state => state.worksError
);
const payeeCodesSelector = createSelector(
  initialSelector,
  state => state.payeeCodes
);

const worksSelector = createSelector(initialSelector, state => state.works);
const worksLoadingSelector = createSelector(
  initialSelector,
  state => state.worksLoading
);

export {
  dealsModalSelector,
  dealsQueryOptions,
  dealsSelector,
  initialWorksSelector,
  ledgerSelector,
  loadingDealsSelector,
  loadingLedgersSelector,
  payeeCodesSelector,
  songsQueryOptionsSelector,
  worksErrorSelector,
  worksLoadingSelector,
  worksSelector
};
