export const getUrlParams = (url = "") => {
  const params: any = {};
  url
    .slice(1)
    .split("&")
    .forEach(el => {
      const [key, value] = el.split("=");
      params[key] = value;
    });
  return params;
};

export const getKeyByValue = (object: any, value: string) => {
  return Object.keys(object).find(key => object[key] === value);
};

export function dynamicSort(property: string) {
  let sortOrder = 1;
  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function(a: any, b: any) {
    const result =
      a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
}

export const getDateByUTCFormat = (currentDate: string) => {
  const date = new Date(currentDate);
  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];
  const mountIndex = date.getMonth();
  const day = date.getUTCDate();
  const year = date.getFullYear();
  const hour = date.getHours();
  const minute = date.getMinutes();
  const tz = Math.abs(new Date().getTimezoneOffset() / 60);
  return `${month[mountIndex]} ${day}, ${year} - ${hour}:${minute} (UTC+${tz})`;
};
export const getDateByUTCFormatNoTime = (currentDate: string) => {
  const date = new Date(currentDate);
  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];
  const mountIndex = date.getMonth();
  const day = date.getUTCDate();
  const year = date.getFullYear();
  return month[mountIndex] ? `${month[mountIndex]} ${day}, ${year}` : "";
};
export const textParams = (text: any) => {
  return text.split("%%");
};
export const replaceText = (text: string, newText?: string) => {
  if (newText) {
    return text.replace("%% %%", newText);
  } else {
    return text;
  }
};
export const convertDocumentPeriodToString = (
  startDate: string,
  endDate: string
) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];
  const startDateObj = new Date(startDate);
  const endDateObj = new Date(endDate);
  const startMonth = months[startDateObj.getUTCMonth()];
  const endMonth = months[endDateObj.getUTCMonth()];

  return `${startMonth} - ${endMonth} ${startDateObj.getUTCFullYear()}`;
};
export const convertToShortDate = (date: string) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ];
  const dateObj = new Date(date);
  const day = dateObj.getUTCDate();
  const month = months[dateObj.getUTCMonth()];
  const year = dateObj.getUTCFullYear();

  return `${day} ${month} ${year}`;
};
