import { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";

import styles from "./CodeInput.module.scss";

import Button from "../../../../components/shared/UiButton";
import { ButtonType } from "../../../../components/shared/UiButton/Button";
import { CodeInputProps } from "./CodeInput.types";

/**
 * Render code input for MFA form
 * @param props React component properties
 * @returns JSX element
 */
const CodeInput = (props: CodeInputProps): JSX.Element => {
  const { t } = useTranslation("translation");

  const [isInputVisible, setIsInputVisible] = useState(false);
  const [passCode, setPassCode] = useState("      ");

  const onChange = (e: ChangeEvent<HTMLInputElement>, index: number): void => {
    if (!/^\d*$/.test(e.target.value)) {
      return;
    }
    const newValue = e.target.value.substring(0, 1);
    const newPassCode =
      passCode.substring(0, index) +
      (newValue || " ") +
      passCode.substring(index + 1, 6);
    setPassCode(newPassCode);
    if (newValue !== "" && e.target.nextElementSibling instanceof HTMLElement) {
      e.target.nextElementSibling.focus();
    }
  };

  const onEnterCodeClick = (
    e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement, MouseEvent>
  ): void => {
    e.preventDefault();
    setIsInputVisible(true);
  };

  const onKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ): void => {
    if (
      e.key === "Backspace" &&
      e.currentTarget.value === "" &&
      e.currentTarget.previousElementSibling instanceof HTMLElement &&
      index > 0
    ) {
      const newPassCode =
        passCode.substring(0, index - 1) + " " + passCode.substring(index, 6);
      setPassCode(newPassCode);
      e.currentTarget.previousElementSibling?.focus();
    }
  };

  const onSubmit = (
    e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement, MouseEvent>
  ): void => {
    e.preventDefault();
    if (!props.isSubmitting) {
      void props.onSubmit(passCode);
    }
  };

  return (
    <div className={props.className}>
      {isInputVisible && (
        <form className={styles.form}>
          <fieldset className={styles.fieldset}>
            <div>
              <legend className={styles.legend}>{t("mfa.enterCode")}</legend>
            </div>
            <div className={styles.inputContainer}>
              <input
                autoComplete="one-time-code"
                className={styles.input}
                name="digit-0"
                onChange={e => onChange(e, 0)}
                onKeyDown={e => onKeyDown(e, 0)}
                type="tel"
                value={passCode[0].trim()}
              />
              <input
                autoComplete="one-time-code"
                className={styles.input}
                name="digit-1"
                onChange={e => onChange(e, 1)}
                onKeyDown={e => onKeyDown(e, 1)}
                type="tel"
                value={passCode[1].trim()}
              />
              <input
                autoComplete="one-time-code"
                className={styles.input}
                name="digit-2"
                onChange={e => onChange(e, 2)}
                onKeyDown={e => onKeyDown(e, 2)}
                type="tel"
                value={passCode[2].trim()}
              />
              <input
                autoComplete="one-time-code"
                className={styles.input}
                name="digit-3"
                onChange={e => onChange(e, 3)}
                onKeyDown={e => onKeyDown(e, 3)}
                type="tel"
                value={passCode[3].trim()}
              />
              <input
                autoComplete="one-time-code"
                className={styles.input}
                name="digit-4"
                onChange={e => onChange(e, 4)}
                onKeyDown={e => onKeyDown(e, 4)}
                type="tel"
                value={passCode[4].trim()}
              />
              <input
                autoComplete="one-time-code"
                className={styles.input}
                name="digit-5"
                onChange={e => onChange(e, 5)}
                onKeyDown={e => onKeyDown(e, 5)}
                type="tel"
                value={passCode[5].trim()}
              />
            </div>
          </fieldset>
          <Button
            className={styles.submitButton}
            loading={props.isSubmitting}
            onClick={onSubmit}
            type="submit"
          >
            {t("mfa.verify")}
          </Button>
        </form>
      )}
      {!isInputVisible && (
        <Button
          className={styles.enterCodeButton}
          onClick={onEnterCodeClick}
          variant={ButtonType.Text}
        >
          {t("mfa.enterCodeButton")}
        </Button>
      )}
    </div>
  );
};

export default CodeInput;
