import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { NavLink, useHistory } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import axios from "axios";
import cn from "classnames";

import styles from "./ForgotPassword.module.scss";

import Button from "../../../components/shared/UiButton";
import { ButtonType } from "../../../components/shared/UiButton/Button";
import ResponseAlert from "../../../components/shared/UiResponseAlert";
import { ResponseAlertType } from "../../../components/shared/UiResponseAlert/ResponseAlert";
import TextField from "../../../components/shared/UiTextField";
import { textParams } from "../../../utils";
import { getErrorDetails } from "../../../utils/error";
import SuccessModal from "./SuccessModal";

const ForgotPassword = () => {
  const { t } = useTranslation("translation");
  const [error, setError] = useState<null | string>(null);
  const [errorLink, setErrorLink] = useState<null | {
    text: string;
    url: string;
  }>(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState<null | boolean>(null);
  const history = useHistory();
  const { authState } = useOktaAuth();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors }
  } = useForm({ mode: "onChange" });

  const closeError = (): void => {
    setError(null);
    setErrorLink(null);
  };

  const handleError = (e: unknown): void => {
    const { errorCode, status } = getErrorDetails(e);
    if (status === 400 && errorCode === "WCP_E_114") {
      setError(textParams(t("troubleToLoginPage.registrationError"))[0]);
      setErrorLink({
        text: textParams(t("troubleToLoginPage.registrationError"))[1],
        url: "/registration"
      });
      return;
    }
    if (
      status === 400 &&
      errorCode === "WCM_E_216" &&
      process.env.REACT_APP_PASS_RESET_WMG_URI
    ) {
      window.open(process.env.REACT_APP_PASS_RESET_WMG_URI)?.focus();
      return;
    }
    if (
      status === 400 &&
      errorCode === "WCM_E_217" &&
      process.env.REACT_APP_PASS_RESET_ADA_URI
    ) {
      window.open(process.env.REACT_APP_PASS_RESET_ADA_URI)?.focus();
      return;
    }
    if (status === 404) {
      setError(textParams(t("troubleToLoginPage.errorMessage"))[0]);
      setErrorLink({
        text: textParams(t("troubleToLoginPage.errorMessage"))[1],
        url: "/contact-us"
      });
      return;
    }
    setError(t("common.unknownError"));
    setErrorLink(null);
  };

  const onSubmit = async ({ email }: any) => {
    closeError();
    setIsLoading(true);
    setIsSuccess(false);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URI}api/users/external/reset-password`,
        {
          email,
          language: localStorage.getItem("i18nextLng")
        }
      );
      if (res) {
        setIsSuccess(true);
      }
    } catch (e) {
      handleError(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (authState?.isAuthenticated) {
      history.push("/");
    }
  }, [history, authState?.isAuthenticated]);

  return isSuccess ? (
    <SuccessModal />
  ) : (
    <div className="container">
      <form onSubmit={handleSubmit(onSubmit)} className={styles.forgotPassword}>
        <h3 className={styles.title}>
          {t(`troubleToLoginPage.troubleToLogin`)}
        </h3>
        <p className={styles.description}>
          {t(`troubleToLoginPage.resetYourPassword`)}
        </p>
        {error && (
          <ResponseAlert
            type={ResponseAlertType.Error}
            onClick={closeError}
            className={styles.alert}
          >
            <div>
              <span className={styles.errorMessage}>{error}</span>
              {errorLink && (
                <NavLink to={errorLink.url} className={styles.linkContact}>
                  {errorLink.text}
                </NavLink>
              )}
            </div>
          </ResponseAlert>
        )}
        <TextField
          className={cn(styles.inputs, { [styles.withError]: !!error })}
          type={"text"}
          inputs
          label={t(`inputs.emailAddress`)}
          placeholder={"user@example.com"}
          errors={{ email: error }}
          {...register("email", {
            required: "field is mandatory",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "invalid email address"
            },
            onChange: event => {
              setValue("email", event.target.value.replace(/\s/g, ""));
            }
          })}
        />
        <div className={styles.actions}>
          <NavLink to={"/registration"} className={styles.link}>
            {t(`loginPage.createNewAccount`)}
          </NavLink>
          <div className={styles.actions_buttons}>
            <Button
              variant={ButtonType.Text}
              type={"button"}
              onClick={() => history.goBack()}
              className={styles.backButton}
            >
              {t(`buttons.back`)}
            </Button>
            <Button
              variant={ButtonType.Primary}
              type={"submit"}
              loading={isLoading}
              disabled={!!isLoading || !(!errors?.email && watch("email"))}
            >
              {t(`buttons.reset`)}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ForgotPassword;
