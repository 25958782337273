import React from "react";
import { useTranslation } from "react-i18next";
import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from "@tanstack/react-table";
import classNames from "classnames";

import styles from "./Table.module.scss";

import Condition from "../Condition";
import Icon from "../Icon";
import LoadingCircle from "../LoadingCircle";
import { TableProps } from "./Table.types";

const Table = <TData, TValue>({
  loading,
  columns,
  data,
  className,
  manualPagination = false,
  size = "md",
  ...rest
}: TableProps<TData, TValue>) => {
  const { t } = useTranslation();
  t;
  className;
  size;

  const table = useReactTable({
    ...rest,
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: manualPagination,
    defaultColumn: {
      size: 100,
      minSize: 30,
      maxSize: 300
    }
  });

  return (
    <table
      className={classNames(styles.table, styles[size as string], className)}
    >
      <thead>
        {table.getHeaderGroups().map(headerGroup => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map(header => (
              <th
                style={{
                  width: header.column.getSize()
                }}
                key={header.id}
                className={styles.headerClass}
                onClick={header.column.getToggleSortingHandler()}
              >
                <div
                  title={
                    header.column.getCanSort()
                      ? header.column.getNextSortingOrder() === "asc"
                        ? "Sort ascending"
                        : header.column.getNextSortingOrder() === "desc"
                        ? "Sort descending"
                        : "Clear sort"
                      : undefined
                  }
                >
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                  <Condition
                    condition={header.column.getCanSort()}
                    Truthy={
                      {
                        asc: <Icon kind="sort_up" />,
                        desc: <Icon kind="sort_down" />
                      }[header.column.getIsSorted() as string] ?? (
                        <Icon kind="sort" />
                      )
                    }
                  />
                </div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map(row => (
          <tr key={row.id} className={styles.row}>
            {row.getVisibleCells().map(cell => (
              <td
                style={{
                  width: cell.column.getSize()
                }}
                key={cell.id}
                className={styles.tableCell}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
        <Condition
          condition={!data.length && !loading}
          Truthy={
            <tr className={styles.noResultRow}>
              <td colSpan={100}>{t(`common.noResult`)}</td>
            </tr>
          }
        />
        <Condition
          condition={!!loading}
          Truthy={
            <div className={classNames({ [styles.loadingRow]: loading })}>
              <LoadingCircle isL />
            </div>
          }
        />
      </tbody>
    </table>
  );
};

export default Table;
