import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import cn from "classnames";

import styles from "./TopSongsChart.module.scss";
import { ReactComponent as DownIcon } from "../../../icons/down_vector.svg";
import { ReactComponent as UpIcon } from "../../../icons/up.svg";

import {
  songsLoadingSelector,
  songsTopSelector
} from "../../../store/analytics/selectors";
import ChartCard from "../ChartCard";
import CurrencyRateShort from "../CurrencyRateShort";
import NoDataAnalytics from "../NoDataAnalytics";
import SongsChart from "./SongsChart";

type TopSongsChartType = {
  isInnerPage?: boolean;
};

const TopSongsChart: React.FC<TopSongsChartType> = ({ isInnerPage }) => {
  const { t } = useTranslation();
  const songsTop = useSelector(songsTopSelector);
  const songsLoading = useSelector(songsLoadingSelector);

  if (isInnerPage) {
    return <SongsChart />;
  }
  return (
    <ChartCard
      title={t(`analytics.topSongs`)}
      isList
      info={songsTop?.topWorks}
      to={"topSongs"}
      isLoading={songsLoading}
    >
      {songsTop !== null && !songsTop?.topWorks?.length && !songsLoading && (
        <NoDataAnalytics />
      )}
      {songsTop === null && !songsLoading && <NoDataAnalytics />}
      {songsTop?.topWorks?.length && !songsLoading ? (
        <div>
          <SongsChart />
          <div className={styles.total}>
            <p>{t(`analytics.allSongs`)}</p>
            <div className={styles.wrapCount}>
              <p className={styles.count}>
                <CurrencyRateShort id="topSongs_total" rate={songsTop?.total} />
              </p>
              {!!songsTop?.percentage && (
                <p
                  className={cn(styles.percent, {
                    [styles.down]: songsTop?.direction !== "INCREMENT"
                  })}
                >
                  {`${songsTop?.percentage}%`}
                  {songsTop?.direction === "INCREMENT" ? (
                    <UpIcon className={styles.upIcon} />
                  ) : (
                    <DownIcon className={styles.upIcon} />
                  )}
                </p>
              )}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </ChartCard>
  );
};

export default TopSongsChart;
