import React, { useCallback, useState } from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import cn from "classnames";
import moment from "moment";

import styles from "./SetUserControlModal.module.scss";
import { ReactComponent as CloseIcon } from "../../../../icons/close.svg";
import { ReactComponent as WarningIcon } from "../../../../icons/warning.svg";

import { useActions } from "../../../../hooks/useActions";
import { jc } from "../../../../lib/styles";
// import { UMSTypeSearchEnum } from "../../../../page/UserManagement/types";
import {
  modalSelector
  // UMSTypeSearchSelector
} from "../../../../store/architectUsersStore/selectors";
import Condition from "../../Condition";
import LoadingCircle from "../../LoadingCircle";

const SetImpersonatePopup = () => {
  const modal = useSelector(modalSelector);
  // const UMSTypeSearch = useSelector(UMSTypeSearchSelector);

  const history = useHistory();

  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const isSuspendList = Array.isArray(modal?.user);

  const {
    SetOpenModalAction,
    setProfileAction,
    getLanguageAction,
    setPayeeCodeAction,
    setPreslectedPayeeCodAction
  } = useActions();

  const { user } = useSelector(modalSelector);
  const handleClearState = useCallback(() => {
    setIsSuccess(false);
    setIsLoading(false);
    setError("");
  }, []);

  const handleImpersonateClick = useCallback(
    user => {
      localStorage.setItem("impersonateId", user.id);
      localStorage.setItem("impersonateEmail", user.email);
      localStorage.setItem("impersonateUser", JSON.stringify(user));
      closeModal();
      getLanguageAction(user.id);
      setProfileAction(history, user.id);
      if (user.payeeCode) {
        localStorage.setItem("impersonatePayeeCode", user.payeeCode);
        localStorage.setItem("payeeCode", user.payeeCode);
        setPayeeCodeAction(user.payeeCode);
        setPreslectedPayeeCodAction(user.payeeCode);
        user.documentType
          ? history.push("/documents")
          : history.push("/accounts");
      }
      closeModal();
    },
    [user]
  );

  const generateTagClassName = (status: string) => {
    return jc(
      styles.status,
      (typeof status === "undefined" || status === "") && `${styles.status}`,
      status === "INVITED" && `${styles.status_invited}`,
      status === "PENDING_REGISTERED" && `${styles.status_invited}`,
      status === "REGISTERED" && `${styles.status_registered}`,
      status === "REVOKED" && `${styles.status_revoked}`,
      status === "ACTIVE" && `${styles.status_active}`,
      status === "INACTIVE" && `${styles.status_inactive}`,
      status === "SUSPENDED" && `${styles.status_suspended}`
    );
  };

  const closeModal = useCallback(() => {
    SetOpenModalAction({ isOpen: false, type: "" });
    handleClearState();
  }, [SetOpenModalAction, handleClearState]);

  return (
    <div className={styles.content}>
      <Modal
        ariaHideApp={false}
        isOpen={modal.isOpen && modal?.user && modal.type === "impersonate"}
        onRequestClose={closeModal}
        className={cn(styles.impersonateModal, {
          [styles.success]: isSuccess,
          [styles.suspendModal]: isSuspendList
        })}
        overlayClassName={styles.myoverlay}
      >
        <div className={styles.actionWrap}>
          <CloseIcon className={styles.closeIcon} onClick={closeModal} />
          <div>Impersonate</div>
          <div className={styles.fullName}>
            {user?.title ? user?.title : user?.email}
          </div>
          <Condition
            condition={!user?.isPayee}
            Truthy={
              <div
                className={cn(
                  styles.status,
                  generateTagClassName(user?.status)
                )}
              >
                {user?.status === "PENDING_REGISTERED"
                  ? "Pending Registered"
                  : user?.status}
              </div>
            }
          />

          <div className={styles.wraper}>
            <div className={styles.emailWrap}>
              <Condition
                condition={!user?.isPayee}
                Truthy={
                  <>
                    <span className={styles.userLabel}>Email:</span>
                    <span className={styles.userValue}>{user?.email}</span>
                  </>
                }
                Falsy={
                  <>
                    <span className={styles.userLabel}>Payee Code:</span>
                    <span className={styles.userValue}>{user?.payeeCode}</span>
                  </>
                }
              />
            </div>
            {!!user?.location && (
              <div className={styles.emailWrap}>
                <span className={styles.userLabel}>Location:</span>
                <span className={styles.userValue}>{user?.location}</span>
              </div>
            )}
            <div className={styles.impersonationDate}>
              {"Impersonation date is "} {moment(new Date()).format("L")}
            </div>
            <div className={styles.impersonationText}>
              {
                "Impersonation will automatically end <1 hour later> or as soon as you impersonate a different Portal User"
              }
            </div>
          </div>
        </div>
        <div className={styles.buttonWrap}>
          {isLoading && <LoadingCircle className={styles.loadingCircle} />}
          {error && (
            <div className={styles.userError}>
              <WarningIcon className={styles.warning} />
              {error}
            </div>
          )}
          <button
            className={styles.button}
            onClick={() => handleImpersonateClick(user)}
          >
            Impersonate
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default SetImpersonatePopup;
