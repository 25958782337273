import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import axios from "axios";

import styles from "./ChangePassword.module.scss";

import Button from "../../../components/shared/UiButton";
import { ButtonType } from "../../../components/shared/UiButton/Button";
import ResponseAlert from "../../../components/shared/UiResponseAlert";
import { ResponseAlertType } from "../../../components/shared/UiResponseAlert/ResponseAlert";
import TextField from "../../../components/shared/UiTextField";
import { useActions } from "../../../hooks/useActions";
import { oktaAuth } from "../../../lib/oktaAuth";
import { profileSelector } from "../../../store/appStatic/selectors";
import { textParams } from "../../../utils";
import { getErrorDetails } from "../../../utils/error";

const ChangePassword = () => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    watch,
    formState: { isDirty, isValid }
  } = useForm({ mode: "all" });
  const password = useRef({});
  password.current = watch("password", "");
  const history = useHistory();
  const isCreate = history.location.pathname.includes("create-password");
  const [error, setError] = useState<null | string>(null);
  const [isLoading, setIsLoading] = useState<null | boolean>(null);
  const profile = useSelector(profileSelector);
  const impersonateEmail = localStorage.getItem("impersonateEmail");
  const { SetOpenModal } = useActions();
  const [status, setStatus] = useState(null);

  const onSubmit = async ({ password, repeatPassword }: any) => {
    setIsLoading(true);
    setError(null);
    try {
      const requestPassword = password === repeatPassword ? password : null;
      if (requestPassword) {
        let token: any;
        let changePassError = false;
        if (!status) {
          await axios
            .post(
              `${process.env.REACT_APP_BASE_URI}api/users/external/change-password`,
              { email: impersonateEmail || profile?.email },
              {
                headers: {
                  ContentType: "application/json",
                  authorization: `Bearer ${oktaAuth.getAccessToken()}`
                }
              }
            )
            .then(response => {
              token = response?.data;
              setStatus(response?.data);
              changePassError = true;
            })
            .catch(e => {
              setStatus(null);
              const { errorCode, status } = getErrorDetails(e);
              if (
                status === 400 &&
                errorCode === "WCM_E_216" &&
                process.env.REACT_APP_PASS_RESET_WMG_URI
              ) {
                window.open(process.env.REACT_APP_PASS_RESET_WMG_URI)?.focus();
                return;
              }
              if (
                status === 400 &&
                errorCode === "WCM_E_217" &&
                process.env.REACT_APP_PASS_RESET_ADA_URI
              ) {
                window.open(process.env.REACT_APP_PASS_RESET_ADA_URI)?.focus();
                return;
              }
              if (status !== 200) {
                setError(t(`resetPasswordPage.errorMessageUnknown`));
                changePassError = false;
              }
            });
        }
        if (!changePassError) {
          return false;
        } else {
          const stateToken = await axios.post(
            `${process.env.REACT_APP_OKTA_PATH}api/v1/authn/recovery/token`,
            {
              recoveryToken: token || status
            }
          );
          const url = `${process.env.REACT_APP_OKTA_PATH}api/v1/authn/credentials/reset_password`;
          const requestBody = isCreate
            ? {
                stateToken: stateToken.data.stateToken,
                newPassword: requestPassword,
                verifyPassword: requestPassword
              }
            : {
                newPassword: requestPassword,
                stateToken: stateToken.data.stateToken
              };
          const res: any = await axios.post(url, requestBody).catch(() => {
            setStatus(null);
          });
          if (res.data) {
            SetOpenModal({ isOpen: true, type: "successPassword" });
            history.push("/profile");
          }
        }
      }
    } catch {
      setStatus(null);
      setError(t("resetPasswordPage.errorMessagePasswordRequirement"));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.resetPassword}>
      <h3 className={styles.title}>
        {isCreate
          ? t(`resetPasswordPage.setPassword`)
          : t(`resetPasswordPage.resetPassword`)}
      </h3>
      {error && (
        <ResponseAlert
          className={styles.error}
          type={ResponseAlertType.Error}
          onClick={() => setError(null)}
        >
          <div>{error}</div>
        </ResponseAlert>
      )}
      <p className={styles.description}>{t(`resetPasswordPage.text`)}</p>
      <TextField
        type="password"
        label={t(`inputs.createPassword`)}
        className={styles.inputs}
        {...register("password", {
          required: "field is mandatory",
          minLength: {
            value: 8,
            message: "Password must have at least 8 characters"
          }
        })}
      />
      <TextField
        type="password"
        label={t(`inputs.confirmPassword`)}
        className={styles.inputs}
        {...register("repeatPassword", {
          required: "field is mandatory",
          validate: value =>
            value === password.current || "The passwords do not match"
        })}
      />
      <div className={styles.actions}>
        <div className={styles.links}>
          {textParams(t(`resetPasswordPage.changePasswordText`))[0]}{" "}
          <NavLink to={"/terms-of-use"} className={styles.link}>
            {textParams(t(`resetPasswordPage.changePasswordText`))[1]}
          </NavLink>{" "}
          {textParams(t(`resetPasswordPage.changePasswordText`))[2]}{" "}
          <NavLink to={"/privacy-policy"} className={styles.link}>
            {textParams(t(`resetPasswordPage.changePasswordText`))[3]}
          </NavLink>
        </div>
        <Button
          type={"submit"}
          variant={ButtonType.Primary}
          disabled={!isDirty || !isValid || !!isLoading}
          className={styles.buttonCreate}
          loading={!!isLoading}
        >
          {isCreate ? t(`buttons.create`) : t(`buttons.reset`)}
        </Button>
      </div>
    </form>
  );
};

export default ChangePassword;
