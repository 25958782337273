import { call, put, select, takeEvery } from "redux-saga/effects";

import { request } from "../../services";
import { payeeCodeSelector } from "../appStatic/selectors";
import {
  GetDealsSuccessAction,
  GetLedgersSuccessAction,
  getWorksSuccess,
  SetDealsLoading,
  SetLedgersLoading,
  setWorksError,
  worksLoading
} from "./actions";
import { DealsTypes } from "./types";

const { REACT_APP_BASE_URI } = process.env;
let isFetchedDeal = true;

export function* GetDealsAsync({ payload }: any): Generator<any, any, any> {
  try {
    yield put(SetDealsLoading(true));
    if (isFetchedDeal) {
      isFetchedDeal = false;
      const { data } = yield call(
        request,
        "get",
        `${REACT_APP_BASE_URI}api/royalty/${payload.id}/deals`
      );
      yield put(GetDealsSuccessAction({ data }));
      yield put(SetDealsLoading(false));
    }
  } catch {
    yield put(GetDealsSuccessAction([]));
    yield put(SetDealsLoading(false));
  } finally {
    yield put(SetDealsLoading(false));
    isFetchedDeal = true;
  }
}

declare global {
  interface Window {
    ledgerId: string | null | number;
  }
}
window.ledgerId;

export function* GetLedgersAsync({ payload }: any): Generator<any, any, any> {
  if (payload?.payeeCode !== window.ledgerId && payload?.payeeCode) {
    window.ledgerId = payload.payeeCode;
    try {
      yield put(SetLedgersLoading(true));
      const res = yield call(
        request,
        "get",
        `${REACT_APP_BASE_URI}api/royalty/${payload.payeeCode}/ledger`
      );
      yield put(GetLedgersSuccessAction({ ledgers: res?.data || [] }));
      yield put(SetLedgersLoading(false));
    } catch {
      yield put(GetLedgersSuccessAction({ ledgers: [] }));
    } finally {
      yield put(SetLedgersLoading(false));
    }
  }
}

let page: any = null;
let size: any = null;
const deals: any = null;

export function* GetWorksAsync({ payload }: any): Generator<any, any, any> {
  if (
    page !== +payload.query.page ||
    size !== +payload.query.size ||
    deals !== payload.params.deals
  ) {
    page = +payload.query.page;
    size = +payload.query.size;
    try {
      const payeeCode = yield select(payeeCodeSelector);
      yield put(worksLoading(true));
      let res = null;
      try {
        res = yield call(
          request,
          "get",
          `${REACT_APP_BASE_URI}api/royalty/${payeeCode}${
            payload.params.deals ? `/deals/${payload.params.deals}` : ""
          }/works?page=${payload.query.page}&size=${payload.query.size}&sort=${
            payload.query.sortBy.id
          }&order=${payload.query.sortBy.desc ? "DESC" : "ASC"}&search=${
            payload.query.value
          }`
        );
        localStorage.setItem("songOption", JSON.stringify(payload.query));
        yield put(getWorksSuccess({ data: res?.data, query: payload.query }));
        page = null;
        size = null;
      } catch {
        yield put(setWorksError(["not-found"]));
      }
    } catch {
      yield put(setWorksError(["not-found"]));
      yield put(worksLoading(false));
    } finally {
      yield put(worksLoading(false));
    }
  }
}

function* watchDealsRequest(): Generator {
  yield takeEvery(DealsTypes.DEALS_REQUEST, GetDealsAsync);
  yield takeEvery(DealsTypes.GET_WORKS, GetWorksAsync);
}

function* watchLedgersRequest(): Generator {
  yield takeEvery(DealsTypes.LEDGERS_REQUEST, GetLedgersAsync);
}

export { watchDealsRequest, watchLedgersRequest };
