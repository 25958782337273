import React from "react";
import ReactTooltip from "react-tooltip";
import cn from "classnames";
import moment from "moment/moment";

import styles from "./History.module.scss";

import Icon from "../../../../components/shared/Icon";
import { TableType } from "../../types";
import { downloadCSV } from "../../utils";
import FileNameColumnCell from "./components/FileNameColumnCell";

export const getColumnsTaxDoc = (
  tableType: TableType,
  fetchedSearchValue?: string | null
) => [
  // {
  //   accessor: "index",
  //   withoutSort: true,
  //   Header: "#",
  //   width: 50,
  //   Cell: ({ row }: any) => {
  //     return row.index + 1;
  //   }
  // },
  {
    accessor: "userTitle",
    Header: "Uploader"
  },
  {
    accessor: "publishedAt",
    Header: "Date Uploaded",
    Cell: ({ cell }: any) => {
      return moment.utc(cell.value).format("DD MMMM YYYY");
    }
  },
  // {
  //   width: 145,
  //   accessor: "payeeCode",
  //   Header: "Payee code"
  // },
  {
    width: 400,
    accessor: "name",
    Header: "Files",
    withoutSort: true,
    Cell: ({ row }: any) => (
      <FileNameColumnCell
        row={row}
        tableType={tableType}
        fetchedSearchValue={fetchedSearchValue}
      />
    )
  },
  {
    width: 50,
    accessor: "download",
    Header: "",
    withoutSort: true,
    Cell: ({ row }: any) => (
      <>
        <ReactTooltip
          id="download"
          data-id="download"
          border
          place="top"
          effect="solid"
          arrowColor="rgba(56, 56, 56, 1)"
        >
          Download Report
        </ReactTooltip>
        <div
          data-for="download"
          data-tip=""
          onClick={event => {
            event.stopPropagation();
            downloadCSV.handleClickDownload(row.original.id, tableType);
          }}
        >
          <Icon kind="file_dnld_icon" className={cn(styles.files_download)} />
        </div>
      </>
    )
  }
];

export const getColumnsDefault = (
  tableType: TableType,
  fetchedSearchValue?: string | null
) => [
  // {
  //   accessor: "index",
  //   withoutSort: true,
  //   Header: "#",
  //   width: 50,
  //   Cell: ({ row }: any) => {
  //     return row.index + 1;
  //   }
  // },
  {
    accessor: "userTitle",
    Header: "Uploader"
  },
  {
    accessor: "publishedAt",
    Header: "Date Uploaded",
    Cell: ({ cell }: any) => {
      return moment.utc(cell.value).format("DD MMMM YYYY");
    }
  },
  // {
  //   width: 145,
  //   accessor: "payeeCode",
  //   Header: "Payee code"
  // },
  {
    accessor: "startDate",
    Header: "Document Date",
    Cell: ({ row }: any) => {
      return `
      ${moment(row.original.startDate).format("MMMM")} - ${moment(
        row.original.endDate
      ).format("MMMM YYYY")}`;
    }
  },
  {
    width: 400,
    accessor: "name",
    Header: "Files",
    withoutSort: true,
    Cell: ({ row }: any) => (
      <FileNameColumnCell
        row={row}
        tableType={tableType}
        fetchedSearchValue={fetchedSearchValue}
      />
    )
  },
  {
    width: 50,
    accessor: "download",
    Header: "",
    withoutSort: true,
    Cell: ({ row }: any) => (
      <>
        <ReactTooltip
          id="download"
          data-id="download"
          border
          place="top"
          effect="solid"
          arrowColor="rgba(56, 56, 56, 1)"
        >
          Download Report
        </ReactTooltip>
        <div
          data-for="download"
          data-tip=""
          onClick={event => {
            event.stopPropagation();
            downloadCSV.handleClickDownload(row.original.id, tableType);
          }}
        >
          <Icon kind="file_dnld_icon" className={cn(styles.files_download)} />
        </div>
      </>
    )
  }
];
