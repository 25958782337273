import React, { FunctionComponent } from "react";
import ReactTooltip from "react-tooltip";
import cn from "classnames";

import styles from "./info-component.module.scss";
import { ReactComponent as InfoIcon } from "../../../icons/white_info_icon.svg";
import { ReactComponent as InfoIconHovered } from "../../../icons/white_info_icon_hovered.svg";
export interface InfoComponentProps {
  title: string;
  hasIcon: boolean;
  iconSize?: "small" | "big";
  titleSize?: "small" | "middle" | "big";
  information?: string;
  tooltipSize?: "small" | "big";
  blockClassName?: string;
}
const InfoComponent: FunctionComponent<InfoComponentProps> = props => {
  return (
    <div
      className={cn(
        styles.block,
        props.blockClassName ? props.blockClassName : "",
        "info-component-block"
      )}
    >
      <div className={cn(styles.head, "info-component-head")}>
        <span
          className={cn(
            styles.title,
            {
              [styles.title_small]: props.titleSize === "small",
              [styles.title_middle]: props.titleSize === "middle",
              [styles.title_big]: props.titleSize === "big"
            },
            "info-component-title"
          )}
        >
          {props.title}
        </span>
        {props.hasIcon && (
          <span
            className={cn(styles.iconBlock, {
              [styles.iconBlock_small]: props.iconSize === "small",
              [styles.iconBlock_big]: props.iconSize === "big"
            })}
            data-for={`info-component-tooltip_${props.title}`}
            data-tip=""
          >
            <InfoIcon className={cn(styles.icon)} />
            <InfoIconHovered className={cn(styles.icon, styles.iconHovered)} />
          </span>
        )}
      </div>
      <div className={cn(styles.value, "info-component-value")}>
        {props.children}
      </div>
      {props.hasIcon && (
        <ReactTooltip
          border
          id={`info-component-tooltip_${props.title}`}
          place="top"
          effect="solid"
          arrowColor="rgba(56, 56, 56, 1)"
          backgroundColor="rgba(56, 56, 56, 1)"
          padding="10px"
          textColor="white"
          className={cn(
            {
              [styles.tooltip_big]: props.tooltipSize === "big",
              [styles.tooltip_small]: props.tooltipSize === "small"
            },
            "info-component-tooltip"
          )}
        >
          {props.information && (
            <div dangerouslySetInnerHTML={{ __html: props.information }} />
          )}
        </ReactTooltip>
      )}
    </div>
  );
};
export default InfoComponent;
