import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import cn from "classnames";

import styles from "./Profile.module.scss";

import LanguageModal from "../../components/shared/Modals/SetLanguageModal";
import SetPasswordModal from "../../components/shared/Modals/SetPasswordModal";
import UiSelect from "../../components/shared/UiSelect";
import { useActions } from "../../hooks/useActions";
import {
  languageSelector,
  profileSelector
} from "../../store/appStatic/selectors";

const Select = () => {
  const { SetOpenModal } = useActions();
  const languages = useSelector(languageSelector);
  const [dropdownValue, setDropdownValue] = useState<any>(null);
  const profile = useSelector(profileSelector);
  const defaultLanguage =
    localStorage.getItem("impersonateLn") ||
    profile?.settings?.preferableLanguage;

  useEffect(() => {
    if (languages?.length) {
      setDropdownValue(
        languages?.find((el: any) => el.value === defaultLanguage)
      );
    }
  }, [languages, defaultLanguage]);
  const openModal = (el: any) => {
    SetOpenModal({
      isOpen: true,
      type: "langModal",
      data: el
    });
  };
  return (
    <div>
      <UiSelect
        value={{
          label: dropdownValue?.label
            ? dropdownValue?.label
            : languages[0]?.label,
          value: dropdownValue?.value
            ? dropdownValue?.value
            : languages[0]?.value
        }}
        onChange={(el: any) => {
          openModal(el);
        }}
        // menuIsOpen
        options={languages}
        className={cn(styles.select)}
        hasGlob
        menuClassName={styles.menu}
      />
      <LanguageModal />
      <SetPasswordModal />
    </div>
  );
};
export default Select;
