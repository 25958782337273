import React from "react";
import ReactTooltip from "react-tooltip";
import cn from "classnames";

import styles from "../../History.module.scss";

import { fileUtils } from "../../../../utils";

const FileNameColumnCell = ({ row, tableType, fetchedSearchValue }: any) => {
  const documents = row?.original?.documents;
  return (
    <div className={styles.files}>
      {/* There will be the files list , for example // row.original.files */}
      {documents &&
        documents.slice(0, 3).map((el: { fileName: string; id: string }) => {
          const payeeCodeMatchesSearchValue =
            fetchedSearchValue &&
            el.fileName
              .toLowerCase()
              .includes(fetchedSearchValue.toLowerCase());
          return (
            <div key={el.fileName}>
              <ReactTooltip
                id={el.fileName}
                data-id={el.fileName}
                border
                place="top"
                effect="solid"
                arrowColor="rgba(56, 56, 56, 1)"
              >
                Download - {el.fileName}
              </ReactTooltip>
              <a
                data-for={el.fileName}
                data-tip=""
                className={cn(styles.file, {
                  [styles.payeeCodeMatch]: payeeCodeMatchesSearchValue
                })}
                onClick={event => {
                  event.stopPropagation();
                  const { id, fileName } = el;
                  fileUtils.getSignedURL({
                    documentId: id,
                    tableType,
                    name: fileName
                  });
                }}
              >
                {el.fileName}
              </a>
            </div>
          );
        })}
      {documents.length > 3 ? (
        <>
          <ReactTooltip
            id="downloads"
            data-id="downloads"
            border
            place="top"
            effect="solid"
            arrowColor="rgba(56, 56, 56, 1)"
          >
            View all files
          </ReactTooltip>
          <span
            data-for="downloads"
            data-tip=""
            className={cn(styles.files_count)}
          >
            + {documents.length - 3}
          </span>
        </>
      ) : null}
    </div>
  );
};

export default FileNameColumnCell;
