import i18next from "i18next";

import { SelectOption } from "../../types/select";

export const getYearOptions = (
  t: typeof i18next.t,
  availableYears: number[]
): SelectOption[] => {
  const initial = [
    { value: "", label: t("statementsPage.documents.filters.allYears") }
  ];
  if (!availableYears.length) return initial;

  return [
    ...initial,
    ...availableYears.map(year => {
      return {
        value: String(year),
        label: String(year)
      };
    })
  ];
};

export const getTypeOptions = (
  t: typeof i18next.t,
  availableTypes: string[]
): SelectOption[] => {
  const options = [
    {
      value: "ROYALTY_STATEMENT",
      label: t("statementsPage.documents.documentType.ROYALTY_STATEMENT")
    },
    {
      value: "REMITTANCE_ADVICE",
      label: t("statementsPage.documents.documentType.REMITTANCE_ADVICE")
    },
    {
      value: "SELF_BILLING_INVOICE",
      label: t("statementsPage.documents.documentType.SELF_BILLING_INVOICE")
    },
    {
      value: "STATEMENT",
      label: t("statementsPage.documents.documentType.STATEMENT")
    },
    {
      value: "TAX",
      label: t("statementsPage.documents.documentType.TAX")
    }
  ];
  const filteredOptions = options.filter(option =>
    availableTypes.includes(option.value)
  );
  return [
    { value: "", label: t("statementsPage.documents.filters.allDocuments") },
    ...filteredOptions
  ];
};

export const downloadRadioOptions = {
  pdf: "pdf",
  csv: "csv",
  all: "pdf,csv"
};

export const ROYALTY_TYPE = "ROYALTY_STATEMENT";
