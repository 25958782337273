import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import cn from "classnames";

import styles from "./TopSongsChart.module.scss";
import { ReactComponent as DownIcon } from "../../../icons/down_vector.svg";
import { ReactComponent as UpIcon } from "../../../icons/up.svg";

import { colors } from "../../../configs/constants/transaction";
import {
  songsLoadingSelector,
  top4SongsSelector
} from "../../../store/analytics/selectors";
import CurrencyRateShort from "../CurrencyRateShort";

const SongsChart = () => {
  const md = useMediaQuery({ maxWidth: 500 });
  const history = useHistory();
  const top4Songs = useSelector(top4SongsSelector);
  const songsLoading = useSelector(songsLoadingSelector);
  const [anime, setAnime] = useState([]);

  const full = top4Songs ? top4Songs[0]?.amount : 0;

  useEffect(() => {
    if (!songsLoading && top4Songs) {
      const x: any = [];
      setTimeout(() => {
        top4Songs.forEach((el: any) => {
          x.push(el.amount);
        });
        setAnime(x);
      }, 100);
    }
  }, [top4Songs, songsLoading]);

  return (
    <div>
      {top4Songs?.map((el: any, i: any) => {
        return (
          <div key={i} className={styles.lineWrap}>
            <div className={styles.item}>
              <ReactTooltip
                border
                id={`shortTooltip_${el?.workTitle}${el?.creators}`}
                place="top"
                effect="solid"
                arrowColor="rgba(56, 56, 56, 1)"
                className={cn(styles.tooltipText, "payeeCodeToolTip", {
                  [styles.hide]: md
                })}
              >
                {!md && (
                  <>
                    <p>{el?.workTitle}</p>
                    <p className={styles.desc}>{el?.creators}</p>
                  </>
                )}
              </ReactTooltip>
              <div
                data-for={`shortTooltip_${el?.workTitle}${el?.creators}`}
                data-tip=""
                className={cn(styles.info, {
                  [styles.innerPageInfo]: history.location.pathname.includes(
                    "topSongs"
                  )
                })}
              >
                <p>{el?.workTitle}</p>
                <p className={styles.desc}>{el?.creators}</p>
              </div>
              <div
                style={{
                  width: `${(el?.amount * 100) / full}%`,
                  backgroundColor: colors[i]
                }}
                className={cn(styles.line, { [styles.active]: anime[i] })}
              />
            </div>
            <div className={styles.wrapCount}>
              <p className={styles.count}>
                <CurrencyRateShort id={`topSongs_${i}`} rate={el?.amount} />
              </p>
              {!!el?.percentage && (
                <p
                  className={cn(styles.percent, {
                    [styles.down]: el?.direction !== "INCREMENT"
                  })}
                >
                  {`${el?.percentage}%`}
                  {el?.direction === "INCREMENT" ? (
                    <UpIcon className={styles.upIcon} />
                  ) : (
                    <DownIcon className={styles.downIcon} />
                  )}
                </p>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default SongsChart;
