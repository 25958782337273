import React, { useCallback, useState } from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import axios, { AxiosResponse } from "axios";
import cn from "classnames";

import styles from "./FailedFileValidationPopup.module.scss";
import { ReactComponent as LoadingIcon } from "../../../../icons/loading.svg";

import { useActions } from "../../../../hooks/useActions";
import { oktaAuth } from "../../../../lib/oktaAuth";
import {
  DocumentType,
  UploadedFile
} from "../../../../page/StatementUpload/types";
import { downloadFile } from "../../../../page/StatementUpload/utils/fileUtils";
import { modalSelector } from "../../../../store/architectUsersStore/selectors";
import Icon from "../../../shared/Icon";
import Button from "../../WCMButton";
import FailedDocumentsOverviewDetailed from "./FailedDocumentsOverviewDetailed";

type FailedFileValidationPopupProps = {
  switchToReview: () => void;
  batchId: string;
  documentType: DocumentType;
};
type QueryParams = {
  failedFileList: UploadedFile[];
  callBack?: () => Promise<void>;
};
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URI,
  headers: {
    accept: "*/*"
  }
});

axiosInstance.interceptors.request.use(config => {
  config.headers.common[
    "Authorization"
  ] = `Bearer ${oktaAuth.getAccessToken()}`;
  config.headers.common["accept"] = "application/json";
  config.headers.common["Accept-Encoding"] = "*";
  return config;
});

const FailedFileValidationPopup: React.FC<FailedFileValidationPopupProps> = ({
  batchId,
  documentType
}) => {
  const modal = useSelector(modalSelector);
  const { SetOpenModalAction } = useActions();

  const [loadingDisregard, setLoadingDisregard] = useState(false);
  const [loadingLogErrors, setLoadingLogErrors] = useState(false);

  const closeModal = useCallback(() => {
    const type = [...modal.type].slice(0, -1);
    SetOpenModalAction({ ...modal, type });
    // handleClearState();
  }, [SetOpenModalAction, modal]);

  const { failedFileList = [], callBack = () => null } = {
    ...modal.queryParams
  } as QueryParams;

  const downloadCSV = async (res: AxiosResponse<any>) => {
    await downloadFile(
      res.data.fileName,
      "data:text/csv;charset=UTF-8," + encodeURIComponent(res.data.csvContent)
    );
  };

  const disregardErrors = async () => {
    return await axiosInstance.put(
      `${process.env.REACT_APP_BASE_URI}api/cms/${documentType}/batches/${batchId}`,
      { action: "REJECT" }
    );
  };

  const fetchSCV = async () => {
    return await axiosInstance.post(
      `${process.env.REACT_APP_BASE_URI}api/cms/${documentType}/export/CSV`,
      {
        skip: 0,
        take: 1000,
        filter: {
          and: [
            {
              name: "batchId",
              operation: "eq",
              value: batchId
            },
            {
              name: "fileStatus",
              operation: "eq",
              value: "REJECTED"
            }
          ]
        }
      }
    );
  };

  const handleDisregard = async () => {
    setLoadingDisregard(true);
    try {
      await disregardErrors();
    } catch (err) {
      console.log(err);
    } finally {
      await callBack();
      setLoadingDisregard(false);
      closeModal();
    }
  };

  const handleLogErrors = async () => {
    setLoadingLogErrors(true);
    try {
      await disregardErrors();
      const csv = await fetchSCV();
      await downloadCSV(csv);
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingLogErrors(false);
      closeModal();
      await callBack();
    }
  };

  const documentWord = failedFileList?.length === 1 ? "document" : "documents";

  return (
    <Modal
      ariaHideApp={false}
      isOpen={modal.isOpen && modal.type?.includes("FailedFileValidation")}
      onRequestClose={closeModal}
      className={styles.modal}
      overlayClassName={styles.overlay}
    >
      <div className={styles.contentWrapper}>
        <div className={styles.title}>
          <h3 className={cn(styles.head)}>UPLOAD REPORT</h3>
          <div className={cn(styles.head_info)}>
            <Icon kind="warning" className={cn(styles.icon)} />
            <span>{`${failedFileList?.length} ${documentWord} failed to upload`}</span>
          </div>
        </div>
        <div className={styles.list}>
          <FailedDocumentsOverviewDetailed failedDocuments={failedFileList} />
        </div>
        <p className={cn(styles.instructionsTitle)}>
          <strong>To proceed to the next step, you have two options:</strong>
        </p>
        <ul className={cn(styles.instructionsList)}>
          <li>
            <span>1.</span>
            <strong>Log errors, remove and continue.</strong> An error report
            will be downloaded to your computer.
          </li>
          <li>
            <span>2.</span>
            <strong>Disregard errors and continue.</strong> Your error log will
            be lost.
          </li>
        </ul>
        <div className={styles.buttonsContainer}>
          <Button
            variant="text"
            onClick={handleDisregard}
            disabled={loadingDisregard || loadingLogErrors}
          >
            <strong>Disregard Errors & Continue</strong>
            {loadingDisregard ? <LoadingIcon /> : null}
          </Button>

          <Button
            onClick={handleLogErrors}
            disabled={loadingDisregard || loadingLogErrors}
          >
            Log Errors, Remove & Continue
            {loadingLogErrors ? <LoadingIcon /> : null}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default FailedFileValidationPopup;
