import React from "react";
import { useTranslation } from "react-i18next";
import { Route, Switch, useHistory } from "react-router-dom";

import styles from "./Settings.module.scss";

import Button from "../../components/userManagement/WCMButton";
import NotificationSettings from "./NotificationSettings";

const Settings = () => {
  const { t } = useTranslation("translation");
  const history = useHistory();

  return (
    <div className="container">
      <nav className={styles.navigation}>
        <Button
          size="large"
          preIcon={{ kind: "back_arrow", iconColor: "#CBCBCB" }}
          variant="text"
          onClick={() => history.goBack()}
        >
          <span className={styles.backButtonText}>{t("buttons.back")}</span>
        </Button>
      </nav>
      <Switch>
        <Route
          path={["/settings/notification"]}
          component={NotificationSettings}
          exact
        />
      </Switch>
    </div>
  );
};

export default Settings;
