import React from "react";
import { useSelector } from "react-redux";
import cn from "classnames";

import styles from "./Tabs.module.scss";

import { MetaDataObject } from "../../../page/UserManagement/helper";
import {
  activityGrid,
  archiveGrid,
  internalUsersGrid,
  songWriterGrid
} from "../../../page/UserManagement/TableConfigs";
import { UMSTypeSearchEnum } from "../../../page/UserManagement/types";
import { profileSelector } from "../../../store/appStatic/selectors";
import { UMSTypeSearchSelector } from "../../../store/architectUsersStore/selectors";
import Condition from "../../shared/Condition";
import { CountInterface } from "./Count";
import TabItem from "./TabItem";

const getQuickFilterName = (name: string) => {
  switch (name) {
    case MetaDataObject.activeCount:
      return "Active";
    case MetaDataObject.inactiveCount:
      return "Inactive";
    case MetaDataObject.registeredCount:
      return "Registered";
    case MetaDataObject.invitedCount:
      return "Invited";
    case MetaDataObject.revokedCount:
      return "Revoked";
    case MetaDataObject.suspendedCount:
      return "Suspended";
    case "":
      return "all";
  }
};
const superAdminTabList = [
  {
    label: "Activity",
    value: "activity",
    status: getQuickFilterName(
      activityGrid().superAdminQuickFilters[0]
    )?.toLowerCase()
  },
  {
    label: "Clients",
    value: "clients",
    status: getQuickFilterName(
      songWriterGrid().superAdminQuickFilters[0]
    )?.toLowerCase()
  },
  {
    label: "Internal Users",
    value: "internal-users",
    status: getQuickFilterName(
      internalUsersGrid().superAdminQuickFilters[0]
    )?.toLowerCase()
  },
  {
    label: "Archive",
    value: "archive",
    status: getQuickFilterName(
      archiveGrid().superAdminQuickFilters[0]
    )?.toLowerCase()
  }
];
const adminTabList = [
  {
    label: "Activity",
    value: "activity",
    status: getQuickFilterName(
      activityGrid().adminQuickFilters[0]
    )?.toLowerCase()
  },
  {
    label: "Clients",
    value: "clients",
    status: getQuickFilterName(
      songWriterGrid().superAdminQuickFilters[0]
    )?.toLowerCase()
  },
  {
    label: "Archive",
    value: "archive",
    status: getQuickFilterName(
      archiveGrid().adminQuickFilters[0]
    )?.toLowerCase()
  }
];

function Tabs({ metaData, tab, children }: CountInterface) {
  const UMSTypeSearch = useSelector(UMSTypeSearchSelector);
  const profile = useSelector(profileSelector);
  return (
    <div className={cn(styles.items)}>
      <Condition
        condition={UMSTypeSearch?.value === UMSTypeSearchEnum.USER_NAME_EMAIL}
        Truthy={
          <ul className="d-flex">
            {profile?.isSuperAdmin &&
              superAdminTabList.map((el, index) => {
                return (
                  <TabItem
                    key={index}
                    active={tab === el.value}
                    metaData={metaData}
                    value={el.value}
                    label={el.label}
                    status={el.status}
                  />
                );
              })}
            {!profile?.isSuperAdmin &&
              adminTabList.map((el, index) => {
                return (
                  <TabItem
                    active={tab === el.value}
                    key={index}
                    metaData={metaData}
                    value={el.value}
                    label={el.label}
                    status={el.status}
                  />
                );
              })}
          </ul>
        }
      />
      {children && children}
    </div>
  );
}

export default Tabs;
