import { RouteComponentProps } from "react-router-dom";

import {
  AppStaticTypes,
  IChangeLanguageAction,
  IChangeLanguageSuccessAction,
  IClearClientState,
  IGetLanguageAction,
  IGetLanguageSuccessAction,
  IResetProfileAction,
  ISetErrorAction,
  ISetLoadingAction,
  ISetPayeeCodeAction,
  ISetProfileAction,
  ISetProfileSuccessAction,
  ISetSingleClient,
  ISingleClientState,
  profileSuccessType
} from "./types";

const setLoadingAction = (isLoading: boolean): ISetLoadingAction => ({
  type: AppStaticTypes.APP_STATIC_LOADING,
  payload: isLoading
});
const setCountryCode = (code: string) => {
  localStorage.setItem("countryCode", JSON.stringify(code));
  return {
    type: AppStaticTypes.SET_COUNTRY_CODE,
    payload: code
  };
};
const setErrorAction = (errors: string[]): ISetErrorAction => ({
  type: AppStaticTypes.APP_STATIC_ERROR,
  payload: errors
});

const setProfileAction = (
  history: RouteComponentProps["history"],
  id?: string
): ISetProfileAction => ({
  type: AppStaticTypes.PROFILE_ACTION,
  payload: { history, id }
});

const setProfileSuccessAction = ({
  data,
  clients,
  emailType,
  preferableLanguage,
  email,
  profile
}: profileSuccessType): ISetProfileSuccessAction => ({
  type: AppStaticTypes.PROFILE_ACTION_SUCCESS,
  payload: { data, clients, emailType, preferableLanguage, email, profile }
});

const resetProfileAction = (): IResetProfileAction => ({
  type: AppStaticTypes.RESET_PROFILE_STATE
});

const setSingleClient = ({
  data
}: {
  data: ISingleClientState;
}): ISetSingleClient => ({
  type: AppStaticTypes.SET_SINGLE_CLIENT,
  payload: { data }
});

const clearClientState = (): IClearClientState => ({
  type: AppStaticTypes.CLEAR_CLIENT
});

const getLanguageAction = (id: string): IGetLanguageAction => ({
  type: AppStaticTypes.GET_LANGUAGE_ACTION,
  payload: id
});

const getLanguageSuccessAction = (ln: string): IGetLanguageSuccessAction => ({
  type: AppStaticTypes.GET_LANGUAGE_SUCCESS_ACTION,
  payload: ln
});

const changeLanguageAction = (ln: {
  value: string;
  label: string;
}): IChangeLanguageAction => ({
  type: AppStaticTypes.CHANGE_LANGUAGE_ACTION,
  payload: ln
});

const changeLanguageSuccessAction = (data: {
  preferableLanguage: string;
}): IChangeLanguageSuccessAction => ({
  type: AppStaticTypes.CHANGE_LANGUAGE_SUCCESS_ACTION,
  payload: { data }
});

const changeIsTermAcceptedAction = (data: { isTermAccepted: boolean }) => ({
  type: AppStaticTypes.CHANGE_IS_TERM_ACCEPTED_ACTION,
  payload: { data }
});

const changeNotificationSettingsAction = (data: {
  emailNotification: boolean;
}) => ({
  type: AppStaticTypes.CHANGE_EMAIL_NOTIFICATION_SETTING_ACTION,
  payload: { data }
});

const setPayeeCodeAction = (payeeCode: string): ISetPayeeCodeAction => {
  localStorage.setItem("payeeCode", payeeCode);
  return {
    type: AppStaticTypes.SET_PAYEE_CODE,
    payload: payeeCode
  };
};
const setPreslectedPayeeCodAction = (payeeCode: string) => ({
  type: AppStaticTypes.SET_PRESELECTED_PAYEE_CODE,
  payload: payeeCode
});

const clearPreselectedPayeCodeAction = () => ({
  type: AppStaticTypes.SET_PRESELECTED_PAYEE_CODE,
  payload: ""
});

export {
  changeIsTermAcceptedAction,
  changeLanguageAction,
  changeLanguageSuccessAction,
  changeNotificationSettingsAction,
  clearClientState,
  clearPreselectedPayeCodeAction,
  getLanguageAction,
  getLanguageSuccessAction,
  resetProfileAction,
  setCountryCode,
  setErrorAction,
  setLoadingAction,
  setPayeeCodeAction,
  setPreslectedPayeeCodAction,
  setProfileAction,
  setProfileSuccessAction,
  setSingleClient
};
