import React, { useEffect, useMemo, useState } from "react";
import { Option } from "react-dropdown";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { OnChangeFn, RowSelectionState } from "@tanstack/table-core";

import styles from "./Documents.module.scss";

import Condition from "../../components/shared/Condition";
import Loader from "../../components/shared/Loading";
import PaginationNumbers from "../../components/shared/Pagination/Pagination";
import UiButton from "../../components/shared/UiButton";
import UiSelect from "../../components/shared/UiSelect";
import {
  payeeCodeSelector,
  profileSelector
} from "../../store/appStatic/selectors";
import { SelectOption } from "../../types/select";
import {
  DocumentCards,
  DocumentTable,
  DownloadTypeSelector
} from "./components";
import {
  downloadRadioOptions,
  getTypeOptions,
  getYearOptions,
  ROYALTY_TYPE
} from "./constants";
import { useDownloadFITDocument } from "./hooks/useDownload";
import useFitDocuments from "./hooks/useFitDocuments";
import { updateSelectedOption } from "./utils";

export const Documents = () => {
  const payeeCode = useSelector(payeeCodeSelector);
  const profile = useSelector(profileSelector);

  const { t, i18n } = useTranslation("translation");

  const mobile = useMediaQuery({ maxWidth: 750 });
  const {
    result: documents,
    loading,
    refetch,
    availableYears,
    availableTypes,
    incrementDownloadCount
  } = useFitDocuments(payeeCode);
  const {
    handleDownloadDocument,
    handleDownloadStatementDocument
  } = useDownloadFITDocument(payeeCode);

  const typeOptions = getTypeOptions(t, availableTypes);
  const yearOptions = getYearOptions(t, availableYears);

  const [pageSize, setPageSize] = useState<number>(mobile ? 10 : 20);
  const [downloadType, setDownloadType] = useState<string>(
    downloadRadioOptions.all
  );
  const [page, setPage] = useState<number>(0);
  const [selectedYear, setSelectedYear] = useState<SelectOption>(
    yearOptions[0]
  );
  const [selectedType, setSelectedType] = useState<SelectOption>(
    typeOptions[0]
  );

  const [selectedDocuments, setSelectedDocuments] = useState<{
    [key: string]: true;
  }>({});

  const { royaltyIsSelected, selectedDocumentsCount } = useMemo(() => {
    const keys = Object.keys(selectedDocuments);
    const royaltyIsSelected = keys.some(key => key.endsWith(ROYALTY_TYPE));
    return {
      selectedDocumentsCount: keys.length,
      royaltyIsSelected
    };
  }, [selectedDocuments]);

  const handleDownload = () => {
    const impersonateEmail = localStorage.getItem("impersonateEmail");
    for (const key in selectedDocuments) {
      const document = documents?.find(doc => doc.id === key);
      if (!document) continue;
      const isRoyalty = document?.documentType === ROYALTY_TYPE;
      if (isRoyalty) {
        handleDownloadStatementDocument(document, downloadType.split(","));
      } else {
        handleDownloadDocument(document);
      }
      // Optimistically increase the number of downloads only if not impersonating another user
      if (!impersonateEmail || impersonateEmail === profile.email) {
        incrementDownloadCount(document);
      }
    }
    setSelectedDocuments({});
  };

  const totalAmount = Math.ceil(Number(documents?.length) / pageSize);

  const onChangeSize = (option: Option): void => {
    setPageSize(Number(option.value));
    setPage(0);
  };

  useEffect(() => {
    void refetch({
      year: selectedYear.value,
      type: selectedType.value
    });
  }, [selectedYear, selectedType]);

  useEffect(() => {
    setSelectedDocuments({});
    setPage(0);
    setSelectedYear(yearOptions[0]);
    setSelectedType(typeOptions[0]);
  }, [payeeCode]);

  useEffect(() => {
    setSelectedType(updateSelectedOption(typeOptions));
    setSelectedYear(updateSelectedOption(yearOptions));
  }, [i18n.language, updateSelectedOption]);

  return (
    <Condition
      condition={!payeeCode || (!documents && loading)}
      Truthy={<Loader />}
      Falsy={
        <div className="container">
          <div className={styles.filterContainer}>
            <section>
              <UiSelect
                name="yearSelector"
                value={selectedYear}
                onChange={option => {
                  setSelectedYear((option as unknown) as SelectOption);
                }}
                options={yearOptions}
              />
            </section>
            <section>
              <UiSelect
                name="typeSelector"
                value={selectedType}
                onChange={option =>
                  setSelectedType((option as unknown) as SelectOption)
                }
                options={typeOptions}
              />
            </section>
            {!mobile && (
              <section>
                <DownloadTypeSelector
                  isMobile={false}
                  isVisible={royaltyIsSelected}
                  setDownloadType={setDownloadType}
                  downloadType={downloadType}
                />
                <UiButton
                  className={styles.downloadButton}
                  text={`${t("buttons.download")} ${
                    selectedDocumentsCount ? `(${selectedDocumentsCount})` : ""
                  }`}
                  disabled={!selectedDocumentsCount}
                  onClick={handleDownload}
                />
              </section>
            )}
          </div>
          <Condition
            condition={mobile}
            Truthy={
              <DocumentCards
                royaltyIsSelected={royaltyIsSelected}
                setDownloadType={setDownloadType}
                downloadType={downloadType}
                page={page}
                pageSize={pageSize}
                data={documents || []}
                selectedDocuments={selectedDocuments}
                setSelectedDocuments={setSelectedDocuments}
                handleDownload={handleDownload}
              />
            }
            Falsy={
              <DocumentTable
                page={page}
                pageSize={pageSize}
                loading={loading}
                data={documents || []}
                selected={selectedDocuments}
                setSelected={
                  setSelectedDocuments as OnChangeFn<RowSelectionState>
                }
              />
            }
          />
          <PaginationNumbers
            alwaysVisible
            isWhite
            changePageRequest={(index: number) => {
              setPage(index);
            }}
            onChangeSize={onChangeSize}
            totalAmount={totalAmount}
            size={pageSize}
            currentPage={page + 1}
          />
        </div>
      }
    />
  );
};

export default Documents;
