import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import cn from "classnames";

import styles from "./LanguageModal.module.scss";
import { ReactComponent as CloseIcon } from "../../../../icons/close.svg";
import { ReactComponent as Glob } from "../../../../icons/white_glogus.svg";

import { useActions } from "../../../../hooks/useActions";
import {
  languageSelector,
  loadingSelector
} from "../../../../store/appStatic/selectors";
import { dealsModalSelector } from "../../../../store/dealsStore/selectors";
import { textParams } from "../../../../utils";
import Button from "../../UiButton";
import { ButtonType } from "../../UiButton/Button";

const LanguageModal = () => {
  const { i18n } = useTranslation("translation");
  const { t } = useTranslation("translation");
  const modal = useSelector(dealsModalSelector);
  const { data } = modal;
  const languages = useSelector(languageSelector);
  const loading = useSelector(loadingSelector);
  const { SetOpenModal, changeLanguageAction } = useActions();
  const lang =
    data && languages?.find((el: any) => el.value === data.value)?.label;
  const toggleModal = useCallback(() => {
    SetOpenModal({ isOpen: false, type: "", data: data });
  }, [SetOpenModal]);
  const ChangeLang = (data: any) => {
    changeLanguageAction(
      data || { value: "EN", label: t(`language.currentLanguage`) }
    );
    i18n.changeLanguage(data?.value || "EN");
  };
  const changedLang: any = languages &&
    data &&
    languages?.find((el: any) => el.value === data.value) && {
      label: languages?.find((el: any) => el.value === data.value).label,
      value: languages?.find((el: any) => el.value === data.value).value
    };

  return (
    <div className={styles.SetUserControlModal}>
      <Modal
        ariaHideApp={false}
        isOpen={modal.isOpen && modal.type === "langModal"}
        onRequestClose={toggleModal}
        className={cn(styles.mymodal)}
        overlayClassName={styles.myoverlay}
      >
        <>
          <CloseIcon className={styles.closeIcon} onClick={toggleModal} />
          <div className={styles.buttonWrap} />
        </>
        <h2 className={cn(styles.title)}>{t(`profilePage.changeLanguage`)}</h2>
        <div className={cn(styles.description)}>
          {textParams(t(`profilePage.changeLanguageText`))[0]}{" "}
          <strong>{lang}</strong>
          {textParams(t(`profilePage.changeLanguageText`))[2]}{" "}
        </div>
        <div className={cn(styles.buttons)}>
          <Button
            variant={ButtonType.Text}
            className={cn(styles.btn)}
            onClick={toggleModal}
          >
            {t(`buttons.cancel`)}
          </Button>
          <Button
            variant={ButtonType.Primary}
            className={cn(styles.btn, styles.btn_primary)}
            onClick={() => ChangeLang(data)}
            loading={loading}
            disabled={loading}
          >
            {t(`buttons.save`)}
          </Button>
        </div>
      </Modal>
      <Modal
        ariaHideApp={false}
        isOpen={modal.isOpen && modal.type === "successLang"}
        onRequestClose={toggleModal}
        className={cn(styles.noOverLayModal)}
        overlayClassName={styles.nooverlay}
      >
        <p className={cn(styles.success)}>
          <Glob />
          <span>
            {textParams(t(`profilePage.changeLanguageSuccess`))[0]}{" "}
            {changedLang && changedLang.label}
          </span>
        </p>
      </Modal>
    </div>
  );
};
export default LanguageModal;
