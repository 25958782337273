import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import cn from "classnames";
import moment from "moment";

import styles from "./RoyaltyPeroid.module.scss";
import { ReactComponent as ArrowDown } from "../../../../icons/arrow.svg";
import { ReactComponent as CalendarIcon } from "../../../../icons/calendar_vector.svg";

import {
  dealsModalSelector,
  ledgerSelector
} from "../../../../store/dealsStore/selectors";
export interface RoyaltyPeriodProps {
  onClick?: () => void;
  royaltyPeriodRef?: any;
}
const RoyaltyPeriod = (props: RoyaltyPeriodProps) => {
  const { t, i18n } = useTranslation("translation");
  const ledger = useSelector(ledgerSelector);
  const { ledgers } = useSelector((state: any) => state.dealsReducer);
  const dealsModal = useSelector(dealsModalSelector);
  const RoyaltyDateFormatting = (startDate: string, endDate: string) => {
    if (startDate && endDate) {
      return (
        moment
          .utc(new Date(startDate))
          .locale(i18n.language)
          .format("MMM YYYY") +
        " - " +
        moment
          .utc(new Date(endDate))
          .locale(i18n.language)
          .format("MMM YYYY")
      );
    } else {
      const start = moment()
        .quarter(
          moment(new Date())
            .utc()
            .quarter()
        )
        .startOf("quarter");

      const end = moment()
        .quarter(
          moment(new Date())
            .utc()
            .quarter()
        )
        .endOf("quarter");
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const quarterStartDate = moment(new Date(start["_d"]))
        .locale(i18n.language)
        .format("MMM YYYY");
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const quarterEndDate = moment(new Date(end["_d"]))
        .locale(i18n.language)
        .format("MMM YYYY");
      return quarterStartDate + " - " + quarterEndDate;
    }
  };
  return (
    <div
      className={cn(styles.block)}
      onClick={() => props.onClick?.()}
      ref={props.royaltyPeriodRef}
    >
      {ledgers?.balances?.length && (
        <CalendarIcon className={cn(styles.calendarIcon)} />
      )}
      <div
        className={cn(
          styles.text,
          ledgers?.balances?.length && styles.text_underline
        )}
      >
        <span>{t(`ledger.royaltyPeriod`) + ": "}</span>
        <br className={cn(styles.break)} />
        <span className={cn(styles.date)}>
          {RoyaltyDateFormatting(
            ledger?.data?.startDate,
            ledger?.data?.endDate
          )}
        </span>
      </div>
      {ledgers?.balances?.length && (
        <ArrowDown
          className={cn(
            styles.arrowIcon,
            dealsModal.type === "royaltyPeriod" && dealsModal.isOpen
              ? styles.arrowIcon_rotate
              : ""
          )}
        />
      )}
    </div>
  );
};
export default RoyaltyPeriod;
