import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import axios from "axios";

import settingsStyles from "../Settings.module.scss";
import styles from "./NotificationSettings.module.scss";

import { useActions } from "../../../hooks/useActions";
import { oktaAuth } from "../../../lib/oktaAuth";
import { singleClientSelector } from "../../../store/appStatic/selectors";
import Toggle from "../components/Toggle";

const { REACT_APP_BASE_URI } = process.env;

const NotificationSettings = () => {
  const { data } = useSelector(singleClientSelector);
  const clientProfile = data?.profile;
  const { t } = useTranslation("translation");
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    if (clientProfile) {
      setIsLoading(true);
      axios
        .get(`${REACT_APP_BASE_URI}api/users/${clientProfile.id}/settings`, {
          headers: {
            Authorization: `Bearer ${oktaAuth.getAccessToken()}`
          }
        })
        .then(({ data: settings }) => {
          setIsChecked(settings.emailNotification);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [clientProfile?.id]);

  const { changeNotificationSettingsAction } = useActions();

  const [isLoading, setIsLoading] = useState(true);

  const handleToggleNotifications = useCallback(async () => {
    setIsLoading(true);
    await axios
      .put(
        `${REACT_APP_BASE_URI}api/users/${clientProfile.id}/settings`,
        { emailNotification: !isChecked },
        {
          headers: {
            Authorization: `Bearer ${oktaAuth.getAccessToken()}`
          }
        }
      )
      .then(({ data: settings }) => {
        setIsChecked(settings.emailNotification);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [clientProfile?.id, changeNotificationSettingsAction, isLoading]);

  return (
    <div>
      <h1 className={settingsStyles.title}>
        {t("settings.notificationSettings.title")}
      </h1>
      <div className={styles.card}>
        <h1 className={styles.description}>
          {t("settings.notificationSettings.description")}
        </h1>
        <Toggle
          label={`${t(
            isChecked
              ? "settings.notificationSettings.enabled"
              : "settings.notificationSettings.disabled"
          )}`}
          isChecked={isChecked}
          onChange={handleToggleNotifications}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default NotificationSettings;
