import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import ReactTooltip from "react-tooltip";

import styles from "./Upload.module.scss";

import Condition from "../../../../components/shared/Condition";
import { CancelUploadPopup } from "../../../../components/userManagement/Modals/CancelUploadPopup";
import DeleteSelectedPopup from "../../../../components/userManagement/Modals/DeleteSelectedPopup";
import DeleteSuccessPopup from "../../../../components/userManagement/Modals/DeleteSuccessPopup";
import DuplicateFileValidationPopup from "../../../../components/userManagement/Modals/DuplicateFileValidationPopup/DuplicateFileValidationPopup";
import ExitWariningPopup from "../../../../components/userManagement/Modals/ExitWarningPopup/ExitWariningPopup";
import { FailedFileValidationPopup } from "../../../../components/userManagement/Modals/FailedFileValidationPopup";
import PublishPopup from "../../../../components/userManagement/Modals/PublishPopup";
import { SaveProgressPopup } from "../../../../components/userManagement/Modals/SaveProgressPopup";
import UploadFileZone from "../../../../components/userManagement/UploadFileZon";
import Table from "../../../../components/userManagement/UploadTable";
import Button from "../../../../components/userManagement/WCMButton";
import { useActions } from "../../../../hooks/useActions";
import {
  convertDocumentPeriodToString,
  convertToShortDate
} from "../../../../utils";
import { options } from "../../constants";
import useFileStatuses from "../../hooks/useFileStatuses";
import useSignedUrls from "../../hooks/useSignedUrls";
import useStoreFiles from "../../hooks/useStoreFiles";
import useUploadFile from "../../hooks/useUploadFile";
import {
  Document,
  DocumentList,
  FlowFinalState,
  PublishableDocument,
  UploadedFile
} from "../../types";
import { dateUtils, fileUtils } from "../../utils";
import SelectFlow from "../SelectFlow";
import {
  FieldTypes,
  StatementFrequencyOption,
  UploadTypeOption
} from "../SelectFlow/types";
import UploadDescription from "../UploadDescription";
import UploadList from "../UploadList";
import UploadProgress from "../UploadProgress";

const Upload = () => {
  const selectedCount = useSelector(
    (state: any) => state.architectUsersReducer.modal.queryParams
  );

  const { SetOpenModalAction } = useActions();
  const { savedBatchId, savedDocumentType } = useParams<any>();
  const conmingFromSavedFirstLoad = useRef(true);
  const history = useHistory();
  const [flowFinalState, setFlowFinalState] = useState<FlowFinalState>(null);
  const [selectFlowDefaultValues, setSelectFlowDefaultValues] = useState<
    FieldTypes
  >({
    uploadType: null,
    statementDate: null,
    statementYear: null,
    statementFrequency: null
  });
  const [isLoadingSignedUrls, setIsLoadingSignedUrls] = useState(false);
  const [isLoadingSwitchToReview, setIsLoadingSwitchToReview] = useState(false);
  const [isLoadingDuplicates, setIsLoadingDuplicates] = useState(false);
  const [newFilesList, setNewFilesList] = useState<File[]>([]);
  const [documentList, setDocumentList] = useState<DocumentList>({
    batchId: "",
    result: []
  });
  const [batchId, setBatchId] = useState<string>(savedBatchId || "");
  const [loading, setLoading] = useState(false);
  const [isReview, setIsReview] = useState(!!savedBatchId);

  const {
    resetUploadById,
    uploadCancelTokens,
    totalProgress,
    uploadsProgress,
    isUploading,
    uploadFiles,
    cancelUploadRef,
    getDraftUploads,
    filesFetching,
    deleteUploadedFile,
    refetchUploadedFiles,
    uploadedFiles
  } = useUploadFile(documentList, flowFinalState?.documentType);
  const { getSignedUrls } = useSignedUrls();
  const { allFilesValidated, allFilesSaved } = useFileStatuses(uploadedFiles);
  const { storeFiles, publishFiles, checkForDuplication } = useStoreFiles();

  const { getSignedURL, getBatchRefetchDelay } = fileUtils;
  const { generateStatementPeriod } = dateUtils;

  // Todo fix this.
  const canSave = useMemo(() => {
    if (!uploadedFiles) return false;
    return (
      !isUploading &&
      uploadedFiles?.length &&
      allFilesValidated &&
      !allFilesSaved
    );
  }, [isUploading, uploadedFiles, allFilesValidated]);
  const tableData = useMemo(() => {
    if (!uploadedFiles?.length) return [];
    return uploadedFiles
      ?.filter((file: UploadedFile) => file.validationStatus === "VALID")
      .map(uploadedFile => {
        const {
          startDate,
          payeeCode,
          fileName,
          uploaderUserTitle,
          endDate,
          uploadedAt,
          fileStatus
        } = uploadedFile;
        return {
          documentDate:
            startDate && endDate
              ? convertDocumentPeriodToString(startDate, endDate)
              : "N/A",
          date: endDate,
          payeeCode,
          fileName,
          uploaderUserTitle,
          documentId: uploadedFile.id,
          uploadedAt: convertToShortDate(uploadedAt || ""),
          fileStatus
        };
      });
  }, [isUploading, uploadedFiles]);
  const columns = useMemo(() => {
    const FILE_NAME_COLUMN_CELL = ({ row }: any) => {
      const ableToDownload = row.original.status !== "PUBLISHED";
      return ableToDownload ? (
        <div className={styles.fileName}>
          <ReactTooltip
            id={row.original.fileName}
            data-id={row.original.fileName}
            border
            place="top"
            effect="solid"
            arrowColor="rgba(56, 56, 56, 1)"
          >
            Download - {row.original.fileName}
          </ReactTooltip>
          <a
            data-for={row.original.fileName}
            data-tip=""
            className={styles.payeeCode}
            onClick={() => {
              const { documentId, fileName } = row.original;
              getSignedURL({
                documentId,
                tableType: flowFinalState?.documentType,
                name: fileName
              });
            }}
          >
            {row.original.fileName}
          </a>
        </div>
      ) : (
        <p>{row.original.fileName}</p>
      );
    };

    const COLUMNS_TAX_DOC = [
      {
        accessor: "index",
        withoutSort: true,
        Header: "#",
        width: 60,
        Cell: ({ row }: any) => {
          return row.index + 1;
        }
      },
      {
        accessor: "payeeCode",
        Header: "Payee code"
      },
      {
        accessor: "fileName",
        Header: "File Name",
        Cell: FILE_NAME_COLUMN_CELL
      },
      {
        accessor: "uploadedAt",
        Header: "Date"
      },
      {
        accessor: "uploaderUserTitle",
        Header: "Uploader",
        withoutSort: true
      }
    ];

    const COLUMNS_DEFAULT = [
      {
        accessor: "index",
        withoutSort: true,
        Header: "#",
        width: 60,
        Cell: ({ row }: any) => {
          return row.index + 1;
        }
      },
      {
        accessor: "payeeCode",
        Header: "Payee code"
      },
      {
        accessor: "fileName",
        Header: "File Name",
        Cell: FILE_NAME_COLUMN_CELL
      },
      {
        accessor: "documentDate",
        Header: "Document Date"
      },
      {
        accessor: "uploadedAt",
        Header: "Date"
      },
      {
        accessor: "uploaderUserTitle",
        Header: "Uploader",
        withoutSort: true
      }
    ];

    if (!flowFinalState) {
      return COLUMNS_TAX_DOC;
    }
    if (flowFinalState?.documentType === "tax-doc") {
      return COLUMNS_TAX_DOC;
    } else {
      return COLUMNS_DEFAULT;
    }
  }, [flowFinalState?.documentType]);
  const failedFileList = useMemo(() => {
    return uploadedFiles?.filter(
      (file: UploadedFile) =>
        file?.validationStatus &&
        file?.validationStatus !== "VALID" &&
        !["REJECTED", "DELETED"].includes(file?.fileStatus)
    );
  }, [uploadedFiles]);

  const {
    date: generateStatementDate,
    freequency: generateStatementFreequency
  } = generateStatementPeriod;

  const appendToDocumentList = (newDocuments: DocumentList) => {
    setDocumentList(previousDocumentList => {
      const result = [...previousDocumentList.result];
      newDocuments.result.forEach(newDocument => {
        if (
          previousDocumentList.result.find(previousDocument => {
            return previousDocument.id === newDocument.id;
          })
        )
          return;
        result.push(newDocument);
      });

      return {
        batchId: newDocuments.batchId,
        result
      };
    });
  };

  const mapUploadFilesToDocuments = (uploadedFiles: UploadedFile[]) => {
    return uploadedFiles?.map(
      ({ id, fileName, validationStatus, invalidationReason }) => ({
        id,
        fileName,
        validationStatus,
        invalidationReason
      })
    ) as Document[];
  };

  const switchToReview = async () => {
    if (!flowFinalState || !batchId) return null;
    setIsLoadingSwitchToReview(true);
    await refetchUploadedFiles(batchId, flowFinalState?.documentType);
    setIsLoadingSwitchToReview(false);
    setIsReview(true);
  };

  const switchToUpload = async () => {
    if (!flowFinalState?.documentType) return;
    await refetchUploadedFiles(batchId, flowFinalState.documentType);
    setIsReview(false);
    setDocumentList({
      batchId: uploadedFiles[0]?.batchId || batchId,
      result: mapUploadFilesToDocuments(uploadedFiles)
    });
  };

  const handleDeleteClick = async () => {
    if (!flowFinalState) return;
    const idArray: string[] = [];
    selectedCount?.forEach((row: any) => {
      idArray.push(row.original.documentId);
    });
    await deleteUploadedFile(batchId, flowFinalState.documentType, idArray);
    setTimeout(() => {
      refetchUploadedFiles(batchId, flowFinalState.documentType);
    }, 500);
  };

  const handlePublishClick = async () => {
    if (!flowFinalState || !batchId) return;
    setIsLoadingDuplicates(true);
    const duplicates = await checkForDuplication(
      batchId,
      flowFinalState.documentType
    );
    setIsLoadingDuplicates(false);
    if (duplicates?.length) {
      SetOpenModalAction({
        isOpen: true,
        type: "DuplicateFileValidation",
        queryParams: { duplicates: duplicates }
      });
      return;
    } else {
      SetOpenModalAction({
        isOpen: true,
        type: "PublishUploadedDocuments",
        queryParams: {
          uploadedFiles
        }
      });
    }
  };

  const exitUploader = () => {
    history.push("/user-management/activity");
  };

  const exitToHistory = () => {
    history.push("/user-management/upload/history");
  };

  const publishDocuments = useCallback(
    async (publishingFiles?: PublishableDocument[]) => {
      if (!flowFinalState || !batchId) return;
      await publishFiles(
        batchId || savedBatchId,
        flowFinalState.documentType,
        flowFinalState.documentType !== "tax-doc"
          ? publishingFiles
          : {
              action: "PUBLISH"
            }
      );
      await refetchUploadedFiles(batchId, flowFinalState.documentType);
      SetOpenModalAction({ isOpen: false, type: "" });
      exitToHistory();
    },
    [batchId, savedBatchId, exitUploader, history]
  );

  const saveProgress = useCallback(async () => {
    if (!flowFinalState) return;
    const storedFiles = await storeFiles(
      batchId,
      flowFinalState?.documentType,
      "SAVE"
    );
    await refetchUploadedFiles(batchId, flowFinalState.documentType);
    return storedFiles;
  }, [batchId, flowFinalState]);

  const handleSwitchToReview = useCallback(() => {
    if (failedFileList?.length) {
      SetOpenModalAction({
        isOpen: true,
        type: ["FailedFileValidation"],
        queryParams: { failedFileList, callBack: switchToReview }
      });
    } else {
      switchToReview();
    }
  }, [setIsReview, SetOpenModalAction, failedFileList, switchToReview]);
  const openDuplicateWarningPopup = useCallback(() => {
    SetOpenModalAction({
      isOpen: true,
      type: "DuplicateFileValidation",
      queryParams: { failedDocumentList: failedFileList }
    });
  }, [SetOpenModalAction]);
  openDuplicateWarningPopup;

  const deleteSelectedHandler = useCallback(async () => {
    await handleDeleteClick();
    SetOpenModalAction({
      isOpen: true,
      type: "DeleteSuccessPopup",
      queryParams: selectedCount?.length
    });
  }, [SetOpenModalAction, handleDeleteClick]);

  const deleteFailedDocument = (id: string) => {
    deleteDocumentById(id);
  };

  const deleteDocumentById = async (id: string) => {
    if (!flowFinalState) return;
    setDocumentList(previousDocumentList => ({
      ...previousDocumentList,
      result: previousDocumentList.result.filter(document => document.id !== id)
    }));
    await deleteUploadedFile(batchId, flowFinalState.documentType, [id]);
    await refetchUploadedFiles(batchId, flowFinalState.documentType);
  };

  const cancelUpload = async (id: string) => {
    uploadCancelTokens[id].cancel();
    resetUploadById(id);
    await deleteDocumentById(id);
  };

  const cancelAllUploads = async () => {
    if (!flowFinalState?.documentType) return;

    cancelUploadRef.current = true;

    const uploadIdsToCancel = uploadedFiles
      .filter(file => file.validationStatus === null)
      .map(file => file.id);

    uploadIdsToCancel.forEach(id => {
      resetUploadById(id);
      if (!uploadCancelTokens[id]) return;
      uploadCancelTokens[id].cancel();
    });

    await deleteUploadedFile(
      batchId,
      flowFinalState.documentType,
      uploadIdsToCancel
    );
    await refetchUploadedFiles(batchId, flowFinalState.documentType);
  };

  // TODO Generateing the document type and period from saved batchId could be refactored.
  const generateFlowStateFromBatchId = (savedFiles: Document[]) => {
    const firstFile = savedFiles[0];

    const uploadType = savedDocumentType;

    if (uploadType === "tax-doc") {
      return setSelectFlowDefaultValues({
        uploadType: options.uploadType.find(
          option => option.value === uploadType
        ) as UploadTypeOption,
        statementYear: null,
        statementFrequency: null,
        statementDate: null
      });
    }

    const statementYear = firstFile?.startDate?.split("-")[0] as string;
    const statementFrequency =
      firstFile?.startDate && firstFile?.endDate
        ? generateStatementFreequency(firstFile?.startDate, firstFile?.endDate)
        : "";

    setSelectFlowDefaultValues({
      uploadType: options.uploadType.find(
        option => option.value === uploadType
      ) as UploadTypeOption,
      statementYear:
        options.statementYear.find(option => option.value === statementYear) ||
        null,
      statementFrequency: options.statementFrequency.find(
        option => option.value === statementFrequency
      ) as StatementFrequencyOption,
      statementDate: generateStatementDate(
        firstFile?.startDate as string,
        statementFrequency,
        options
      )
    });
  };

  const openReloadWaringPopup = (e: any) => {
    e.preventDefault();
    e.returnValue = "";
  };

  const openCancelUploadPopup = () => {
    SetOpenModalAction({
      isOpen: true,
      type: "CancelUpload"
    });
  };

  const openSaveProgressPopup = () => {
    if (!flowFinalState?.documentType) return null;

    const popupList = ["SaveProgress"];
    if (failedFileList?.length) {
      popupList.push("FailedFileValidation");
    }

    SetOpenModalAction({
      isOpen: true,
      type: popupList,
      queryParams: {
        failedFileList: failedFileList,
        callBack: async () =>
          await refetchUploadedFiles(batchId, flowFinalState?.documentType),
        batchId
      }
    });
  };

  // Handle exit
  useEffect(() => {
    const unblockExit = history.block((location, action) => {
      if (!allFilesSaved) {
        SetOpenModalAction({
          isOpen: true,
          type: "ExitWarning",
          queryParams: { unblockExit, location, action }
        });
        return false;
      }
    });

    return () => {
      unblockExit();
    };
  }, [allFilesSaved]);

  // Handle reload warning.
  useEffect(() => {
    if (!allFilesSaved) {
      window.addEventListener("beforeunload", openReloadWaringPopup);
    }
    return () => {
      window.removeEventListener("beforeunload", openReloadWaringPopup);
    };
  }, [isUploading, allFilesSaved]);

  // Fetch already existing batchId files (When comeing from the Saved tab)
  useEffect(() => {
    const refetchFromSavedBatchId = async () => {
      if (!savedBatchId || !savedDocumentType) return;
      setLoading(true);

      let fetchedFiles = await refetchUploadedFiles(
        savedBatchId,
        savedDocumentType
      );

      if (conmingFromSavedFirstLoad.current) {
        conmingFromSavedFirstLoad.current = false;
        const draftIds = getDraftUploads(fetchedFiles).map(({ id }) => id);
        if (draftIds) {
          await deleteUploadedFile(savedBatchId, savedDocumentType, draftIds);
          fetchedFiles = await refetchUploadedFiles(
            savedBatchId,
            savedDocumentType
          );
        }
      }

      setLoading(false);
      setFlowFinalState({
        documentType: savedDocumentType
      });
      setDocumentList({
        batchId: savedBatchId,
        result: mapUploadFilesToDocuments(fetchedFiles)
      });
      setBatchId(savedBatchId);
      generateFlowStateFromBatchId(fetchedFiles);
    };
    refetchFromSavedBatchId();
  }, [
    savedBatchId,
    savedDocumentType,
    setFlowFinalState,
    setDocumentList,
    setBatchId
  ]);

  // Poll document data until all files are uploaded and validated.
  useEffect(() => {
    if (!flowFinalState) return;
    const id = setInterval(() => {
      refetchUploadedFiles(batchId, flowFinalState.documentType);
    }, getBatchRefetchDelay(uploadedFiles.length));
    if (!isUploading && allFilesValidated) {
      clearInterval(id);
    }
    return () => clearInterval(id);
  }, [batchId, uploadedFiles]);

  // Handle new files upload.
  useEffect(() => {
    const handleNewSignedUrls = async () => {
      if (!flowFinalState) return;
      const newSignedUrls = await getSignedUrls(
        newFilesList,
        flowFinalState,
        batchId
      );
      if (!newSignedUrls) return null;

      await refetchUploadedFiles(
        newSignedUrls?.batchId,
        flowFinalState.documentType
      );
      setIsLoadingSignedUrls(false);
      appendToDocumentList(newSignedUrls);
      setBatchId(previousBatchId => newSignedUrls?.batchId || previousBatchId);
      uploadFiles(newSignedUrls, newFilesList);

      setNewFilesList([]);
    };
    handleNewSignedUrls();
  }, [newFilesList, getSignedUrls]);
  return (
    <div className={styles.container}>
      <SelectFlow
        setFinalState={setFlowFinalState}
        disabled={Boolean(documentList?.result?.length)}
        defaultValues={selectFlowDefaultValues}
      />
      <Condition
        condition={isReview}
        Truthy={
          <>
            <Table
              columns={columns}
              data={tableData}
              loading={loading}
              selectActionType={"Delete Selected"}
              selectActionLabel={"DeleteSelected"}
            />
            <div className={styles.buttonsContainer}>
              <Button
                size="medium"
                onClick={switchToUpload}
                isLoading={filesFetching}
              >
                Upload More
              </Button>
              <Button
                disabled={!canSave}
                size="medium"
                onClick={openSaveProgressPopup}
              >
                Save Progress
              </Button>
              <Button
                isLoading={isLoadingDuplicates}
                disabled={!uploadedFiles.length}
                size="medium"
                onClick={handlePublishClick}
              >
                Publish
              </Button>
            </div>
          </>
        }
        Falsy={
          <>
            <Condition
              condition={!!flowFinalState}
              Truthy={
                <>
                  <UploadFileZone
                    isLoading={isLoadingSignedUrls}
                    setFileList={setNewFilesList}
                    documentType={flowFinalState?.documentType}
                    onUpload={() => setIsLoadingSignedUrls(true)}
                  />
                  <UploadDescription />
                  <Condition
                    condition={
                      !!documentList?.result?.length || isLoadingSignedUrls
                    }
                    Truthy={
                      <>
                        <UploadList
                          onDelete={deleteFailedDocument}
                          onCancel={cancelUpload}
                          uploadsProgress={uploadsProgress}
                          uploadCancelTokens={uploadCancelTokens}
                          uploadedFiles={uploadedFiles}
                        />
                        <UploadProgress value={totalProgress} />
                        <div className={styles.buttonsContainer}>
                          <Button
                            disabled={!isUploading && allFilesValidated}
                            size="medium"
                            onClick={openCancelUploadPopup}
                            variant="text"
                          >
                            Cancel Upload
                          </Button>
                          <Button
                            disabled={!canSave}
                            size="medium"
                            onClick={openSaveProgressPopup}
                          >
                            Save Progress
                          </Button>
                          <Button
                            isLoading={isLoadingSwitchToReview}
                            disabled={isUploading || !allFilesValidated}
                            size="medium"
                            onClick={handleSwitchToReview}
                          >
                            Next
                          </Button>
                        </div>
                      </>
                    }
                  />
                </>
              }
            />
          </>
        }
      />

      <FailedFileValidationPopup
        documentType={flowFinalState?.documentType || "statement-doc"}
        batchId={batchId}
        switchToReview={switchToReview}
      />
      <CancelUploadPopup cancelAllUploads={cancelAllUploads} />
      <SaveProgressPopup
        saveProgress={saveProgress}
        failedFileList={failedFileList}
        uploadedFiles={uploadedFiles}
      />
      <DuplicateFileValidationPopup handlePublish={publishDocuments} />
      <DeleteSuccessPopup />
      <DeleteSelectedPopup
        onClick={deleteSelectedHandler}
        title={`Delete ${selectedCount?.length} ${
          selectedCount?.length > 1 ? "documents" : "document"
        }?`}
      />
      <PublishPopup publish={publishDocuments} exit={exitUploader} />

      <ExitWariningPopup />
    </div>
  );
};

export default Upload;
